<template>
<div id="introduction" class="book-content space-y-8">
<h1 class="text-black text-xl sm:text-4xl sm:leading-10 font-atlas-medium flex flex-col space-y-4">
    <span class="hidden sm:block">In this course you’ll discover how to standout,</span>
    <span class="hidden sm:block">earn more money, and survive as a freelancer.</span>

    <span class="block sm:hidden">In this course you’ll discover how to standout,
    earn more money, and survive as a freelancer.</span>
</h1>

<ChapterVideo  :show="false" :floating="false" :videoID="'b6441c599c004843bba68021e0871973'" :title="'Introduction'" />

<p id="details">Hey, y’all! I’m Dann Petty, a freelance web and app designer who learned the hard way how to become a successful freelancer. When I started out, I had no clue what I was doing and made a lot of mistakes. Since then, however, I’ve learned incredibly valuable tips. They’ve helped me turn my freelancing business into the multi-million-dollar enterprise that it is today.  I learned how to better price my projects, which led to doubling and even tripling my income. I researched ways of getting more consistent work and marketing myself. Most importantly, I learned how to treat my clients, so they continue referring me to others. </p>
<p>One of the biggest keys to freelancing is making sure clients know you exist. I learned a simple method of doing this, and yet it’s something at which I see so many freelancers consistently fail. I have to tell you, from my 20+ years of experience as a full-time freelance web and app designer for companies like National Geographic, Google, Airbnb, Adobe, and many others, the moment you stop sharing your work and stop letting people know you exist is the moment the work stops coming your way.</p>
<p>When I first started freelancing, I wished there was more information available about what to do. Everything I saw seemed to be focused primarily on how to set up your business/taxes and other aspects that only had to do with the financial end of things. </p>
<p>But to do most of that, you first need a successful business to set up! To save you time, money, and to keep you from learning the hard way (like I did), I wrote this book. It’s loaded with absolutely everything I know about how to become a successful freelancer, whether you do it full-time, part-time, or simply for some pocket money.</p>
<p>In Standout Freelancer, I’m going to teach you everything I know about freelancing. We’ll go over things like:</p>

<div class="space-y-4">
    <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
        <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            What you need to know before starting.
        </span>
    </div>
   <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
       <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            How to set yourself up for success.
        </span>
    </div>
    <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
      <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            How to get work.
        </span>
    </div>
     <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
       <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            How to standout.
        </span>
    </div>
 <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
       <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            How to work with clients and what they expect.
        </span>
    </div>
     <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
       <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            How to price your projects.
        </span>
    </div>
   <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
       <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            How to double your income.
        </span>
    </div>
     <div class="flex flex-row items-center  space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
       <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            Everything portfolio.
        </span>
    </div>
     <div class="flex flex-row items-center space-x-4">
        <svg class="w-5 h-5 flex flex-shrink-0 flex-col justify-end self-start sm:self-center mt-1 sm:mt-0 " viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
      <span class="text-black text-left text-lg sm:text-2xl font-atlas-medium">
            And even the not-so-fun side of freelancing.
        </span>
    </div>
</div>

<p>Standout Freelancer is not a “you should do this” but is more of a “hey, consider this” and “here’s what worked for me and others” type of book and course, so you can make your own decisions. Be wary of anyone who tells you that you should do something unless they are saying you should do your own research and experimentation. It’s never that black and white, which is why I felt an obligation to write the proper, real, and raw course about freelancing. What worked for me might not work for anyone else, and that’s ok. Once you’ve gone through this, you’ll be able to do whatever you want to do, in whatever way you want to do it, as long as it works… for you.</p>
<p>Freelancing can be such a joy and be incredibly satisfying, but it can also be a painful ride at times. For me, though, it’s one of the smartest decisions I’ve ever made about my career. When I look back at the last 20 years, I’m deeply thankful for everything I’ve experienced and for all the people I’ve met throughout this adventure. There have been ups and downs, naturally, and months with no work, but that’s part of what is so addictive about it—the ultimate joy of succeeding as a solo creative professional. Get out there and give it the proper go. I’m here to help you do that. See you inside!</p>

<div class="h-2"></div>

<div class="flex flex-row items-center justify-between border-t border-b py-8 border-gray border-opacity-20 mt-20">
    <svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51472 20.3934C2.92893 19.8076 1.97919 19.8076 1.3934 20.3934C0.807612 20.9792 0.807612 21.9289 1.3934 22.5147L10.9393 32.0607ZM10.5 6.55671e-08L10.5 31L13.5 31L13.5 -6.55671e-08L10.5 6.55671e-08Z" fill="#45DB78"/>
    </svg>

    <h2 class="text-black text-base sm:text-lg font-atlas-medium">Preview the first few sections for free!</h2>

    <svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9393 32.0607C11.5251 32.6464 12.4749 32.6464 13.0607 32.0607L22.6066 22.5147C23.1924 21.9289 23.1924 20.9792 22.6066 20.3934C22.0208 19.8076 21.0711 19.8076 20.4853 20.3934L12 28.8787L3.51472 20.3934C2.92893 19.8076 1.97919 19.8076 1.3934 20.3934C0.807612 20.9792 0.807612 21.9289 1.3934 22.5147L10.9393 32.0607ZM10.5 6.55671e-08L10.5 31L13.5 31L13.5 -6.55671e-08L10.5 6.55671e-08Z" fill="#45DB78"/>
    </svg>

</div>

<div class="h-4"></div>

<h2 ref="chapter1" id="chapter-one-things-you-must-know-first" class=" text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter One:
Things You Must Know First</strong></h2>
<ChapterVideo  :show="false" :floating="false" :videoID="'04d45925e18649289ccb7c57f209a08f'" :title="'Things You Must Know First'" />
<p>Has this been you? Maybe it’s 3:30 p.m. on a Tuesday. The workweek
has started, but Friday is still far away. You seem to have too much on
your plate — and yet you’re simultaneously bored. You look out the
window and see nice things. A park, maybe. Birds landing on tree limbs.
Then, as you think about your 20-minute commute back home at the end of
the workday, it hits you: those birds have a lot more freedom than you
do.</p>
<p>I’m not saying everyone with a nine-to-five hates their job. Far from
it. But I’m saying that if you’re interested in freelancing, the odds
are pretty good that some part of that scenario resonates with you. And
while we’d all like to win the lottery or magically secure a passive
income that lets us relax on the beach with mojitos in our hands, the
truth is there’s a much more reliable way to take your work life into
your own hands.</p>
<p>That way: going independent. Going freelance.</p>
<p>If you’re new to it, the very idea of it can throw you off. People
might tell you things like <em>if it sounds too good to be true, it
probably is</em>. But the realities of freelancing aren’t really as
radical as you might imagine. With freelancing, you’ll still have to
work for your income. With freelancing, you’ll still be accountable to
others to do a good job. With freelancing, you’ll still have deadlines,
bills, and just about everything else you might associate with the
nine-to-five.</p>
<p>But you’ll gain one very important thing. <em>Control</em>.</p>
<p>This is a simple A-to-B journey. There’s nothing stopping you from
quitting your job today and going out to earn your first freelance
clients. And honestly — taking action so decisively would bode well for
your makeup as a freelancer.</p>
<p>But there are still things you can do to shorten the learning curve.
Think of it as a flight plan: a checklist of things to do before you go
wheels-up and leave the solid ground of a steady paycheck. In my time as
a freelancer, I’ve had successes and failures. If you read this book
from beginning to end, you might be able to double-down on my successes
— and skip some of the failures.</p>
<p>It’s been a lot of work. A lot of clients. A lot of mistakes. A lot
of achievements. A lot of joy. A lot of learning. And what does it all
add up to?</p>
<p>Let’s get started.</p>

<h5 id="dont-sweat-the-details" class="section"><strong>Don’t sweat the details
yet.</strong></h5>
<ChapterVideo  :show="false" :floating="false" :videoID="'4fb99b5bdfb54c8e9be7c81b1eb13ddc'" :title="`Don’t sweat the details
yet`" />
<p>The hardest part about becoming a freelancer is starting. That’s
mainly because on paper, it can sound daunting having to come up with a
name. Making it legal. Getting a bank. Setting up a portfolio. Marketing
yourself. Selling yourself. Managing projects. Getting clients. Writing
proposals. And then there’s actually <em>doing</em> the service you’re
offering your clients.</p>
<p>Let’s be real. It’s stressful even just thinking about it. It’s why
so many potential freelancers keep putting off becoming one — they get
too buried into the details they think they need in order to “start
freelancing.”</p>
<p>You could literally start now. Don’t worry about getting everything
together yet. Don’t sweat those details.</p>
<p>I’m serious, if you want to become a freelancer, you have to just
start. No one is holding you back but yourself at this stage. Let’s be
honest, it’s the only path to becoming a freelancer – just starting.
Otherwise, you’re just thinking about being a freelancer, not becoming
one.</p>
<p>You don’t need to have it all figured out at first. Don’t rush off
and create an LLC or bank account either. Don’t even think about those
things yet.</p>
<p>First, get a project. Secondly, get paid. The rest can come
later.</p>
<p>I told you, this book is going to be different. I’m going to be real.
I’m going to be simple and I’m going to give it to you straight. You
will never fully be prepared for what freelancing may bring. You will
never get it 100% perfect. So stop procrastinating and just start.</p>
<p>There’s no better time than now.</p>
<p>Listen: for most of my freelance career, I just used my personal name
and social security number (on w9’s) for my business. For <em>years</em>
I did this. Hundreds of thousands of dollars made, if not a million,
before I did anything different. It’s not the most optimal way and makes
tax season a little harder, but I mention that just so you know that you
<em>can</em> start with what you’ve got now. Don’t even wait until your
personal website is ready. It’s just another thing to hold you back.</p>
<p>I put all my design work on Behance.net (before there was
Dribbble.com and Instagram and things) for free and got started letting
the world know I existed. I’m in the boat that you don’t even really
need a personal website. Will it help? Sure. But sometimes it just makes
things worse. And it definitely slows people down from putting
themselves out there. At first, just go where the clients are – social
sharing sites. No one will find your personal website unless it was
shared on one of those sites anyways, or unless you’re a genius at SEO.
But good luck there. Google is always changing its algorithms. Your
personal brand is more important.</p>
<p>Keep it simple. Just get your first check made out to you. Would it
be nice to have a legal business name, bank account, website, and
everything beforehand? Again, yeah sure, of course it would.</p>
<p>Is it necessary? Absolutely not.</p>
<p>But you’ve got to understand that stuff like that takes time and
becoming a freelancer is a process. Let me repeat that. IT’S A PROCESS.
It’s a process that is hard enough as it is already…so just keep it
simple.</p>
<p>Besides, it’s also just smart to wait until you get going and truly
figure out whether or not you even <em>enjoy</em> freelancing before you
go through all the trouble of creating an LLC, getting a bank account,
and everything that comes with it. I see so many people that only make
it freelancing for a few months before they go back full-time somewhere
else.</p>
<p>It’s not for everyone. We’re a select breed.</p>
<p>Every freelancer has learned how to freelance by — guess what? —
<em>freelancing</em>. It’s the best way to figure out what works best
for you because as you’ll hear repeatedly in this book, being successful
as a freelancer means many different things to different people. For
some, it’s all about the larger sum of money you can make compared to
being full-time for someone else. For others, it’s about more free time
and flexibility in their life. Of course, for some freelancing is the
only option available.</p>
<p>The most important thing is that you’re putting yourself out there,
getting work and getting paid in whichever way works best for you – not
how some article, YouTube video, or even this book tells you how to. Do
what works for you. This book will just help you find out what that
is.</p>
<p>Hopefully it’s already clear: no matter what you read or what someone
tells you, there are <em>several</em> different ways to freelance.
That’s why I’m going to use this book to mention my opinions. But I’ll
also share lessons from other freelancers. You’ll see there’s no “X
marks the spot” in freelancing. Everyone’s map is different. I’m just
going to tell you how to read your own map in the fastest possible
way.</p>
<p>I’ll say it one more time. JUST START.</p>

<h5 id="understand-why-youre-starting" class="section"><strong>Understand why you’re
starting.</strong></h5>
<ChapterVideo  :show="false" :floating="false" :videoID="'34f7e1738806456faeaa2efd756e0d48'" :title="`Understand why you’re
starting`" />
<p>This is a question that you’ll never stop asking yourself, even after
you’ve been freelancing for years. Why? Why am I doing this?</p>
<ul>
<li><blockquote>
<p>To earn extra money?</p>
</blockquote></li>
<li><blockquote>
<p>To have more freedom/flexibility?</p>
</blockquote></li>
<li><blockquote>
<p>To run your own business?</p>
</blockquote></li>
<li><blockquote>
<p>To kick start your career because you have no work?</p>
</blockquote></li>
<li><blockquote>
<p>Because you just got fired?</p>
</blockquote></li>
<li><blockquote>
<p>Because you can’t get a full-time job?</p>
</blockquote></li>
<li><blockquote>
<p>Because you’re burnt out at your current job?</p>
</blockquote></li>
<li><blockquote>
<p>Or are you like me and you want to freelance because you want to work
for as many clients as you can, make way more money, surf all day,
travel, and to watch my kids grow up? Basically, half of the above
reasons.</p>
</blockquote></li>
</ul>
<p>Regardless of your reason, which may change throughout your career as
a freelancer, understanding why you do it will help focus your mindset.
It will also help you determine what kind of advice you can listen to,
and what you can toss away.</p>
<p>Some people will say you can never work on more than one project at
once, for example. But maybe those freelancers aren’t trying to maximize
income like you are. Maybe their set skill takes longer on projects and
requires a bit more attention. Some will say you still have to work
normal business hours, like the rest of the world, but they may not
understand you freelance so you can travel or surf during the day or
that you can only work 4-5 hours a day because that’s when your kids are
at school.</p>
<p>My own reasons? I started freelancing so I could work on as many
projects for as many clients as I wanted. I knew I wanted to earn as
much money as possible. I knew that I wanted to surf during the day and
not work as much as everyone else. I also knew I wanted to have kids in
the future and wanted to be with them as much as possible.</p>
<p>Understanding my “why” helped me decide which and how many jobs to
accept and which ones to turn down. More importantly, it also helped me
understand how much I needed to make to sustain my “why.” I quit my job
as a Creative Director at a famous design agency to go full-time
freelance because it wasn’t fulfilling any of those “whys.” I had to
work specifically 9-6 (at least), couldn’t surf during the day, and
couldn’t travel and work from remote locations when I needed/wanted/or
felt uninspired. I was also set to one salary range based on company
size and could only get paid more maybe every year if I worked super
hard. I didn’t like that all of my “whys” were all unattainable
full-time for someone else. But that’s just me — I don’t like
limitations unless I’ve put them there for a reason.</p>
<p>So now that we know <em>why</em> we want to freelance, let’s figure
out when we’re going to start.</p>

<h5 id="dont-quit-your-job-yet" class="section"><strong>Don’t quit your job
yet.</strong></h5>
<ChapterVideo  :show="false" :floating="false" :videoID="'a2b9b2d0d86d43fcb685858995ed447a'" :title="`Don’t quit your job
yet`" />
<p>I know you’ve heard this one before, but I’ll add my own twist to it
by saying this is not a rule. Again, there are no rules for how to
freelance. This advice is just smart. It’s smart to have work lined up
before you quit a job and start freelancing full-time because honestly
for starters, you might not even like it. Maybe even more important,
work isn’t always there. Even after a decade of freelancing, you will
hit a dry spell. Happens to all of us more than we like to admit.</p>
<p>What this advice forgets, as it’s constantly shouting out, is that
there are people that don’t have the luxury of a full-time job with
great pay. So I’ll say this: if this is you, consider trying to get a
job at a coffee shop or somewhere a lot of people cross paths so you can
meet people and tell them you’re freelancing while still making a little
bit of income. For some of you, even that sounds like a hard reach. I
understand. Do what you can to get paid, legally, so you’re not digging
yourself a bigger hole trying to freelance — that’s all this advice is
saying. No job is stable, especially not freelancing.</p>
<p>I know you’re eager to get out of the full-time job and live the
luxurious life of a full-time freelancer you’ve been dreaming about. But
let's slow down for a minute and think about this. If you just jump
straight in, will you have work? Do you have any work lined up? What if
you don’t find a job within a couple of weeks — then what? Do you have a
little bit of money saved up in case that happens so you can stick with
trying a little bit longer? See what I’m getting at? It’s tricky. In a
perfect world, you’ll get to work right away, and everything will be
just like you dreamed about but again let’s stay in reality — might not
happen that way.</p>
<p>With that understood, the best approach is to start freelancing
part-time. You don’t have to give yourself a timeline either. Oh in 2
weeks I’ll go full-time, or this summer. You’ll know when the time is
right. But if you’re super eager just wait until you already have at
least 1 project lined up, if not 2, that could make you enough money to
give freelancing <em>an honest</em> try. It’s important you give it a
solid honest try so you don’t have any regrets later on. The only way to
do that is with time.</p>
<p>Keep in mind: freelancing can be a little slow at first. One thing we
see way too often is freelancers taking the jump full-time but only
lasting a few weeks — in most cases, it’s because they didn’t have
enough money saved up to give it a proper go and respectfully, get a
little anxious with all the unknowns that come with freelancing.</p>
<p>With that said, that situation (or something like that) is highly
possible, even if you’re a seasoned freelancer. There’s absolutely
nothing wrong with that. Some consider it a break, others an
opportunity; every situation is different. Over my 15-year career as a
full-time freelancer, I went to work for six companies full-time for a
short period of time — each with a reason.</p>
<p>Here are a couple of examples of that:</p>
<p><strong>Medium.com</strong> — before Medium.com was a thing, the team
behind it was called Obvious Corp, led by Evan Williams and Biz Stone. I
was one of the first designers there. I got a DM on Twitter from Ev,
which is wild because he created Twitter, saying he liked my work and
wanted me to come work with him. Like most opportunities like this, I
mentioned I was only interested in freelancing opportunities and just
like that, I started freelancing with them for 2 months while we were
exploring what later became Medium.com. After 2 months, I learned so
much working on the product I decided to join full-time until the
launch.</p>
<p>I left once it launched, as I felt my job there was done, but also
because I got accepted into <strong>Y-Combinator</strong>. I noticed a
problem with communication at companies I’d worked for, so decided to
create a product called HUM. Right before our demo day, a product we all
know as Slack was announced, destroying all momentum for me and my
co-founder. So, I went back to full-time freelancing. I was even able to
freelance for a majority of my YC class which made it all worth it. I
knew I enjoyed freelancing more than working on one single product, but
Medium was one of those once-in-a-lifetime opportunities and I wanted to
experience it. Remember what I said earlier about opportunities, grab
them when you see them.</p>
<p><strong>Note:</strong> As I was full-time at Medium, I never stopped
letting people know I was available for side freelance. It was important
to me I never lost that momentum because I knew how hard it can be to
gain back.</p>

</div>
</template>

<script>

import ChapterVideo from '../components/ChapterVideo.vue'

export default {
  name: "BookPreview",

  components: {
    ChapterVideo
  },

  props: ["scrollValue"],

  data() {
    return {

    };
  },

  async created() {
      console.log(this.scrollValue)
  },

  methods: {

  },
};
</script>

<style>
</style>
