<template>
  <div class="min-h-screen bg-black-dark flex justify-center">
    <div
      class="
        flex
        justify-items-center
        sm:items-center
        content-center
        justify-center
        align-items-center
        w-full
        px-8
        sm:w-full
      "
    >
      <div class="mx-auto w-full max-w-2xl">
        <router-link
          :to="{ name: 'Home' }"
          class="
            rounded-md
            text-gray-lightest
            flex flex-row
            items-center
            gap-2
            text-lg
            hover:text-white
            focus:outline-none
            mb-12
            mt-12
            sm:mt-0
          "
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292892 7.29289C-0.0976315 7.68342 -0.0976314 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928933C7.68054 0.538409 7.04738 0.538409 6.65685 0.928933L0.292892 7.29289ZM16 7L0.999999 7L0.999999 9L16 9L16 7Z"
              fill="#8B8C8D"
            />
          </svg>

          Back to Home
        </router-link>
        <div>
          <!-- <router-link :to="{ name: 'Home' }">
            <span class="sr-only">Standout Freelancer</span>
           <p class="text-white text-xl font-black">Standout Freelancer</p>
          </router-link> -->
          <h2 class="mt-6 text-3xl font-semibold text-white">
            <span class="block">Sign in to</span>
            <span class="block">Standout Freelancer</span>
          </h2>
          <p class="mt-2">
            <router-link
              :to="{ name: 'Signup' }"
              class="font-untitledserif text-gray-lightest text-lg hover:text-white"
            >
              No account? <span class="underline">Sign up here</span>
            </router-link>
          </p>
        </div>

        <div class="mt-8">
          <div class="mt-6">
            <form action="#" method="POST" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium text-white">
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    v-model="email"
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      h-12
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    v-model="password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    required
                    class="
                      appearance-none
                      block
                      w-full
                      h-12
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none
                      focus:ring-indigo-500
                      focus:border-indigo-500
                      sm:text-sm
                    "
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="">
                  <router-link
                    :to="{ name: 'ForgotPassword' }"
                    class="font-untitledserif text-gray-lightest text-lg hover:text-white"
                  >
                    Forgot your password?
                  </router-link>
                </div>
              </div>

              <div
                v-if="errorMessage"
                class="flex items-center justify-between"
              >
                <div class="text-sm font-medium text-green">
                  <p>
                    {{ errorMessage }}
                  </p>
                </div>
              </div>

              <div>
                <button
                  @click="submit"
                  type="submit"
                  class="
                    px-4
                    sm:px-8
                    mt-3
                    md:mt-4
                    lg:mt-5
                    py-3
                    bg-green
                    text-center

                    w-full
                    rounded-full
                    shadow-sm
                    text-lg
                    font-atlas-medium
                    text-black
                    focus:outline-none
                    buyButton
                  "
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "Signin",

  data() {
    return {
      email: null,
      password: null,
      errorMessage: "",
      loading: false,
    };
  },

  methods: {
    async submit(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      this.errorMessage = null;

      if (this.email && this.password) {
        const email = this.email.trim();
        const password = this.password.trim();

        await api.state.firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            this.errorMessage =
              "The password is invalid or the user does not have a password.";
            return;
          });

        if (!this.errorMessage) {
            this.$router.push({
              name: "Home",
            });
        } else {
          console.log("Error: ", this.errorMessage);
        }
      } else {
        this.errorMessage = "Please fill in all the fields.";
      }

      return null;
    },
  },
};
</script>


<style scoped>
</style>
