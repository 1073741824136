import store from '@/store';
import api from '@/api';

export default {
  async user({ userId }) {
    const foundItem = store.state.users.find(user => user.id === userId);
    if (foundItem) return foundItem;

    const user = await api.users.getById({ userId });

    if (user) {
      store.setAction({
        which: 'addUser',
        data: {
            user,
        },
      });
    }

    return user;
  },
};
