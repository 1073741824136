import Vue from 'vue'
import App from './App.vue'
import api from "@/api";
import router from './router'
import store from "./store";
import checkView from 'vue-check-view'
import VueMeta from 'vue-meta'
import './assets/styles/index.css';

Vue.config.productionTip = false

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp({
    apiKey: "AIzaSyBTlvnXi7_htlYPffofoacWXZcqLvoI3rg",
    authDomain: "freelance-guide.firebaseapp.com",
    projectId: "freelance-guide",
    storageBucket: "freelance-guide.appspot.com",
});

// API initialization
api.init({
    firebase,
    firestoreDB: firebase.firestore(),
    storage: firebase.storage()
});

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')

Vue.use(checkView)
Vue.use(VueMeta)

api.state.firebase.auth().onAuthStateChanged(async user => {

    if (user) {
        const userData = await api.users.get({
            userId: user.uid,
        })

        const mergedUser = {
            ...user,
            // claims,
            userData,
        }

        store.setAction({
            which: 'setCurrentUser',
            data: {
                user: mergedUser,
            },
        })
    } else {
        // console.log('No user is signed in')
    }
})
