<template>
  <div
    class=""
    @scroll="onScroll"
  >
    <div
      class="sticky md:fixed top-0 w-full bg-black-dark"
      :class="
        scrollPosition ? 'bg-black-dark bg-opacity-100 z-50' : 'z-30'
      "
    >
      <Nav
        :scrollPosition="false"
        :page="'home'"
        @postJob="toggleMenu"
        @toggleTextMode="toggleTextMode"
      />
    </div>

    <!-- <Header /> -->

    <Content :scrollPosition="scrollPosition" :textMode="textMode"/>

    <PaymentSuccess
      v-if="showSuccessModal"
      description="I wish you the most successful and standout year you've ever had. Enjoy the course!"
      :closeText="'Close'"
      @closePaymentModal="closePaymentModal"
    />

    <div class="mt-2 max-w-7xl mx-auto">
      <main class="pt-32 sm:pt-0">
        <Footer />
      </main>
    </div>
  </div>
</template>

<script>
import Nav from "./Nav";

import Content from "./Content"
import PaymentSuccess from "./PaymentSuccess";
import Footer from "./Footer.vue";

export default {
  name: "Home",

  components: {
    Nav,
    Content,
    PaymentSuccess,
    Footer,
  },

  data() {
    return {
      showSuccessModal: false,
      scrollPosition: false,
      textMode: false,
      userId: null,
    };
  },

  async created() {
    window.addEventListener("scroll", this.onScroll);
  },

  async mounted() {
    const route = this.$route;
    // console.log(route.path)

    if (route.path === "/success" || route.path === "/canceled") {
      this.sessionId = this.$route.query.session_id;

      if (route.path === "/success") {
        this.showSuccessModal = true;
        // this.$confetti.start();
      }
    }
  },

  destroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
      toggleTextMode(data) {
          this.textMode = data.textMode
      },
    closePaymentModal() {
      this.showSuccessModal = false;
    },
    onScroll() {
      this.scrollPosition = window.scrollY > 500;
    },
    toggleMenu() {

    },
  },
};
</script>

<style>
.fix {
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}
</style>
