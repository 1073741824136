<template>
  <div class="w-full h-full">
    <div class="lg:flex h-full relative" :class="isOpen ? 'bg-gray-light' : 'bg-white'">

     <TableOfContents :preview="false" :active="currentId" :justPlayed="videoId" @scrollTo="scrollTo"  @showBuy="showBuy" @onExpand="onExpand"/>

      <div
        class="
          content-wrapper
          bg-white
          transform
          ease-in-out
          transition-all
          duration-300
          min-w-0
          pt-10
          sm:pt-40
          w-full
          flex-auto
          lg:static lg:max-h-full lg:overflow-visible
          z-10
        "
      >
        <div class="w-full flex justify-between">
          <div
            id="content"
            class="
              min-w-0
              max-w-7xl
              flex-auto
              space-y-4
              pb-24
              lg:pb-16
              mt-0
              sm:-mt-16
              text-gray-body
            "
            :class="
              isOpen ? 'px-4 sm:px-6 xl:px-8 pb-24' : 'pr-4 sm:pr-6 xl:pr-8'
            "
          >
            <Book @completed="completed" :textMode="textMode" @inView="inView"/>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>


<script>

import TableOfContents from "../components/TableOfContents.vue";
import Book from "../components/Book.vue";

export default {
  name: "Content",

  components: {
    TableOfContents,
    Book,
  },

  props: ["scrollPosition", "textMode"],

  data() {
    return {
      isOpen: true,
      isMobile: false,
      videoId: '',
      currentId: 'chapter-one-things-you-must-know-first'
    };
  },

  async created() {
      this.isMobile = this.checkMobile()
  },

  methods: {
    completed(data) {
        const videoId = data.videoId

        this.videoId = videoId
    },

    checkMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },

    inView(data) {
        const id = data.id

        this.currentId = id
    },

   onExpand(value) {
        this.preventScroll = value.value
    },

    openSidebar() {
      this.isOpen = !this.isOpen;
    },

    showBuy() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },

    scrollTo(data) {

            var element = document.getElementById(data.href);
            var headerOffset = this.isMobile ? 850 : 20;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            console.log(offsetPosition)

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
    },

    scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
    }
  },
};
</script>

<style>
</style>
