import axios from "axios";

export default {

    async getCheckoutCourse({ lineItems, userId, code }) {
        const results = await axios.post('/get-checkout-session-course', {
            lineItems,
            userId,
            code
        })

        return results
    },
}
