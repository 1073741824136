<template>
  <div class="mx-auto py-4 px-4 sm:px-6 lg:px-8 xl:px-10">
    <nav
      class="relative flex items-center justify-between gap-2"
      aria-label="Global"
    >
      <!-- absolute inset-y-0 left-0"> -->
      <div class="flex items-center">
        <div class="flex items-center justify-between z-30 w-auto ">
          <router-link :to="'/'">
            <span class="sr-only">Standout Freelancer</span>
            <p class="text-white text-2xl font-atlas-medium">Standout Freelancer</p>
          </router-link>
        </div>
      </div>
      <div class="mr-0 my-2 sm:hidden">
        <button
          @click="showMobileMenu"
          type="button"
          class="
            rounded-md
            p-2
            inline-flex
            items-center
            justify-center
            text-white
            hover:text-gray-500
            hover:bg-gray-100
            focus:outline-none
          "
          aria-expanded="false"
        >
          <span class="sr-only">Open menu</span>
          <svg
            width="20"
            height="10"
            viewBox="0 0 20 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="19"
              y1="1"
              x2="1"
              y2="0.999999"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
            />
            <line
              x1="19"
              y1="9"
              x2="1"
              y2="9"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>

      <div class="relative hidden sm:ml-3 sm:flex sm:items-center sm:justify-end">
        <router-link
          v-if="!user"
          :to="{ name: 'Signin' }"
          class="
                inline-flex
                items-center
                justify-center
                rounded-full
                text-sm
                w-auto
                border-2
                px-6
                py-2
                text-right
                font-medium
                text-white
                bg-black

                focus:outline-none
                seeDetails
          "
           :class="hideOnScroll ? 'border-white' : 'border-black'"
        >
          <span class="hidden sm:block">Member Login</span>
          <!-- <span class="block sm:hidden">Login</span> -->
        </router-link>
            <!-- <button
            v-if="!user"
            @click="showLogin"
            class="
                inline-flex
                items-center
                justify-center
                rounded-full
                text-sm
                w-auto
                border-2
                px-6
                py-2
                text-right
                font-medium
                text-white
                bg-black

                focus:outline-none
                seeDetails
            "
            :class="hideOnScroll ? 'border-white' : 'border-black'"
            >
                <span class="hidden sm:block">Member Login</span>
            </button> -->

            <!-- <div id="login-menu" class="absolute z-50 h-0 top-10 right-5 bg-black overflow-hidden shadow-xl rounded-b-md rounded-l-md">
                <form action="#" method="POST" id="login-content" class="space-y-2 p-8 w-80">
                    <div>
                        <label for="email" class="block text-sm font-medium text-white">
                          Email address
                        </label>
                        <div class="mt-1">
                          <input
                            v-model="email"
                            id="email"
                            name="email"
                            type="email"
                            autocomplete="email"
                            required
                            class="
                              appearance-none
                              block
                              w-full
                              px-3
                              py-2
                              border border-gray-300
                              rounded-md
                              shadow-sm
                              placeholder-gray-400
                              focus:outline-none
                              focus:ring-indigo-500
                              focus:border-indigo-500
                              sm:text-sm
                            "
                          />
                        </div>
                    </div>

                    <div class="space-y-1">
                        <label
                        for="password"
                        class="block text-sm font-medium text-white"
                        >
                        Password
                        </label>
                        <div class="mt-1">
                        <input
                            v-model="password"
                            id="password"
                            name="password"
                            type="password"
                            autocomplete="current-password"
                            required
                            class="
                            appearance-none
                            block
                            w-full
                            px-3
                            py-2
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            placeholder-gray-400
                            focus:outline-none
                            focus:ring-indigo-500
                            focus:border-indigo-500
                            sm:text-sm
                            "
                        />
                        </div>
                    </div>

                    <div class="flex items-center justify-between">
                        <div class="text-sm">
                        <router-link
                            :to="{ name: 'ForgotPassword' }"
                            class="font-medium text-white hover:text-gray-light"
                        >
                            Forgot your password?
                        </router-link>
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <div class="text-sm font-medium text-red-light">
                        <p>

                        </p>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <button
                        @click="submit"
                        type="submit"
                        class="
                            px-4
                            py-1
                            bg-white
                            text-center

                            w-1/2
                            rounded-full
                            shadow-sm
                            text-xs
                            sm:text-base
                            font-semibold
                            text-black
                            focus:outline-none
                        "
                        >
                        Sign in
                        </button>
                    </div>
                </form>
            </div> -->

        <button
          @click="buyCourse"
          v-if="hideOnScroll"
          class="
              bg-green
            text-center
            px-4
            py-1
            ml-4
            w-auto
            rounded-full
            shadow-md
            text-base
            font-semibold
            text-black
            focus:outline-none
            animate-buy
            buyButton
            border-2
            border-green
          "
        >
          <span class="hidden sm:block">Course Closed</span>

        </button>

        <a
          v-else-if="user && !hideOnScroll"
          @click="openMenu"
          href="#"
          class="
            inline-flex
            items-center
            justify-center
            text-base
            w-auto
            text-center
            font-medium
            text-white
            hover:text-gray-light
            focus:outline-none
            relative
          "
        >
          <span class="sr-only">Open user menu</span>
          <span>{{ user.email }}</span>
          <span class="ml-2">
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z"
                fill="#C4C4C4"
              />
            </svg>
          </span>
        </a>
      </div>
      <div
        v-if="profileMenu"
        class="
          top-8
          absolute
          right-0
          mt-2
          w-48
          rounded-md
          shadow-lg
          bg-white
          ring-1 ring-black ring-opacity-5
          py-1
          z-50
          focus:outline-none
        "
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu-button"
        tabindex="-1"
      >

        <router-link
        v-if="sharedState.currentUser.userData.payment && page !== 'home'"
          to="/"
          class="
            block
            py-2
            px-4
            text-sm text-gray-700
            hover:bg-green
            hover:text-black
          "
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-1"
          >View Course</router-link
        >

        <a
        v-if="sharedState.currentUser.userData.payment && page === 'home'"
          @click="toggleTextMode"
          href="#"
          class="
            block
            py-2
            px-4
            text-sm text-gray-700
            hover:bg-green
            hover:text-black
          "
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-2"
          >{{ textMode ? 'Video Mode' : 'Text Mode' }}</a
        >

        <a
          @click="signOut"
          href="/buy"
          class="
            block
            py-2
            px-4
            text-sm text-gray-700
                hover:bg-green
            hover:text-black
          "
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-3"
          >Sign out</a
        >
      </div>
    </nav>
    <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
    <div
      v-if="mobileMenu"
      class="
        absolute
        w-full
        h-screen
        top-0
        inset-0
        bg-black-menu
        transition
        transform
        origin-top-right
        z-50
        overflow-hidden
        md:hidden
      "
    >
      <div class="pt-5 pb-6 px-5 h-screen">
        <div class="flex items-center justify-between">
          <div>
              <router-link :to="'/'">
            <span class="sr-only">Standout Freelancer</span>
            <p class="text-white text-2xl font-atlas-medium">Standout Freelancer</p>
          </router-link>
          </div>
          <div class="-mr-2 flex align-middle">

            <button
              @click="showMobileMenu"
              type="button"
              class="
                rounded-md
                p-2
                inline-flex
                items-center
                justify-center
                text-white
                hover:text-gray-500
                hover:bg-gray-100
                focus:outline-none
              "
            >
              <span class="sr-only">Close menu</span>
              <!-- Heroicon name: outline/x -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="mt-12 h-screen">
          <nav class="flex flex-col justify-between h-96">
            <div class="flex flex-col gap-y-8">

              <a
                href="mailto:hey@dannpetty.com"
                class="-m-3 p-3 text-center rounded-md hover:bg-gray-50"
              >
                <span class="text-xl font-medium text-white">
                  hey@dannpetty.com
                </span>
              </a>

              <div class="w-full flex flex-col space-y-10 justify-center">

                <router-link
                v-if="sharedState.currentUser && sharedState.currentUser.userData.payment && page !== 'home'"
                :to="'/'"
                class="-m-3 p-3 text-center text-xl font-medium text-white rounded-md hover:bg-gray-50"
                id="user-menu-item-1"
                >View Course</router-link>

                <button
                v-if="!hideOnScroll && !user"
                @click="(e) => navigateTo(e, 'Signin')"
                class="
                    inline-flex
                    items-center
                    justify-center
                    rounded-full
                    text-sm
                    w-auto
                    border-2
                    px-6
                    py-2
                    text-right
                    font-medium
                    text-white
                    bg-black

                    focus:outline-none
                    seeDetails
                "
                >
                <span class="">Member Login</span>
                </button>

                <button
                  type="button"
                  @click="buyCourse"
                  class="
                    bg-green
                    text-center
                       px-6
                py-2
                    w-auto
                    rounded-full
                    shadow-md
                    text-base
                    font-semibold
                    text-black
                    focus:outline-none
                    animate-buy-menu
                    buyButton
                    border-2
                    border-green
                  "
                >
                  Course Closed
                </button>
              </div>
            </div>
            <div class="flex justify-center">
              <a
                class="focus:outline-none pt-40"
                href="https://twitter.com/dannpetty"
                target="_blank"
              >
                <svg
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.14615 16.8463C14.6056 16.8463 18.6856 10.6662 18.6856 5.30681C18.6856 5.13128 18.6856 4.95653 18.6737 4.78258C19.4675 4.20846 20.1526 3.4976 20.6971 2.68328C19.9569 3.01126 19.1717 3.22635 18.3677 3.32137C19.2144 2.81452 19.848 2.01733 20.1508 1.07816C19.3546 1.55057 18.4837 1.8835 17.5755 2.06258C16.964 1.41238 16.1552 0.981829 15.2744 0.837562C14.3936 0.693295 13.4898 0.843353 12.7028 1.26451C11.9159 1.68567 11.2897 2.35446 10.9211 3.16737C10.5525 3.98029 10.4622 4.89201 10.664 5.76146C9.05154 5.68063 7.47413 5.26161 6.03415 4.5316C4.59416 3.8016 3.32379 2.77693 2.3055 1.52411C1.78688 2.41694 1.62803 3.47387 1.86131 4.47969C2.09459 5.48552 2.70245 6.36463 3.56113 6.93802C2.91572 6.91889 2.28437 6.74478 1.72039 6.43039C1.72039 6.447 1.72039 6.46439 1.72039 6.48179C1.72064 7.41815 2.04478 8.32559 2.63782 9.05021C3.23086 9.77483 4.05629 10.272 4.97411 10.4574C4.37703 10.6203 3.75056 10.6441 3.14285 10.527C3.40201 11.3329 3.90656 12.0376 4.58593 12.5425C5.2653 13.0475 6.08552 13.3276 6.93187 13.3435C5.49576 14.4721 3.72169 15.0848 1.89513 15.083C1.57245 15.0824 1.25008 15.0628 0.929688 15.0245C2.78438 16.2147 4.94241 16.846 7.14615 16.8431"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import store from "../store";
import { gsap } from "gsap";

export default {
  name: "Nav",

  props: ["menu", "scrollPosition", "hideNav", "page"],

  data() {
    return {
        sharedState: store.state,
        expanded: false,
        hideOnScroll: false,
        user: null,
        profileMenu: false,
        mobileMenu: false,
        loginMenu: null,
        email: null,
        password: null,
        errorMessage: "",
        textMode: false
    };
  },

  watch: {
    scrollPosition(newVal) {
      this.hideOnScroll = newVal;
    },
    "sharedState.currentUser"(newVal) {
      if (newVal) {
        this.user = newVal;
      }
    },
  },

  created() {
    if (store.state.currentUser) {
      this.user = api.state.firebase.auth().currentUser;
    }

    this.hideOnScroll = this.scrollPosition;
  },

  destroy() {
    document.body.classList.remove("overflow-hidden");
  },

  methods: {
    toggleTextMode() {
        this.textMode = !this.textMode

        this.$emit('toggleTextMode', {
            textMode: this.textMode
        })
    },

    showLogin() {
        let timeline = gsap.timeline({ paused: true })

        if (!this.loginMenu) {
            timeline.fromTo('#login-menu', { height: 0, width: 0 }, { height: '280px', width: '320px', duration: 0.5 })
            timeline.fromTo('#login-content', { opacity: 0, y: -20 }, { opacity: 1, y: 0, duration: 0.5 }, 0.2)
        } else {
            timeline.fromTo('#login-content', { opacity: 1, y: 0 }, { opacity: 0, y: -20, duration: 0.2 })
            timeline.fromTo('#login-menu', { height: '280px', width: '320px' }, { height: 0, width: 0, duration: 0.4 }, 0)
        }

        timeline.play()
        this.loginMenu = !this.loginMenu
    },

    async submit(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      this.errorMessage = null;

      if (this.email && this.password) {
        const email = this.email.trim();
        const password = this.password.trim();

        await api.state.firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode);
            console.log(errorMessage);
            this.errorMessage =
              "The password is invalid or the user does not have a password.";
            return;
          });

        if (!this.errorMessage) {
            this.showLogin()
            this.$router.push({
              name: "Home",
            });
        } else {
          console.log("Error message", this.errorMessage);
        }
      } else {
        this.errorMessage = "Please fill in all the fields.";
      }

      return null;
    },

    postJob() {
    //   this.$emit("postJob");
    },

    buyCourse() {
      if (!api.state.firebase.auth().currentUser) {
        this.$router.push({ name: "Signup" });
      } else {
        this.$emit("buyCourse");
      }
    },

    navigateTo(e, route) {
      e.preventDefault();
      document.body.classList.remove("overflow-hidden");
          console.log('_2')
      this.$router.push({ name: route });
    },

    showMobileMenu() {
      this.mobileMenu = !this.mobileMenu;

      if (this.mobileMenu) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },

    toggle(e, page) {
      if (this.$router.currentRoute.fullPath === "/") {
        this.$emit("toggleNav", {
          page: page,
        });
      } else {
        this.$router.push("/");
      }
    },

    openMenu(e) {
      e.preventDefault();
      this.profileMenu = !this.profileMenu;
    },

    signOut() {
      api.state.firebase
        .auth()
        .signOut()
        .then(
          function () {
            store.setAction({
              which: "signout",
            });
            console.log("Signed out");
          },
          function (error) {
            console.log("Error signing out:", error);
          }
        );

      this.user = null;
      this.profileMenu = false;

        // console.log('_1')
      this.$router.push("/buy").catch(() => {

      });
    },

    expandJob(e) {
      e.preventDefault();
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style scoped>
</style>
