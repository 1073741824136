import api from "@/api";
import axios from "axios";

export default {

    async get({ userId }) {
        const items = [];

        const snapshot = await api.state.firestoreDB.collection("users").doc(userId).get();

        items.push({
            id: snapshot.id,
            ...snapshot.data()
        });

        return items[0];
    },

    // async getUserCourses({ userId }) {
    //     const items = [];

    //     const snapshot = await api.state.firestoreDB
    //         .collection("users")
    //         .doc(userId)
    //         .collection('courses')
    //         .get();

    //     snapshot.forEach((doc) => {
    //         items.push({
    //             id: doc.id,
    //             ...doc.data()
    //         });
    //     })

    //     return items;
    // },

    // async getUserCourse({ userId }) {
    //     const items = [];

    //     const snapshot = await api.state.firestoreDB
    //         .collection("users")
    //         .doc(userId)
    //         .collection('courses')
    //         .get();

    //         snapshot.forEach((doc) => {
    //             items.push({
    //                 id: doc.id,
    //                 ...doc.data()
    //             });
    //         })

    //     return items[0];
    // },

    async getResetEmailLink({ email }) {
        try {
            const results = await axios.post('/get-reset-email-link', {
                email
            })

            return results
        } catch (error) {
            console.log(error)
            return null
        }



    },

    async getUsers() {
        const items = [];

        const snapshot = await api.state.firestoreDB.collection("users").get();

        snapshot.forEach(doc => {
            items.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return items;
    },

    async addUser({ user }) {
        return await api.state.firestoreDB
            .collection("users")
            .doc(user.userId)
            .set({
                ...user,
                createdAt: new Date()
            });
    },

    async getVideos({ userId }) {
        const users = [];
        const snapshot = await api.state.firestoreDB
            .collection("users")
            .doc(userId)
            .get();

        users.push({
            id: snapshot.id,
            ...snapshot.data()
        });

        const videos = users[0].videos

        return videos ? videos : []
    },

    async setAsPlayed({ userId, videoId }) {
        const users = [];

        const snapshot = await api.state.firestoreDB
            .collection("users")
            .doc(userId)
            .get();

        users.push({
            id: snapshot.id,
            ...snapshot.data()
        });

        let user = users[0]

        if (!user.videos) {
            user.videos = []
        }

        let videoIndex = user.videos.findIndex((video) => video.videoId === videoId)

        if (videoIndex !== -1) {
            user.videos[videoIndex].played = true
        } else {
            user.videos.push({
                videoId: videoId,
                played: true
            })
        }

        await api.state.firestoreDB
            .collection("users")
            .doc(userId)
            .set({
                ...user
            }, {
                merge: true
            })
    }
};
