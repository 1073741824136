<template>
    <aside :class="isMobile && expanded ? 'z-50' : 'z-10'"
    class="sidebar flex flex-col text-sm flex-none translate-x-0 opacity-1 h-full sticky  md:top-10 toc-mobile">
        <div @click="expandContents" class="flex flex-row justify-between items-center w-full h-12 px-4 relative z-10 md:hidden">
            <p class="text-black font-atlas-medium">{{selectedChapter}}</p>
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M5 8L0.669873 0.499999L9.33013 0.5L5 8Z" fill="#141414"/>
            </svg>
        </div>
        <ul v-if="filteredContents" :class="expanded && isMobile ? 'h-screen z-50 ' : expanded || !isMobile ? 'h-screen' : 'hidden' " class="toc-main text-gray-dark space-y-12 min-w-max pt-8 px-4 md:pl-10 md:pr-10 overflow-y-scroll">
          <li class="toc-title" v-for="(section, index) in filteredContents" :key="index">
              <div class="space-x-3 mt-4 text-black font-atlas-medium">
                    <span v-if="section.number" className="text-black font-atlas-medium">{{section.number}}</span>
                    <a @click="(e) => scrollTo(e, section.link, section.title, section.preview)" class="mb-4  text-black font-atlas-medium" :href="section.link" :class="preview && section.preview ? '' : !preview ? '' : 'line-through'">{{section.title}}</a>
              </div>
            <ul class="space-y-2 mt-4 list-disc list-inside">
              <li v-for="(chapter, chapterIndex) in section.chapters" :key="chapterIndex" class="relative" :class="videos && videos.length > 0 && videos.filter((v) => v.videoId === chapter.videoId).length > 0 ? 'played' : ''">
                <a
                  class="toc-ancor-link font-atlas hover:text-black"
                  :class="(selectedChapter === getTitle(chapter.link)) && (preview && !chapter.preview) ? 'line-through' : (preview && chapter.preview) && (selectedChapter === getTitle(chapter.link)) ? 'active-list' : (preview && !chapter.preview) && (selectedChapter !== getTitle(chapter.link)) ? 'line-through' : !preview && (selectedChapter === getTitle(chapter.link)) ? 'active-list' : ''"
                  @click="(e) => scrollTo(e, chapter.link, chapter.title, chapter.preview)"
                  :href="chapter.link"
                >
                <span :class="preview && chapter.free ? 'bg-green text-black uppercase px-2 py-1 rounded-md z-50' : 'hidden'">free</span>
                 {{chapter.title}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
    </aside>
</template>

<script>
import api from "@/api";

export default {
  name: "TableOfContents",

  props: ['preview', 'active', 'justPlayed', 'currentId'],

  watch: {
      'active'(newVal, oldVal) {
          if (newVal !== oldVal) {
              this.selectedChapter = this.getTitle(newVal)
          }
      },
    'justPlayed'(newVal, oldVal) {
          if (newVal !== oldVal) {
              this.videos.push({
                  videoId: newVal,
                  played: true
              })
          }
      },
  },

  computed: {
      filteredContents() {
            if (this.preview) {
                return this.contents
            } else {
                return this.contents.filter((c) => !c.hideOnAuth)
            }
      }
  },

  data() {
    return {
        expanded: false,
        isMobile: false,
        videos: [],
        selectedChapter: 'Introduction',
        contents: [{
            title: 'Introduction',
            link: 'introduction',
            preview: true,
            hideOnAuth: true,
            chapters: [
                {title: "Details", link: 'details', preview: true},
                {title: "What's Included.", link: 'whats-included', preview: true},
            ]
        },
        {
            title: 'Things You Must Know First',
            link: 'things-you-must-know-first',
            preview: true,
            videoId: '695192345',
            number: 1,
            chapters: [
                {title: "Don’t sweat the details yet.", link: 'dont-sweat-the-details', preview: true, free: true, videoId: '699600025'},
                {title: "Understand why you’re starting.", link: 'understand-why-youre-starting', preview: true, free: true, videoId: '695128370'},
                {title: "Don’t quit your job yet.", link: 'dont-quit-your-job-yet', preview: true, free: true, videoId: '695123900'},
                {title: "Should you freelance full-time or part-time?", link: 'should-you-freelance-full-time-or-part-time', videoId: '695122590'},
                {title: "You must let them know you exist.", link: 'you-must-let-them-know-you-exist', videoId: '695124765'},
                {title: "Relationships are everything.", link: 'relationships-are-everything', videoId: '695126885'},
                {title: "There’s no such thing as job security.", link: 'theres-no-such-thing-as-job-security', videoId: '695124681'},
                {title: "Freelancing is not for everyone. That’s ok.", link: 'freelancing-is-not-for-everyone.-thats-ok', videoId: '695126318'},
                {title: "You can make it without a following on social media.", link: 'you-can-make-it-without-a-following-on-social-media', videoId: '701352519'},
                {title: "You can do it.", link: 'you-can-do-it', videoId: '701350631'},
            ]
        },
        {
            title: 'Setting Yourself Up (for Success)',
            link: 'chapter-two-setting-yourself-up-for-success',
            videoId: '695202219',
            number: 2,
            chapters: [
                {title: "Find your focus, story, and plan.", link: 'find-your-focus-story-and-plan', videoId: '695131501'},
                {title: "How to set up your brand.", link: 'how-to-set-up-your-brand', videoId: '695128749'},
                {title: "Find a good domain name and email address.", link: 'find-a-good-domain-name-and-email-address', videoId: '695130303'},
                {title: "Have clear messaging everywhere.", link: 'have-clear-messaging-everywhere', videoId: '695129852'},
                {title: '“Getting started” myths.', link: 'getting-started-myths', videoId: '695130003'},
                {title: "How I got started.", link: 'how-i-got-started', videoId: '695130756'},
                {title: "Reputation is everything.", link: 'reputation-is-everything', videoId: '695130931'},
                {title: "Build trust.", link: 'build-trust', videoId: '695129686'},
            ]
        },
        {
            title: 'How to Get Work and Stand Out',
            link: 'chapter-three-how-to-get-work-and-stand-out',
            videoId: '695211736',
            number: 3,
            chapters: [
                {title: "Let them know you exist.", link: 'let-them-know-you-exist', videoId: '695133743'},
                {title: "Finding your first client.", link: 'finding-your-first-client', videoId: '695133131'},
                {title: 'When to work for “free.”', link: 'when-to-work-for-free', videoId: '695136368'},
                {title: 'Example: I created Nixon.com for “free.”', link: 'example-i-created-nixon.com-for-free', videoId: '701353452'},
                {title: "Go where the clients are.", link: 'go-where-the-clients-are', videoId: '695136777'},
                {title: "Side projects show your passion.", link: 'side-projects-show-your-passion', videoId: '695135249'},
                {title: "Build a stellar portfolio.", link: 'build-a-stellar-portfolio', videoId: '695136308'},
                {title: "What to do if you have no work.", link: 'what-to-do-if-you-have-no-work', videoId: '695134363'},
                {title: "Watch your online presence.", link: 'watch-your-online-presence'},
                {title: "Learn the power of a question.", link: 'learn-the-power-of-a-question', videoId: '695134821'},
                {title: "Try five ideas to get you noticed.", link: 'try-five-ideas-to-get-you-noticed', videoId: '695132107'},
            ]
        },
        {
            title: 'Working with Clients',
            link: 'chapter-four-working-with-clients',
            videoId: '695214976',
            number: 4,
            chapters: [
                {title: "Communication is everything.", link: 'communication-is-everything', videoId: '695137613'},
                {title: "Be easy to work with.", link: 'be-easy-to-work-with', videoId: '695138206'},
                {title: 'Be upfront about your timing.', link: 'be-upfront-about-your-timing', videoId: '695138701'},
                {title: 'What clients care about.', link: 'what-clients-care-about', videoId: '695136858'},
                {title: "Common mistakes to avoid.", link: 'common-mistakes-to-avoid', videoId: '695137056'},
            ]
        },
        {
            title: 'Guide to Pricing',
            link: 'chapter-five-guide-to-pricing',
            videoId: '696770040',
            number: 5,
            chapters: [
                {title: "How I Charge.", link: 'how-i-charge', videoId: '695142614'},
                {title: "Important: be flexible.", link: 'important-be-flexible', videoId: '701368179'},
                {title: "Follow these four steps to discover your pricing.", link: 'follow-these-four-steps-to-discover-your-pricing', videoId: '695139078'},
                {title: "Consider project-based pricing.", link: 'consider-project-based-pricing', videoId: '695145894'},
                {title: "Charge weekly (my favorite).", link: 'charge-weekly-my-favorite', videoId: '695141205'},
                {title: "Value-based pricing.", link: 'value-based-pricing', videoId: '695146323'},
                {title: "Charge hourly (My least favorite).", link: 'charge-hourly-my-least-favorite', videoId: '695140758'},
                {title: "Stop charging hourly (when/if you can).", link: 'stop-charging-hourly-whenif-you-can', videoId: '695146167'},
                {title: "Give them options.", link: 'give-them-options', videoId: '695142243'},
                {title: "Refer a friend > saying no.", link: 'refer-a-friend-saying-no', videoId: '695146132'},
                {title: "What I charge for web design.", link: 'what-i-charge-for-web-design', videoId: '695142614'},
                {title: "Take a percentage upfront when you can.", link: 'take-a-percentage-upfront-when-you-can', videoId: '695145486'},
                {title: "Consider getting creative with payments.", link: 'consider-getting-creative-with-payments', videoId: '695141757'},
                {title: "Learn how to invoice & accept payment.", link: 'learn-how-to-invoice-accept-payment', videoId: '695144963'},
                {title: "Offer discounts.", link: 'offer-discounts', videoId: '695141537'},
                {title: "Double your income.", link: 'double-your-income', videoId: '700584018'},
                {title: "Bonus: Up-sale the client.", link: 'bonus-up-sale-the-client', videoId: '700585300'},
                {title: 'Stop asking, “What’s your budget?”', link: 'stop-asking-whats-your-budget', videoId: '700585851'},
                {title: "Avoid these common mistakes with pricing.", link: 'avoid-these-common-mistakes-with-pricing', videoId: '700582913'},
            ]
        },
        {
            title: 'Everything Portfolio',
            link: 'chapter-six-everything-portfolio',
            videoId: '697766583',
            number: 6,
            chapters: [
                {title: "What is a portfolio?", link: 'what-is-a-portfolio', videoId: '697765734'},
                {title: "Find your story.", link: 'find-your-story', videoId: '697764141'},
                {title: "Find your plan.", link: 'find-your-plan', videoId: '697763044'},
                {title: "Decide which tool you’ll use.", link: 'decide-which-tool-youll-use', videoId: '697764623'},
                {title: "Divide your portfolio into 4 main sections.", link: 'divide-your-portfolio-into-4-main-sections', videoId: '697746634'},
                {title: "Only show the work you want to get.", link: 'only-show-the-work-you-want-to-get', videoId: '697762078'},
                {title: "Only show your best work.", link: 'only-show-your-best-work', videoId: '697765734'},
                {title: "Show that you’re focused.", link: 'show-that-youre-focused', videoId: '697754536'},
                {title: "What if you don’t have any work for your portfolio?", link: 'what-if-you-dont-have-any-work-for-your-portfolio', videoId: '697760973'},
                {title: "Make it easy to contact you.", link: 'make-it-easy-to-contact-you', videoId: '697749165'},
                {title: "Never stop adding work.", link: 'never-stop-adding-work', videoId: '697760624'},
            ]
        },
        {
            title: 'The Not-So-Fun Side of Freelancing',
            link: 'chapter-7-the-not-so-fun-side-of-freelancing',
            videoId: '696860353',
            number: 7,
            chapters: [
                {title: "What do I do if I don’t get work?", link: 'what-do-i-do-if-i-dont-get-work', videoId: '696761657'},
                {title: "What do I do if a client refuses to pay me?", link: 'what-do-i-do-if-a-client-refuses-to-pay-me', videoId: '696759719'},
                {title: "Learn how to handle taxes as a freelancer.", link: 'learn-how-to-handle-taxes-as-a-freelancer', videoId: '696767117'},
                {title: "Avoid these common mistakes and traps.", link: 'avoid-these-common-mistakes-and-traps', videoId: '696758042'},
                {title: "Learn to deal with imposter syndrome.", link: 'learn-to-deal-with-imposter-syndrome', videoId: '696764082'},
                {title: "Learn these 10 secrets for freelance success.", link: 'learn-these-10-secrets-for-freelance-success', videoId: '696753415'},
            ]
        },
        {
            title: '',
            link: '',
            number: '',
            chapters: []
        },{
            title: '',
            link: '',
            number: '',
            chapters: []
        }]
    };
  },


  async created() {
        this.isMobile = this.checkMobile()

        if (api.state.firebase.auth().currentUser) {
            const videos = await  api.users.getVideos({
                userId: api.state.firebase.auth().currentUser.uid,
            })

            this.videos = videos
        }
  },

  methods: {
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    expandContents() {

        this.expanded = !this.expanded
        this.$emit('onExpand', {
            value: this.expanded
        })
    },

    checkMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },

    scrollTo(e, href, title, isPreview) {
        if (e) {
            e.preventDefault()
        }

        if(this.preview && !isPreview) {
            this.$emit('showBuy');
        } else {
            this.$emit('scrollTo', {
                href: href
            });
        }

        this.selectedChapter = href.replaceAll('-',' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
        this.expanded = false
    },

      getTitle(link) {
      return link.replaceAll('-',' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  }
  },


};
</script>

<style>
</style>
