<template>
<div id="introduction" class="book-content space-y-8">

<h2 ref="chapter1" v-view="(e) => viewHandler(e, 'chapter-one-things-you-must-know-first')" id="chapter-one-things-you-must-know-first" class=" text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter One:
Things You Must Know First</strong></h2>

<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'10f6eee85fec4dadace75718ec9b23c8'" :title="'Chapter 1'" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695192345' || v.videoId === '10f6eee85fec4dadace75718ec9b23c8')"/>
<p class="text-black-caption text-base font-atlas-medium">Complete Chapter Video - 49:00</p>
</div>
<p>Has this been you? Maybe it’s 3:30 p.m. on a Tuesday. The workweek
has started, but Friday is still far away. You seem to have too much on
your plate — and yet you’re simultaneously bored. You look out the
window and see nice things. A park, maybe. Birds landing on tree limbs.
Then, as you think about your 20-minute commute back home at the end of
the workday, it hits you: those birds have a lot more freedom than you
do.</p>
<p>I’m not saying everyone with a nine-to-five hates their job. Far from
it. But I’m saying that if you’re interested in freelancing, the odds
are pretty good that some part of that scenario resonates with you. And
while we’d all like to win the lottery or magically secure a passive
income that lets us relax on the beach with mojitos in our hands, the
truth is there’s a much more reliable way to take your work life into
your own hands.</p>
<p>That way: going independent. Going freelance.</p>
<p>If you’re new to it, the very idea of it can throw you off. People
might tell you things like <em>if it sounds too good to be true, it
probably is</em>. But the realities of freelancing aren’t really as
radical as you might imagine. With freelancing, you’ll still have to
work for your income. With freelancing, you’ll still be accountable to
others to do a good job. With freelancing, you’ll still have deadlines,
bills, and just about everything else you might associate with the
nine-to-five.</p>
<p>But you’ll gain one very important thing. <em>Control</em>.</p>
<p>This is a simple A-to-B journey. There’s nothing stopping you from
quitting your job today and going out to earn your first freelance
clients. And honestly — taking action so decisively would bode well for
your makeup as a freelancer.</p>
<p>But there are still things you can do to shorten the learning curve.
Think of it as a flight plan: a checklist of things to do before you go
wheels-up and leave the solid ground of a steady paycheck. In my time as
a freelancer, I’ve had successes and failures. If you read this book
from beginning to end, you might be able to double-down on my successes
— and skip some of the failures.</p>
<p>That’s the goal. It’s been a lot of work. A lot of clients. A lot of mistakes. A lot
of achievements. A lot of joy. A lot of learning. And what does it all
add up to?</p>
<p>Let’s get started.</p>

<h5 id="dont-sweat-the-details"  v-view="(e) => viewHandler(e, 'dont-sweat-the-details')" class="section"><strong>Don’t sweat the details
yet.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'4fb99b5bdfb54c8e9be7c81b1eb13ddc'" :title="`Don’t sweat the details
yet`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '699600025' || v.videoId === '4fb99b5bdfb54c8e9be7c81b1eb13ddc')"/>
<p>The hardest part about becoming a freelancer is starting. That’s
mainly because on paper, it can sound daunting having to come up with a
name. Making it legal. Getting a bank. Setting up a portfolio. Marketing
yourself. Selling yourself. Managing projects. Getting clients. Writing
proposals. And then there’s actually <em>doing</em> the service you’re
offering your clients.</p>
<p>Let’s be real. It’s stressful even just thinking about it. It’s why
so many potential freelancers keep putting off becoming one — they get
too buried into the details they think they need in order to “start
freelancing.”</p>
<p>You could literally start now. Don’t worry about getting everything
together yet. Don’t sweat those details.</p>
<p>I’m serious, if you want to become a freelancer, you have to just
start. No one is holding you back but yourself at this stage. Let’s be
honest, it’s the only path to becoming a freelancer – just starting.
Otherwise, you’re just thinking about being a freelancer, not becoming
one.</p>
<p>You don’t need to have it all figured out at first. Don’t rush off
and create an LLC or bank account either. Don’t even think about those
things yet.</p>
<p>First, get a project. Secondly, get paid. The rest can come
later.</p>
<p>I told you, this book is going to be different. I’m going to be real.
I’m going to be simple and I’m going to give it to you straight. You
will never fully be prepared for what freelancing may bring. You will
never get it 100% perfect. So stop procrastinating and just start.</p>
<p>There’s no better time than now.</p>
<p>Listen: for most of my freelance career, I just used my personal name
and social security number (on w9’s) for my business. For <em>years</em>
I did this. Hundreds of thousands of dollars made, if not a million,
before I did anything different. It’s not the most optimal way and makes
tax season a little harder, but I mention that just so you know that you
<em>can</em> start with what you’ve got now. Don’t even wait until your
personal website is ready. It’s just another thing to hold you back.</p>
<p>I put all my design work on Behance.net (before there was
Dribbble.com and Instagram and things) for free and got started letting
the world know I existed. I didn’t wait for a personal website. I’m in the boat that you don’t even really
need a personal website. Will it help? Sure. But sometimes it just makes
things worse. And it definitely slows people down from putting
themselves out there. At first, just go where the clients are – social
sharing sites. No one will find your personal website unless it was
shared on one of those sites anyways, or unless you’re a genius at SEO.
But good luck there. Google is always changing its algorithms. Your
personal brand is more important.</p>
<p>Keep it simple. Just get your first check made out to you. Would it
be nice to have a legal business name, bank account, website, and
everything beforehand? Again, yeah sure, of course it would.</p>
<p>Is it necessary? Absolutely not.</p>
<p>But you’ve got to understand that stuff like that takes time and
becoming a freelancer is a process. Let me repeat that. IT’S A PROCESS.
It’s a process that is hard enough as it is already…so just keep it
simple.</p>
<p>Besides, it’s also just smart to wait until you get going and truly
figure out whether or not you even <em>enjoy</em> freelancing before you
go through all the trouble of creating an LLC, getting a bank account,
and everything that comes with it. I see so many people that only make
it freelancing for a few months before they go back full-time somewhere
else.</p>
<p>It’s not for everyone. We’re a select breed.</p>
<p>Every freelancer has learned how to freelance by — guess what? —
<em>freelancing</em>. It’s the best way to figure out what works best
for you because as you’ll hear repeatedly in this book, being successful
as a freelancer means many different things to different people. For
some, it’s all about the larger sum of money you can make compared to
being full-time for someone else. For others, it’s about more free time
and flexibility in their life. Of course, for some freelancing is the
only option available.</p>
<p>The most important thing is that you’re putting yourself out there,
getting work and getting paid in whichever way works best for you – not
how some article, YouTube video, or even this book and course, tells you how to. Do
what works for you. This book will just help you find out what that
is.</p>
<p>Hopefully it’s already clear: no matter what you read or what someone
tells you, there are <em>several</em> different ways to freelance.
That’s why I’m going to use this book to mention my opinions. But I’ll
also share lessons from other freelancers. You’ll see there’s no “X
marks the spot” in freelancing. Everyone’s map is different. I’m just
going to tell you how to read your own map in the fastest possible
way.</p>
<p>I’ll say it one more time. JUST START.</p>

<h5 id="understand-why-youre-starting" v-view="(e) => viewHandler(e, 'understand-why-youre-starting')" class="section"><strong>Understand why you’re
starting.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'34f7e1738806456faeaa2efd756e0d48'" :title="`Understand why you’re
starting`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695128370' || v.videoId === '34f7e1738806456faeaa2efd756e0d48')"/>
<p>This is a question that you’ll never stop asking yourself, even after
you’ve been freelancing for years. Why? Why am I doing this?</p>
<ul>
<li><blockquote>
<p>To earn extra money?</p>
</blockquote></li>
<li><blockquote>
<p>To have more freedom/flexibility?</p>
</blockquote></li>
<li><blockquote>
<p>To run your own business?</p>
</blockquote></li>
<li><blockquote>
<p>To kick start your career because you have no work?</p>
</blockquote></li>
<li><blockquote>
<p>Because you just got fired?</p>
</blockquote></li>
<li><blockquote>
<p>Because you can’t get a full-time job?</p>
</blockquote></li>
<li><blockquote>
<p>Because you’re burnt out at your current job?</p>
</blockquote></li>
<li><blockquote>
<p>Or are you like me and you want to freelance because you want to work
for as many clients as you can, make way more money, surf all day,
travel, and to watch my kids grow up?</p>
</blockquote></li>
</ul>
<p>Regardless of your reason, which may change throughout your career as
a freelancer, understanding why you do it will help focus your mindset.
It will also help you determine what kind of advice you can listen to,
and what you can toss away.</p>
<p>Some people will say you can never work on more than one project at
once, for example. But maybe those freelancers aren’t trying to maximize
income like you are. Maybe their set skill takes longer on projects and
requires a bit more attention. Some will say you still have to work
normal business hours, like the rest of the world, but they may not
understand you freelance so you can travel or surf during the day or
that you can only work 4-5 hours a day because that’s when your kids are
at school.</p>
<p>My own reasons? I started freelancing so I could work on as many
projects for as many clients as I wanted. I knew I wanted to earn as
much money as possible. I knew that I wanted to surf during the day and
not work as much as everyone else. I also knew I wanted to have kids in
the future and wanted to be with them as much as possible.</p>
<p>Understanding my “why” helped me decide which and how many jobs to
accept and which ones to turn down. More importantly, it also helped me
understand how much I needed to make to sustain my “why.” I quit my job
as a Creative Director at a famous design agency to go full-time
freelance because it wasn’t fulfilling any of those “whys.” I had to
work specifically 9-6 (at least), couldn’t surf during the day, and
couldn’t travel and work from remote locations when I needed/wanted/or
felt uninspired. I was also set to one salary range based on company
size and could only get paid more maybe every year if I worked super
hard. I didn’t like that all of my “whys” were all unattainable
full-time for someone else. But that’s just me — I don’t like
limitations unless I’ve put them there for a reason.</p>
<p>So now that we know <em>why</em> we want to freelance, let’s figure
out when we’re going to start.</p>

<h5 id="dont-quit-your-job-yet" v-view="(e) => viewHandler(e, 'dont-quit-your-job-yet')" class="section"><strong>Don’t quit your job
yet.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'a2b9b2d0d86d43fcb685858995ed447a'" :title="`Don’t quit your job
yet`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695123900' || v.videoId === 'a2b9b2d0d86d43fcb685858995ed447a')"/>
<p>I know you’ve heard this one before, but I’ll add my own twist to it
by saying this is not a rule. Again, there are no rules for how to
freelance. This advice is just smart. It’s smart to have work lined up
before you quit a job and start freelancing full-time because honestly
for starters, you might not even like it. Maybe even more important,
work isn’t always there. Even after a decade of freelancing, you will
hit a dry spell. Happens to all of us more than we like to admit.</p>
<p>What this advice forgets, as it’s constantly shouting out, is that
there are people that don’t have the luxury of a full-time job with
great pay. So I’ll say this: if this is you, consider trying to get a
job at a coffee shop or somewhere a lot of people cross paths so you can
meet people and tell them you’re freelancing while still making a little
bit of income. For some of you, even that sounds like a hard reach. I
understand. Do what you can to get paid, legally, so you’re not digging
yourself a bigger hole trying to freelance — that’s all this advice is
saying. No job is stable, especially not freelancing.</p>
<p>I know you’re eager to get out of the full-time job and live the
luxurious life of a full-time freelancer you’ve been dreaming about. But
let's slow down for a minute and think about this. If you just jump
straight in, will you have work? Do you have any work lined up? What if
you don’t find a job within a couple of weeks — then what? Do you have a
little bit of money saved up in case that happens so you can stick with
trying a little bit longer? See what I’m getting at? It’s tricky. In a
perfect world, you’ll get to work right away, and everything will be
just like you dreamed about but again let’s stay in reality — might not
happen that way.</p>
<p>With that understood, the best approach is to start freelancing
part-time. You don’t have to give yourself a timeline either. Oh in 2
weeks I’ll go full-time, or this summer. You’ll know when the time is
right. But if you’re super eager just wait until you already have at
least 1 project lined up, if not 2, that could make you enough money to
give freelancing <em>an honest</em> try. It’s important you give it a
solid honest try so you don’t have any regrets later on. The only way to
do that is with time.</p>
<p>Keep in mind: freelancing can be a little slow at first. One thing we
see way too often is freelancers taking the jump full-time but only
lasting a few weeks — in most cases, it’s because they didn’t have
enough money saved up to give it a proper go and respectfully, get a
little anxious with all the unknowns that come with freelancing.</p>
<p>With that said, that situation (or something like that) is highly
possible, even if you’re a seasoned freelancer. There’s absolutely
nothing wrong with that. Some consider it a break, others an
opportunity; every situation is different. Over my 15-year career as a
full-time freelancer, I went to work for six companies full-time for a
short period of time — each with a reason.</p>
<p>Here are a couple of examples of that:</p>
<p><strong>Medium.com</strong> — before Medium.com was a thing, the team
behind it was called Obvious Corp, led by Evan Williams and Biz Stone, the founders of Twitter. I
was one of the first designers there. I got a DM on Twitter from Ev,
which is wild because he created Twitter, saying he liked my work and
wanted me to come work with him. Like most opportunities like this, I
mentioned I was only interested in freelancing opportunities and just
like that, I started freelancing with them for 2 months while we were
exploring what later became Medium.com. After 2 months, I learned so
much working on the product I decided to join full-time until the
launch.</p>
<p>I left once it launched, as I felt my job there was done, but also
because I got accepted into <strong>Y-Combinator</strong>. I noticed a
problem with communication at companies I’d worked for, so decided to
create a product called HUM. Right before our demo day, a product we all
know as Slack was announced, destroying all momentum for me and my
co-founder. So, I went back to full-time freelancing. I was even able to
freelance for a majority of my YC class which made it all worth it. I
knew I enjoyed freelancing more than working on one single product, but
Medium was one of those once-in-a-lifetime opportunities and I wanted to
experience it. Remember what I said earlier about opportunities, grab
them when you see them.</p>
<p><strong>Note:</strong> As I was full-time at Medium, I never stopped
letting people know I was available for side freelance. It was important
to me I never lost that momentum because I knew how hard it can be to
gain back.</p>

<h5 v-view="(e) => viewHandler(e, 'should-you-freelance-full-time-or-part-time')" id="should-you-freelance-full-time-or-part-time" class="section"><strong>Should you
freelance full-time or part-time?</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'9c776aaf227e4f43bf7c4dea4b964a54'" :title="`Should you
freelance full-time or part-time?`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695122590' || v.videoId === '9c776aaf227e4f43bf7c4dea4b964a54')" />
<p>First, look at your situation. Do you have a few consistent clients
lined up already, or are you thinking about starting from scratch?</p>
<p>If you’re starting from scratch, it’s going to be tough to build a
business while wondering how you’re going to pay your light bill. Even
if you can devote eight hours a day to seeking work, that’s eight hours
without pay.</p>
<p>I’ve said it before, but it bears repeating: anyone who’s spent time
freelancing knows there will be dry spells. These are often unexplained.
You might be doing everything right: sending emails out to great
clients, sprucing up your portfolio, being a great person to work with.
But there are ebbs and flows to freelancing no matter what you do. If
you’re planning on going full-time, do you have a plan for dealing with
a dry spell if you face one at the moment you start?</p>
<p>There are other questions you should answer, too. Do you have money
set aside? Are you capable of weathering some difficult months in the
early goings? There isn’t a cookie-cutter response here. Ultimately, you
have to strike the right balance for your situation. Blind optimism
isn’t good. But neither is avoiding the leap to full-time freelancing
when you can make a living with the clients you already have.</p>
<p>Whatever you do, get a few elements lined up first. I know—you’re
excited to get started. But if you haven’t proven to yourself that
you’re willing to put in the work to make freelancing a full-time gig
yet, how do you know you’re ready.</p>
<ul>
<li><blockquote>
<p>Do you have a working portfolio?</p>
</blockquote></li>
<li><blockquote>
<p>A clear idea of the kind of work you’re willing to do?</p>
</blockquote></li>
<li><blockquote>
<p>The types of clients you’re going to work for?</p>
</blockquote></li>
</ul>
<p>Finally, don’t let “part-time” become a code phrase for “never.” It
shouldn’t be an excuse to procrastinate. If there’s a point when you
earn more money freelancing than at your old nine-to-five, there’s no
reason you need the stress of two jobs. You can be a better freelancer
if you quit the full-time gig and take the leap.</p>
<p>If you’re going to freelance, there’s going to have to be part of you
that’s comfortable with uncertainty. It’s not something to avoid. It’s a
skill you can learn. And you’ll never learn it sitting on the couch.</p>
<p>The benefits of going full-time?</p>
<ul>
<li><blockquote>
<p><strong>Taking action.</strong> It’s almost always better to take
action. You never know what the road looks like until you’ve taken those
first steps. There’s an old saying: “Fortune favors the bold.” Going
full-time means you’ll immediately have time to start the essential
work, like creating a portfolio. When I started out, I didn’t even have
a website. But I found ways to get clients. Action is (and always will
be) the most important thing. The full-time strategy is congruent with
this. Some people do better diving into the deep end of the pool.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Striking while the iron’s hot.</strong> 35% of the global
workforce is freelance in some way. 75% of those workers say they
wouldn’t go back. Some quick math suggests the world is already
changing. Where do you think those trends are heading? Get into
freelancing now, especially while there’s still some room to make a name
for yourself.</p>
</blockquote></li>
</ul>
<p>The benefits of going part-time?</p>
<ul>
<li><blockquote>
<p><strong>Proof of concept.</strong> Let’s say your goal is to earn
$10,000 a month. Imagine you’ve built up working one day per week
freelancing, earning a nice income of $1,000 per month. Even though
you’re only 10% toward your goal, you’ve already got “proof of concept.”
You’ve figured out how to earn money independently. From there, it’s
just a matter of scaling that work into a full-time income.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Financial flexibility.</strong> A lot of people like to say
“don’t quit your day job.” And it’s good advice. There are plenty of
freelancers who make slow, steady transitions to full-time freelancing
and never miss a beat. The only caveat is that you shouldn’t work so
slow that you begin hedging your bets and use your old job as an excuse
not to take that final leap of faith.</p>
</blockquote></li>
</ul>

<h5 id="you-must-let-them-know-you-exist" v-view="(e) => viewHandler(e, 'you-must-let-them-know-you-exist')" class="section"><strong>You must let them
know you exist.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'b4387b69eea3458f92dfc8b2dfcbbbb5'"  :title="`You must let them
know you exist`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695124765' || v.videoId === 'b4387b69eea3458f92dfc8b2dfcbbbb5')" />
<p>Understand this: clients and projects will not appear out of thin
air. You can’t just put up a website in the sea of others and expect to
get an email for work. You have to do work to get work. You must
continually let people know that you exist out there. That you are
someone who is actively freelancing and doing great work. <em>If you do
nothing else from this book, at least do this.</em></p>
<p>You must let people know that you exist. Or else, sadly, no one will
ever know.</p>
<p>You’d be surprised actually how many people think by putting up a
website or sending out one tweet or post somewhere online that work will
magically come. It doesn’t work that way, y’all. It just doesn’t. These
are the folks that give up after a few weeks because no work ever came.
They didn’t try. We’re surrounded by “overnight” successes online that
we’re blinded by the fact that those overnight successes took a lot of
work upfront, sometimes for months or years. It doesn’t work that way!
Put in the work. Create. Create. Create! Share. Share. Share!</p>
<p>Here are some ideas on how you can let people know you exist.</p>
<p><strong>Be social and active on popular social networks where clients
are and post frequently.</strong> Be active in the appropriate places
for your industry: Twitter, Instagram, Facebook, Linkedin, Behance,
Dribbble, etc. My favorite is Twitter – it’s amazing the reach you can
have on that platform. People there are eager to help others on these
platforms and the more you share your work there, the more likely it is
you’ll get seen. Before you start sharing there, keep in mind sharing on
sites like Twitter, most tweets fly by people's feeds. But the reach is
massive. The key here is to post frequently, even if slightly tweaking
each post from the original. On sites like Instagram, tags are a huge
deal. Be sure to add tags to your work and don’t for a second think that
doing so is cheesy or shameful – think of it as free marketing. You’ve got to throw that ego away. On sites
like Dribbble, tags and keywords are also incredibly important as most
clients looking to hire someone go straight to the search to search for
things like “illustrator” “web designer” etc. Details in your tags are
important here. Just look at the difference in search results for shots
tagged “homepage” vs “home page” – it’s incredible.</p>
<p>The key to all of these is to post frequently. Try 2-3 times a week
if not more. See what people react to and don’t react to. Don’t get
upset if one of your posts doesn’t get any traction at all but then
another one does – take note of the differences and try to understand
why one “did better” than the other – tweak accordingly.</p>
<p><em>Important note: please, y’all, don’t worry about likes and
followings. Does it help – there’s no denying that. But you can do this
without likes! Some of the best I know have little to no following.
Don’t let things like that discourage you. Focus on your work. Focus on
your responses. Focus on the quality of content you’re sharing. Remind
yourself, are you doing this for followers or are you doing this for
work? Followings usually just become distractions – don’t get
distracted.</em></p>
<p>Let me quickly tell you the story about Marko. Marko had come to me
asking for help getting work so I checked out how he was currently
promoting himself. Once I looked at his Twitter and Dribbble accounts,
it was very clear why the work started to dry up – he wasn't active but
just sitting around waiting for the work to come to him. His Dribbble
page had maybe 5-6 projects and that’s it. His Twitter barely had any
signs of designs he’s made in the past and you’d have no idea he was
actively looking for work. So I told Marko to try something.</p>
<p>“Try posting a design every other day until work starts to come. You
don’t even have to create new work, just start sharing bits and pieces
of what you do have. Also, mention that you’re available for work. Maybe
focus on landing pages only, so you can become that landing page
guy.”</p>
<p>In around two weeks’ time, Marko started to get new clients reaching
out for landing page designs and would be completely booked. ever since.
He’s getting so much work now, he’s having friends help with the
projects. From zero work to too much work in a matter of weeks. Why? He
put in the work to simply let people know that he exists. It’s not
always that simple, but it’s the most basic thing you can do and in my
opinion, ideally you would never stop. Even when you’re overly booked,
still show your work – you always let them know you exist. The moment
you stop sharing, the moment work stops coming.</p>
<p><strong>Highly valuable tip: Respond to job opportunities publicly on
Twitter.</strong> You’re going to hear about work opportunities there
first before they’re even posted in other places and the icing on the
cake for me is that you can interact with the exact people hiring and
even members from the team, not recruiters. It’s brilliant and it’s
gross how many miss out on this. It’s also gross how many times I’ve
seen people reply to job opportunities only to fail epically. Let me
blow your mind for a minute. This is my favorite advice to give
people:</p>
<p>Let’s say you see someone post a tweet like this:</p>
<blockquote>
<p><em>Hi everyone! I’m currently seeking freelance pitches for someone
to give our corporate site a complete rebranding. Please send over your
portfolio samples and availability to: [email address].</em></p>
</blockquote>
<p>Look at the responses. Most of them just say something like “Hi! I’m
interested.” Maybe just a drop a link to their portfolio, or some other
half-hearted response.</p>
<p>Let me ask you, does a response like that even sound like you’re
interested? No, it doesn’t. Wasted opportunity.</p>
<p>But responding that way isn’t the only wasted opportunity. Here’s
what people fail to understand: when you respond to a public tweet,
guest what? Others can see it too! Even other people are hiring! I tell
all of my design students that when you see a tweet about a job
opportunity, you respond with the mindset that other companies or people
hiring will see it too, not just the one company you’re responding to.
So instead of responding super lazily like the examples above, you need
to think about how you can stand out and how you can show off what you
can do in one simple tweet. Next time, try responding with something
like this instead:</p>
<blockquote>
<p><em>“Hey (first name)! I love what y’all are doing with (problem
they’re solving) and it’s something I’d love to work on as well. I’m
really strong at (insert things you’re good at relative to the job).
I’ve attached some recent work (attached 3-4 items of work) – you can
find more at (portfolio url). Excited about the possibilities!”</em></p>
</blockquote>
<p>Friends, you never see people respond this way so when you respond
this way you will stand out big time. Also, you’ll notice that you’ll
have the most liked reply in the whole thread – helping you stand out
even further. You may even notice others replying to your reply, talking
highly of your work.</p>
<p>But the biggest benefit to responding this way is that not only will
the original person who tweeted actually be forced to see your work as
it’s not hidden behind any links or extra clicks, but also literally the
entire world can see it too. Meaning, anyone else who is hiring now has
an opportunity to know you exist. You just increased your odds of
finding work 100x stronger over simply applying to the job.</p>
<p><strong>You can also just DM people or cold email someone.</strong>
This is so underrated, it’s hilarious. Just send someone a DM from a
company that you enjoy. Tell them about yourself and why you love the
company and that you’re seeing if there are any job opportunities there.
Be sure to keep your DM or email incredibly short and simple and you’d
be surprised how many responses you can get. Just keep in mind, not
everyone checks their email a lot, nor their DMs. Anytime I do and see
someone take the time to message me their work and appreciate the work I
do, if it’s good and I can actually help them and if they clearly aren’t
just trying to use me, I’ll respond and I’ll even refer them to people
hiring. It’s literally my favorite thing to do.</p>
<p>Go to networking events like conferences. The trick to these is not
just to fill some seats and potentially pass out business cards, which
btw, please don’t hand anyone more trash to throw away – no one wants
that and there is no connection there. If you stir up an interesting
conversation with someone, say something like, “hey, are you on Twitter
(or whatever network you both have)?” If they say yes, then say
something like “Oh, we should follow each other” and bam, watch them
pull out their phone and literally follow you on the spot. BAM! You’re
now literally connected. They will see your tweets or the work you
share. If you ask someone for their phone number, email, or worse, give
them a business card, then you’ve missed out on an incredible
opportunity to actually “connect.” Use this!</p>
<p><strong>Tell people you’re looking for work.</strong> No brainer,
right? Well actually, people are extremely afraid to admit they need
work. For some reason, some people see this as a negative but in
reality, there’s nothing better you can do than to simply tell people
you’re looking for work. Whether friends in passing or blasting it to
the world – always tell people when you’re available for work and
looking.</p>
<p>You really need to constantly be letting people know you’re looking
for work, even when you have work because there’s nothing worse than
waiting until a project ends to just then start looking for more.
Remember, the best time to get work is when you’ve got work. Always
market yourself. There is absolutely nothing wrong with saying you’re
looking for work. It’s not desperate. It’s not weak. It’s smart. Do it
and don’t stop.</p>

<h5 id="relationships-are-everything" v-view="(e) => viewHandler(e, 'relationships-are-everything')" class="section"><strong>Relationships are
everything.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'8f21a715c0fd4b309bf7e5d2fe8e5664'"  :title="`Relationships are
everything`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695126885' || v.videoId === '8f21a715c0fd4b309bf7e5d2fe8e5664')"/>
<p>Out of everything in this book, this section is probably one of the
most important because everything in our careers revolve around
relationships. You might have zero relationships right now and that’s ok
– most of us started the same way. But you will find these relationships
as you start and continue to put yourself out there more and more. The
first step is to always let people know that you exist, and the second
step will always be building and maintaining those relationships.</p>
<p>When it comes to relationships, though, you’ve got to understand it’s
a two-way street. It’s not just about how someone treats you, it’s also
about how <em>you</em> treat them. The stronger the relationship, the
more likely that person wants to tell the world about you and vice
versa.<br />
<br />
To get started finding relationships, remember that their perception of
you is important. As a freelancer, it’s imperative that you're known as
someone that is incredibly simple to work with, has a good character,
delivers on time, is a fun experience to work with, etc. Note I didn’t
just say “does good work.” I’ve worked with a ton of people that do
incredible work, better than most others’ work, but they are not
enjoyable to work with. I’ll never refer them.</p>
<p>It’s important the client enjoys working with you. In return, they
refer you to their friends and other founders or CEOs. On top of that,
you may have friends or colleagues who think you’re a great human and
freelancer and will refer you to new business opportunities - the cycle
is endless and it’s THE best way to get work.</p>
<p>Learn to let others market for you.</p>
<p>Here are some tips to help you with relationships:</p>
<p><strong>Be as easy, simple, and fun to work with as
possible.</strong> No one likes to work with someone that is difficult.
Maybe they’re difficult to get a hold of, difficult by always demanding
a meeting or “quick” call to discuss anything, difficult by being overly
strict with zero flexibility on anything – whatever it may be, be
mindful of how “difficult” you might be and adjust accordingly.</p>
<p><strong>On most projects, I get started right away without any
demands upfront.</strong> Whoa, whoa, whoa. What? Nothing upfront? Let
me explain. This doesn’t mean that I never ask for money upfront. As a
freelancer, you definitely want to cover yourself and ensure you’re
getting paid before you do too much work. However, I find it best to
just get started and ask for your initial payment upfront at the same
time, not one before the other. This shows excitement for the project
but more importantly – trust. And what’s the most important part about
freelancing? Building relationships. How do you build relationships?
Through trust.</p>
<p>Every good freelancer I’ve worked with does this, but it’s not
something you’ll read about online. You will read the exact opposite
which is actually great on paper as most of these articles and other
books are only trying to help you make some money and that’s it.
Y'all, I’m trying to help you make money and gain relationships that
set the stage for long-term success.</p>
<p>No one will tell you to <em>just get started</em>, even though they
do it themselves. But I don’t care what others think. This is what I do
and it works. Imagine telling a client that you won’t get started till
you get your payment. You’re basically saying I don’t trust you, but you
need to trust me. Imagine your plumber coming over and demanding 50%
payment upfront before they do anything. Come on. I’ve even worked on
multi-million dollar projects at big agencies, we did the same thing
there.</p>
<p>Even if that’s what you’re going to do (avoiding working until
payment comes through), don’t phrase it that way because it sounds too
pushy. Again, you won’t read that online because it’s the opposite of
what is “smart” but again, they all do it. Instead, say something
like:</p>
<p>“Sounds good, I’ll get started right away. If you don’t mind, I’ve
attached my invoice for 50% upfront. Please send that over when you can.
Excited to work on this! Expect something from me in the next couple
days.”</p>
<p>It’s all about trust. Now I know what you’re thinking, what if I
don’t know the client, why should I trust them? That’s a great question.
Again, just phrase your question correctly and in trusting matter and
wait a little longer to get started – maybe just start with some
brainstorming or sketching. Most wires come through the same day when
you ask, or within 2-3 business days. Anything longer than that starts
to get sketchy for me. I always ask for same day wires or proof of wire
being sent. I’ll say something like “I accept same-day wire transfers.
Please let me know when you send it! Here’s my info: ____”</p>
<p>Majority of the time? When I say that, the client even follows up
with a screenshot of the transfer. I love it.</p>
<p><strong>Don’t send questionnaires.</strong> Nothing says “I don’t
respect your time nor do I have time for you” like sending your client a
questionnaire. If you’re too busy to have a conversation with your
client then you’re too busy to work with them, plain and simple. It’s
pretty shocking that even in today’s world with access to great phone
conversations and video calls that freelancers are still sending out
questionnaires to their clients. Please don’t do this. Just don’t. Set
up a kick-off conversation with them and let questions flow from that.
It’s much easier for your clients to hop on a call than it is to take a
test and answer tons of questions. A caveat to this of course is if
there is a language barrier – in that case, these can be efficient, but
you still want to ensure it comes across custom for this specific
project and not a basic templated questionnaire. Don’t forget, you
actually want to get to know your clients. You want a relationship with
them, not just a project! Relationships equal more work and
referrals.</p>
<p><strong>Trust your client and be trustworthy yourself.</strong> We’ve
already gone over some ways to do this. Just remember you can’t have a
relationship if you don’t have trust. The goal with every client is to
build a relationship.</p>
<p>I’ll talk about this in more detail later. But for now, remember that
every business relationship goes two ways. It may seem one-way in some
regards; the <em>money</em> flows in one direction while the
<em>work</em> flows in the other. But every business relationship
requires a foundation of trust. My approach? I make myself as easy to
trust and work with as possible. I want people to think of me the next
time they think they need to hire someone. To do that, I’m not just a
professional in the way I approach their work. I’m also the person they
can text any time. I keep it light and casual because I know that this
isn’t just one gig; this gig is an opportunity to build something that
can be great for both of us, long-term.</p>
<p><strong>Always be “available.”</strong> I’m going to let you in on a
little secret here. If a client comes to you and asks you if you’re
available, for one, never just say “no.” Instead, refer a friend –
garner that relationship and build that trust even without working with
the client. Also don’t say something like “yes, but in 2 weeks or next
month (whatever the future timeline may be). The moment you say you’re
not available now, that’s when the client goes hunting for someone else.
You’ve missed the opportunity. Here’s the thing no one thinks about: to
start a project, there’s usually some ramp-up time, right? Let’s say you
said no because you were wrapping up another project for two more weeks.
Well, in those two weeks, you could have been ramping up the other
project – chatting with the client about the project, getting the
proposal or agreements together, waiting 2-3 days for the wire transfer,
etc. When those two weeks are up you then might not have work and if you
do, you’ll need the same ramp-up time potentially on a different project
so now you’re not getting paid for one to two weeks when you could have
potentially already gotten your payment.</p>
<p><strong>Follow up and show you care about them and their
business.</strong> Did you work with some for a few weeks or months,
they paid you well, and everything was great? Follow up with them. Ask
them how the work is going. Ask them how the business is going. Ask them
how THEY are doing.</p>
<p><strong>Be flexible.</strong> I’ll go ahead and tell you, there’s
nothing going to slow a freelancer down more than them not being
flexible, especially on pricing. This is such an important detail I
wrote a whole section about it where we can go further in-depth but
while you’re here I’ll just say this, if you charge $1000 for a logo
design but a client can only come up with $900 and you tell them no then
you are doing this freelancing thing totally wrong and I hate to say it
but you’re doing to lose a ton of work opportunities over something like
10%. Just remember, 90% is better than 0%. Especially 90% plus a
relationship that could lead to even more.</p>
<p>Quick story: I once did a website for a pro golfer for about $500
when I first got started, ages ago. He was my design schoolteachers’
boyfriend and we worked together on the project and I gained her trust.
Three short years later, my teacher became the Creative Director at
National Geographic, and guess who the first freelancer was she called
to design a website for National Geographic when they needed a hand? Me.
I made nearly $100,000 on the project.</p>
<p>Imagine if I said no to that $500 early on in my career. Does that
mean every project will be that way? Absolutely not. I could see the
relationship in this. I could see the future opportunities were worth
way more valuable than a $500 check.</p>
<p><strong>Give discounts.</strong> If a client works with you more than
once, offer them a discount. If the last project you did was $20K and
they need another similar project, do it for $18,500. Don’t always do
this and definitely feel it out to see if it’s even appropriate but
don’t forget that offering discounts can be a great way to show your
appreciation of the relationship.</p>
<p><strong>Support a client on social platforms.</strong> This one is
easy: retweet and share stuff they share. You’d be surprised how few
people do this. Doing so makes the clients feel like you care about them
and the relationship enough to publicly support each other. Who knows,
maybe they’ll return the favor by doing the same for your work.</p>

<h5 id="theres-no-such-thing-as-job-security" v-view="(e) => viewHandler(e, 'theres-no-such-thing-as-job-security')" class="section"><strong>There’s no such
thing as job security.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'aafc49e00eb44237bcaaa05e36d42cf0'"  :title="`There’s no such
thing as job security`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695124681' || v.videoId === 'aafc49e00eb44237bcaaa05e36d42cf0')" />
<p>If you’re thinking about going freelance but you’re worried because
you like your “job security” at your current full-time position, let me
ask you one question. How many friends do you have that got laid off
before? How many of those were unexpected? Has this ever happened to
you? If it hasn’t, you may not be fully aware, but no job is secure. You
can get laid off at any moment. You’re not invincible and neither is
your company. Things happen. Don’t let this deter you from your goal of
going freelance.</p>

<h5 id="freelancing-is-not-for-everyone.-thats-ok" v-view="(e) => viewHandler(e, 'freelancing-is-not-for-everyone.-thats-ok')" class="section"><strong>Freelancing
is not for everyone. That’s ok.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'2d6afd2cfdb9469da6d972cb757bd47d'"  :title="`Freelancing
is not for everyone. That’s ok`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695126318' || v.videoId === '2d6afd2cfdb9469da6d972cb757bd47d')" />
<p>When you become a freelancer, no matter what service you’re providing
you now need to do the job of multiple people. You’ve suddenly become a
CEO, project manager, accountant, the Head of Marketing, and even the
janitor. Not everyone can handle this and that’s perfectly ok.</p>
<p>Just don’t let people tell you that you have to be organized to be a
freelancer. I’m the least organized person and it doesn’t hold me back
one bit. We’re all going to freelance a bit differently than the next
person but just keep in mind it’s not for everyone. Not everyone can
handle the unknowns, the extra stresses, or even the freedom. If it’s
not for you, it’s not for you. I will applaud you for trying. Just
promise yourself that if you try freelancing and it doesn’t work after a
proper go at it, promise that you’ll recognize that and be proud of
yourself for trying.</p>
<h5
id="you-can-make-it-without-a-following-on-social-media" v-view="(e) => viewHandler(e, 'you-can-make-it-without-a-following-on-social-media')" class="section"><strong>You
can make it without a following on social media.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'b47a153d38eb4a9c98c8636ce7f81fb1'"  :title="`You
can make it without a following on social media`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '701352519' || v.videoId === 'b47a153d38eb4a9c98c8636ce7f81fb1')" />


<p>Here’s the big myth. You need 5,000 followers on Twitter, 10,000 on
Instagram, and 100,000 LinkedIn friends before you can get started
freelancing.</p>
<p>The reality? The process usually happens in reverse. Imagine you’re
freelancing for a client. You’re killing it: providing great value and
going above and beyond for their needs. When someone else asks that
client if they know anyone in your line of work, who are they going to
think of? You.</p>
<p>You might have zero followers on Twitter at that point. All people
care about is how you treat them.</p>
<p>There’s no magic number here. If you’re waiting until you have 500 or
1,000 followers to start, you’ll be waiting a long time. Get going now.
Start building that portfolio now. Start networking now. In time, the
followers will come.</p>
<p>One more word: don’t forget that freelancing predates social media.
True, an active social media account is a major asset. But if you’re
going to spend all of your time networking rather than delivering great
results, you’re going to spend a lifetime wondering how other people do
it. Don’t be someone who wonders. Focus on your clients first. Social
media can come second.</p>
<h5 id="you-can-do-it" v-view="(e) => viewHandler(e, 'you-can-do-it')" class="section"><strong>You can do it.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'e49cb299ac5b40ff9e228b0c2000025a'"  :title="`You can do it`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '701350631' || v.videoId === 'e49cb299ac5b40ff9e228b0c2000025a')" />


<p>If you’re wondering if you’re capable of freelancing full-time,
remember this old quote from Ralph Waldo Emerson: do the thing and you
will have the power. In other words, you don’t know your capacity until
you try.</p>
<p>Don’t wait on the sidelines, wondering how it’s done. Start. Start
small, if you have to. Start with the smallest conceivable task, but get
started, because you’re probably going to prove to yourself that the
idea of doing something new is far scarier than the reality of it.</p>
<p>In one Reddit thread, a user once asked an entrepreneur’s community
how to start a business with $2,000. One of the top comments didn’t say
“start a business.” That’s too vague. Instead, the comment broke it down
into small parts: going to a freelancing platform, picking an industry
you like, identifying the five most requested jobs, creating a website
that solves that problem, etc.</p>
<p>When you put it like that, the process doesn’t sound so difficult,
does it? With enough skill, you could manage it in an afternoon.</p>
<p>The leap of faith is the hardest part. Starting small makes that leap
of faith small. It helps you build momentum. The idea isn’t to only do
small things but to demystify the process for yourself. They say that
starting is half the battle.</p>
<p>So why not make it the easiest part? Get the starting out of the way
and you’ll learn how easy the rest of the work can be.</p>
<p>If none of that will convince you, let me leave you with a quote from
George Bernard Shaw.</p>
<p>“People are always blaming their circumstances for what they are. I
don't believe in circumstances. The people who get on in this world are
the people who get up and look for the circumstances they want, and if
they can't find them, make them.”</p>
<p>That’s freelancing. But you’ll never find that out if you wait
forever for it to happen to you. Don’t wait. Make it happen.</p>

<h2 ref="chapter2" id="chapter-two-setting-yourself-up-for-success" v-view="(e) => viewHandler(e, 'chapter-two-setting-yourself-up-for-success')" class="chapter text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter
Two: Setting Yourself Up (for Success)</strong></h2>

<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'964ba31128a44997bf1e19efc784008b'"  :title="`Chapter
Two`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695202219' || v.videoId === '964ba31128a44997bf1e19efc784008b')"/>
<p class="text-black-caption font-atlas-medium text-base font-atlas">Complete Chapter Video - 45:10</p>
</div>
<p>We’ve all experienced this trepidation before. You’ve put a lot of
work into your portfolio. Then, when someone (who can potentially pay
you five figures for this project alone) starts to review it, you get
nervous. What if the portfolio is nothing like what they want? What if
your portfolio isn’t even that great? Just what makes a portfolio
“great” anyway?</p>
<p>It’s a simple question with a complicated answer.</p>
<p>For starters, don’t think of your portfolio as a static list. Think
of it as a curation of your best work. This is the work you want to be
remembered for; the best stuff, the pieces that have made people say
wow. It’s also more than that. It’s your brand. It’s your opportunity to
show who you are as an individual.</p>
<p>Writer, designer, artist, photographer—it doesn’t matter. You have to
have a voice. Yes, you want to show off your skillset, but you also want
to show that there is only one voice here, and if your clients want that
voice, they have to work with you.</p>
<p>In essence, a portfolio is your story. It’s the story of how you
developed that voice and how you learned to express it. It’s an
amalgamation of your interests, passions, skills, talent, and
experiences. All of these add up to a completely unique individual. Your
portfolio should show that you have a 100% monopoly on your voice and
your style.</p>
<p>A portfolio is also adaptable. It should change. Even the U.S.
Constitution changes via the amendment process. You should change your
portfolio, too. Clients and companies aren’t looking at your portfolio
to see what you’ve done in the past. They think they are, but that’s not
really what it’s about. The portfolio is there to show them what you can
do, now and in the future.</p>
<p>Here’s one thing most freelancers don’t consider. The clients hiring
you are looking at hundreds of portfolios at any given time. How will
you stand out?</p>
<p>Everyone’s use case for a portfolio is different. Some might focus on
getting work. Some might focus on showing off. We’ll talk more about
portfolios later in this book. But the key to remember is that to stand
out, you have to be distinctive; be different in unexpected ways. And
one of the most important ways to be distinctive is to show off your own
unique personality. If you can find a way to match your “use case” with
your unique personality, you’re setting yourself up for success.</p>
<p>You’re the only one on earth with your perspective. Use that to your
advantage.</p>
<p>Think of a portfolio as more than a place to show off your work. It’s
three things in one: a story, a plan, and a tool. Let’s dig in deeper
and figure out what you can do to identify what makes you unique—and
what you can do to stand out.</p>

<h5 id="find-your-focus-story-and-plan" v-view="(e) => viewHandler(e, 'find-your-focus-story-and-plan')" class="section"><strong>Find your focus, story,
and plan.</strong> </h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'5877f42a37b74293898c9227a817a046'"  :title="`Find your focus, story,
and plan`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695131501' || v.videoId === '5877f42a37b74293898c9227a817a046')" />
<p>Ever talk to someone and ask “what’s your story?”</p>
<p>You’re not asking them about why they’re the same as everybody else.
When you ask “what’s your story,” you’re trying to find something
unique.</p>
<p>It’s the same way with freelancers. Your story is what makes you
unique. What topic gets your heart pumping and makes your eyes go wide
with excitement? What gives you the juice to wake up in the morning?
Unless you’ve never felt an ounce of passion for anything in your life,
chances are you have a pretty compelling story to tell. It’s somewhere
in there. Find it, and communicate that in your portfolio.</p>
<p>Want an example? Here’s my story.</p>
<p><strong>Short version:</strong> I’m a freelance web designer and
iPhone app designer. I went to school for Graphic Design, got the
Bachelor of Arts—and by the way, no one ever asks me about my B.A.—and I
absolutely fell in love with website design. My passion? I love building
something online for the world that they can check out with just a few
clicks. I love that companies can host a beautiful design for the cost
of a domain and hosting. I’ve had an entrepreneurial drive within me for
as long as I can remember. I started my first business when I was 18 (an
online magazine for car enthusiasts), and I knew early on in my career
that web design would be my route and plan of action.</p>
<p>That’s not all. I love outdoor adventure. Surfing, hiking,
snowboarding. I love sports. Cars. Tech.</p>
<p>I also love people. Making connections along this entrepreneurial
journey has been one of the most satisfying parts of it. I tell clients
to text me any time. This hasn’t always been to my advantage. Sometimes,
I’m too friendly. I’ve been hurt. I’ve been taken advantage of. I’m an
introvert; shy, quiet, capable of being alone for days at a time when
others might recharge from a quiet fifteen minutes.</p>
<p>Does that sound like a story you’ve heard before?</p>
<p>Maybe one or two elements sound familiar. You’ve met web designers
before. You’ve met introverts before. You’ve met car enthusiasts before.
Pick out one element and it might not sound unique. But like someone
inventing a new “flavor” of soda by trying all sorts of options at the
soda fountain, it becomes something wholly unique when you add your
entire story together.</p>
<p>I take this same approach with my portfolio. I ask myself who I am:
my passions, my interests, my skills. I either write it into the copy of
the portfolio itself or add it to the content of my work. I think about
what pictures of myself I want to include. My goal isn’t to “get the
job.” My goal is to tell the unique story of who I am: what it is about
me that you can’t find anywhere else, no matter how long you looked.</p>
<p>That’s the story. Let’s move on to the plan.</p>
<p>To come up with a plan, I have to ask myself some very important
questions. Who’s going to read the portfolio? What do I want their
impression of me to be when they do read it?</p>
<p>Don’t overthink this part of the process. There aren’t wrong answers
here. I’m not going to look over your shoulder and say, “No—you should
have answered differently.” The only one checking your work here is you.
You have to come up with these answers. Who do you want to work with?
What story do you want to tell?</p>
<p>You might have guessed from my story above that I gravitate to
adventure-related companies. I gravitate to startups with interesting
stories themselves. How did you know that? Simple. Through the careful
selection of details from my life. And you can bet that my portfolio
shares the exact snippets and highlights from similar work I’ve done in
the past.</p>
<p>Just as a gifted writer describes a scene through well-chosen
physical details, you’ll craft a portfolio by selecting the right work.
Do you want to do web design for tech or car companies? Don’t show
brochure work for pharmaceuticals or logo concepts for fast food chains.
You might be proud of the work. But your portfolio is just as much about
the future as it is the past.</p>
<p>My own story dictates that I like working web design for adventure
clients: think Nike, Burton, Quiksilver, Oakley, Nixon, Red Bull,
Patagonia. My portfolio should show these kinds of brands the work I do
for them and similar companies.</p>
<p>But it shouldn’t only show that. Maybe I’ll show my Nixon.com
redesign or the work I did for Red Bull and The North Face. But I’ll
also throw in Google and Airbnb. I’ll show what I did for Holler, a
social networking app, because it reinforces other values in my story,
like the connections I create. Or I’ll show a big feature like my work
for Epicurrence when I did complete brand work for a week-long
“non-conference” event. This work shows my ability to construct work
around an entire brand, not just one sliver of work for a bigger
company.</p>
<p>I’ll also throw in one or two smaller clients so people don’t think
I’m so expensive they can’t afford me. I don’t want to portray myself as
someone who only works with the Nikes of the world. If I did, I’d only
include Nike in the portfolio. Remember: portfolios are just as much
about the future as about the past. So I include smaller start-up
clients to remind the start-ups of the world that, yes, I’m willing to
help them too, and consider that work among my proudest.</p>
<p>By now, you might be frustrated with me because I still haven’t
addressed one fundamental issue. <strong>What if you don’t have any good
work to show?</strong></p>
<p>Sure, it’s easy to talk about the benefits of building a
story-telling portfolio when you have a story like mine to tell. But how
do you get there in the first place?</p>
<p>Here’s the secret. Are you ready for it?</p>
<p>You make it yourself.</p>
<p>Okay, maybe that sounds a little disappointing—so let me explain.
There are a few ways you can approach your “portfolio creation”
process:</p>
<ul>
<li><blockquote>
<p><strong>Unpaid work.</strong> Gasp! I know. The two dirtiest words in
all of the world of freelancing. But remember: we’re talking about your
future here, and the work you show on your portfolio is by far the most
engaging reason a big-time company may hire you one day. This isn’t just
unpaid work. It’s an investment: in your skills, in your future, in your
ability to sacrifice a little bit in the short-term to build long-term
success. In fact, it’s so important for your future that I don’t want
you to make excuses. Try it. You might be surprised at the wonders it
does for your portfolio.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Get proactive.</strong> I can’t tell you how many times I’ve
come across freelancers who put their tiny two-hour project into their
portfolio, then sit around and wait for work to show up and knock on
their front door. That’s not how it works. Remember: a portfolio is not
a record of what’s been. A portfolio is also a blueprint for what you
want in the future. Use it as an opportunity to practice your craft. Go
create. Practice. Enhance your skills. Use that practice as portfolio
work. And ask yourself this: if you were hiring someone to do great work
for you, and their portfolio had a piece that was exactly what you
wanted, would you worry that the piece was on spec? Of course not. You’d
only care that they showed the talent to do it. And you’d hire them.</p>
</blockquote></li>
</ul>
<p>So now you have your story. You have a plan for telling that story.
What about the plan for making that story more than just a list of work
in your portfolio? That’s where it gets interesting.</p>

<h5 id="how-to-set-up-your-brand" v-view="(e) => viewHandler(e, 'how-to-set-up-your-brand')" class="section"><strong>How to set up your
brand.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'fdf4ade97e0049edbaf99310b2f5727a'"  :title="`How to set up your
brand`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695128749' || v.videoId === 'fdf4ade97e0049edbaf99310b2f5727a')" />
<p>Before you decide on your domain name for your portfolio website, go
back to your plan and consider what your goals are. Are you a freelancer
and you only want to be known as yourself, by your name? Are you an
individual that’s simply looking to get hired by a larger team? Are you
looking to grow a company yourself—maybe a little studio? Are you an
agency already? There’s no right or wrong answer. It just all depends on
what your plan is. For example, if you’re looking to land a full-time
job in-house somewhere, then there’s a lot of benefit to using your own
name and not coming up with a company name. If you’re setting up your
portfolio to help bring in freelance clients, then there’s an equal
benefit to using your real name or creating a company name and building
a brand from that. With that said, if you’re looking to grow an agency
or start a collective of people that work together, creating a company
name will be a much better fit.</p>
<p>Creating your plan from the start will help you make the proper
decisions here. Let’s go over a few benefits and downsides to each.</p>
<p><strong>Using your personal name</strong></p>
<p>Using your personal name as your domain name for your portfolio, has
its fair share of benefits and downsides. Choosing the right path can
only be determined by your plan. For example, here are some benefits to
using your personal name:</p>
<ul>
<li><blockquote>
<p>You personally will be associated as an individual looking for work,
and not a large team.</p>
</blockquote></li>
<li><blockquote>
<p>You personally will always be associated with the work that you’re
sharing. When people see a certain project, they’ll always think of your
name, not a company’s name.</p>
</blockquote></li>
<li><blockquote>
<p>If you’re looking to be hired full-time, there will be less confusion
for the companies looking to hire you.</p>
</blockquote></li>
<li><blockquote>
<p>If you’re a freelancer, you’ll feel more obtainable. Notice I didn’t
say cheaper, but more obtainable. Some companies stay away from agencies
or larger teams thinking they can’t afford them as they’re having to pay
for multiple people. Even though they might not be able to afford you,
they’re more likely to reach out in this instance.</p>
</blockquote></li>
</ul>
<p>With the benefits come a few downsides as well:</p>
<ul>
<li><blockquote>
<p>You personally will be associated as an individual looking for work.
If it’s bad work, or unethical work, that’s all on you.</p>
</blockquote></li>
<li><blockquote>
<p>If you’re looking to turn yourself into an agency, you’ll have a
harder time adding teammates with a company that’s your name, and not
best suitable for a team name. If for some reason you decided to grow
and start your own company/agency, in order to find teammates you’ll
likely need to come up with a whole new company/brand now and remarket
everything that you’ve worked so hard on for your personal name. Really,
this isn’t a big deal. You can always redirect everything from your
personal name. Also, you might have built a strong enough reputation
where no matter where you go, seeing your name associated with a company
you’ve created is good enough to not lose any traction. Or, better yet
you’ve partnered with someone just as good, if not better than you are,
gaining even more attention. Regardless, this is something to be aware
of especially if it’s already in your plan to add teammates. This is
another reason having a good, solid plan is so important. It can save
you time in the long run.</p>
</blockquote></li>
<li><blockquote>
<p>If you have a very common personal name, unfortunately, it can be
hard to get the user handles and domains these days. It can also make
your portfolio harder to find in searches as multiple names will appear.
When I got started, I knew a few Daniels that were designers and the
domain danielpetty.com (my real name) was already taken. Some of my
family used to call me Dan, so I considered using that but I didn’t like
how the 3 letters looked (like the true designer I am—sometimes it's a
curse). But, I remembered my Grandfather always called me Dan except
when he wrote it, it always looked like he spelled Dann as he always
wrote in cursive. I didn’t know any Dann’s at all and I still don’t know
any other designers named Dann almost two decades later. From there on
out, I was known as Dann Petty instead of Daniel Petty. This slight
adjustment helped me “brand” myself while not veering off too far from
my real name.</p>
</blockquote></li>
</ul>
<p>There are a few other designers that do this well, but unfortunately,
without revealing their real names, I don’t want to give up their gig in
case it’s not public information. If you have a common name and you
don’t want a company name, consider creating an alternative, “real” name
to go by. Celebrities do it all the time. Do what you can do to help
yourself stand out—especially if you’re a freelancer.</p>
<p><strong>Using a company name</strong></p>
<p>Just like using your personal name has its benefits and downsides, so
will using a company name.</p>
<p>Here are just a few benefits to using a company name:</p>
<ul>
<li><blockquote>
<p>One of the biggest benefits to using a company name is that it’s so
much easier to scale and add teammates, partners, or employees. If this
is in your plan, consider going ahead with this.</p>
</blockquote></li>
<li><blockquote>
<p>It’s potentially easier to get user handles and domain names as
there’s more opportunity to get creative with a company name.</p>
</blockquote></li>
<li><blockquote>
<p>Using a company name is a fantastic way to show that you know
branding as well.</p>
</blockquote></li>
</ul>
<p>Downsides to using a company name:</p>
<ul>
<li><blockquote>
<p>If having your real name makes you feel more obtainable, having a
company name will make you feel less obtainable to some people. It’s all
about perception.</p>
</blockquote></li>
<li><blockquote>
<p>People might think you’re a company, when in reality, maybe you’re
not. You’re just one person. In that case, you may miss that opportunity
to get hired for full-time positions or certain projects.</p>
</blockquote></li>
</ul>
<p>In summary, if you’re one person, own it. If you’re one person trying
to grow into a larger company with partners and employees, own that.
Your goal will be making it less confusing and choosing what properly
fits into your plan.</p>

<h5 id="find-a-good-domain-name-and-email-address" v-view="(e) => viewHandler(e, 'find-a-good-domain-name-and-email-address')" class="section"><strong>Find a good
domain name and email address.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'143c2d27843d4ef08c27efeb7eb676b8'"  :title="`Find a good
domain name and email address`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695130303' || v.videoId === '143c2d27843d4ef08c27efeb7eb676b8')" />
<p>It’s debatable how important a solid domain is these days for
personal use. I could use dannpetty.com, or dannpettydesigns.com or even
dannpetty.design—it wouldn’t matter as I link my portfolio almost
everywhere. No one really types these in anymore. Where finding a good
domain name becomes much more important, is when you’re coming up with a
company name and not using your personal name. You see, if your
portfolio is used as a tool to get more work as a team, an agency or
company of some kind—you want everyone to remember the name. These days,
with so many domain name extensions like <em>.design .live
.whatever</em>, it’s making these names even harder to remember. And
remember: sometimes <em>not</em> having a .com or .co can come across
cheap or rushed. Let’s face it: most unique extensions are available and
only $12 in most cases. A good domain these days is incredibly hard to
find. Getting one, even if you still paid $12, has a perception of
seriousness, something more thought out, something you put a little more
effort into. Sometimes even a victorious vibe (seriously, scoring a good
domain is like winning some big championship game these days).</p>
<p>Do I think that perception is a good thing? No, but let’s be real
with ourselves, it is. Not for everyone, but for a lot of people it is.
If someone is telling you that it doesn’t matter if you use an .io
extension or .awesome extension, take a look at what extensions they are
using. If they’re in fact using a unique extension, ask how it's
affected them? Ask why they chose to use that extension. In most cases,
they’ll just say because the .com wasn’t available. If you think about
it, that means the .com was their first choice. Likely the .co was their
second choice. Ultimately, they went with their third or fourth choice.
That’s not always the case, however, which is why it’s important to
ask.</p>
<p>My advice: if you want a solid domain name for your company do not
use anything other than a .com or a .co. Instead of using a name like
funsize.agency, since funsize.com was a taken domain name, Funsize, the
design agency in Austin, TX, opted for funsize.co. They’re very
successful in doing so. Now, does that mean they can’t use .agency or
something similar? Absolutely not—use whatever they need or better yet,
whatever they want. Same for you. Look at Clay. They use clay.global. It
works for them. It may take me a few times to get used to it, but
really, I just need to type it in once unless I clear my cache often. I
think where it gets trickier, is when you need to tell someone out loud
your domain name and expect them to remember the unique, unfamiliar
extension.</p>
<p>Again, it’s debatable these days how important your extension is. All
I’m saying? Put in the time and research and consider the downsides of
using them. Think of it this way: since you’re more used to using .coms
on almost every website, creating a company like basicagency.com instead
of basic.agency, makes it much more familiar, which in return, makes it
more memorable. Again, if your domain is just for personal use and
you’re looking to get hired as an individual for a team—the importance
of this drops drastically.</p>
<p>Here are some examples of great domain names and extensions:</p>
<p>Examples of personal .coms using real names:</p>
<ul>
<li><blockquote>
<p><strong>CéliaValette.com</strong></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://dannpetty.com" target="_blank">dannpetty.com</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://anna-hurley.com" target="_blank">anna-hurley.com</a></p>
</blockquote></li>
</ul>
<p>Examples of personal .coms using more branded names:</p>
<ul>
<li><blockquote>
<p><a class="underline" href="https://lynnandtonic.com" target="_blank">lynnandtonic.com</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://simplebits.com" target="_blank">simplebits.com</a></p>
</blockquote></li>
</ul>
<p>Examples of company .coms:</p>
<ul>
<li><blockquote>
<p><a class="underline" href="https://basicagency.com" target="_blank">basicagency.com</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://pentagram.ca" target="_blank">pentagram.ca</a></p>
</blockquote></li>
</ul>
<p>Examples of .cos:</p>
<ul>
<li><blockquote>
<p><a class="underline" href="https://studiofrank.co" target="_blank">studiofrank.co</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://kerem.co" target="_blank">kerem.co</a></p>
</blockquote></li>
</ul>
<p>Examples of unique extensions:</p>
<ul>
<li><blockquote>
<p><a class="underline" href="https://clay.global" target="_blank">clay.global</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://butt.guru" target="_blank">butt.guru</a>, haha</p>
</blockquote></li>
</ul>
<p>If you’re looking for a domain name right now, you have several
options of where to snag one at. Here’s my method for finding a domain
name: I usually start out by searching domai.nr as it also gives
suggestions of alternative spellings and you can clearly see which
extensions are taken or available. Sometimes I check out sites like
Se.do as well, as it has many good ones listed for sale — though quite
expensive when you find a good one. I’ve noticed that recently on
domai.nr (which used to be my go-to), it’ll now show a domain name that
seems available, but they can’t provide the price. This is usually
because it’s for sale on sites like GoDaddy at a much higher cost.
Because of that, since most of my domains are in fact with GoDaddy
already (all 50 of them). I just now go straight to GoDaddy to search
for domains as I’m strictly looking for .coms or .cos only. If they’re
available, I want to see the costs before I get my hopes up. If you find
a domain you really like and it appears to be taken on the service where
you’re searching for the domain, be sure to take the time to also type
it into the browser. Often, you’ll see that the domain you’re interested
in that seems to be taken, turns out, it’s actually just parked or
listed for sale. In that case, you can find a broker to help you snag
it.</p>
<p>Keep in mind that when you’re searching your domain name, whether
it’s your real name or a business name, be sure you look and see what
handles are available on sites like Twitter or Instagram. If it’s in
your plan to be part of those networks, it’s valuable to keep your
domain name and handles as consistent as possible. I’m @dannpetty on
pretty much every service. I love how easy it is to say when people ask,
“Hey, what’s your Twitter and Instagram?” I just say @dannpetty, Dann
with two <em>N</em>s everywhere. If my domain name was something like
dann.design, my Twitter handle was @designerdann, and my Instagram was
@dpetty, and my Dribbble was Daniel Petty? Not only would I get confused
and not be able to remember it all, but <em>so would everyone else</em>.
Stay as consistent as possible. It simplifies everything.</p>
<p>Is it bad if you can’t keep things consistent? No, not at all —
especially if you’re not a company or building a brand. There’s nothing
wrong with it. But, the more consistent you can be, the better your
chances of being remembered.</p>
<p>My recommendation is to start by making a list of your top 5-10
names. Go through and see which .coms and .cos are available. If one’s
available, place a checkmark beside it. Then go see if the Twitter
handle is available, as I’d argue that’s the next important spot to be.
If it’s available, place another checkmark beside it. Then do it for
Instagram and of course, do the same for the services in your industry.
For example, Dribbble, Behance, GitHub, and etc. See which has the most
checkmarks — consider using that name. There are also a few services
that will do this for you, but again, there’s nothing better than
checking for yourself. Most handles are simply not in use and there are
potentially ways to get them.</p>
<p>Where domain names really get tricky is in the email address.</p>
<p>I’ll ask again: what’s your plan? Do you want to be a freelancer? Are
you just an individual looking for a new full-time job? This is
important to know to decide how important your email address is. If
you’re just looking for a full-time role, it’s okay to use domains like
@gmail.com. However, if you’re a freelancer trying to come across as
professional, it’s likely a better idea to use an email address based on
your domain name. As a society, we’re so used to using .coms, especially
for email. It gets incredibly awkward remembering emails coming from
things like design.agency or design.internet. In my opinion, this is
where the domain name you choose becomes incredibly important.</p>
<p>Sure, if you’ve already emailed someone, it’ll just appear as you
start typing in the <strong>To:</strong> field depending on the email
client you’re using. However, I’d argue that your email address is most
likely your number-one point of contact for new clients and
opportunities. Especially if you’re using your email for freelancing or
your own company. Don’t make it feel unfamiliar and difficult to
remember. There are still plenty of .com and .co domains available. All
you need to do is put in the effort to find out there are no better
options for you to use.</p>
<p>For example:</p>
<p>My email address is <a
href="mailto:hey@dannpetty.com"><u>hey@dannpetty.com</u></a>. If someone
is going to hire me for freelance, that’s the first place they are going
to try to contact me. Knowing that, I want to take advantage of every
little thing I can do in order to sound as professional as possible. For
the longest time, I was using <u><a
href="mailto:yell@dannpetty.com">yell@dannpetty.com</a>.</u> But as I
got older and times changed, having the cute “yell@” didn’t seem to fit
my personality anymore, despite working for years. Imagine my email
being <a
href="mailto:dannpetty@gmail.com"><u>dannpetty@gmail.com</u></a>. Not
bad, but it just feels a little unprofessional (if I intend to be a
business or a freelancer). It comes across more as a personal email
address. It’s not a bad thing and you can totally rock this. But
consider your plan. If you’re trying to become a freelancer, keep your
URL and email the same. If your domain is like mine, dannpetty.com, then
have an email associated with that domain. It’s also good marketing.
Whenever someone comes across your email that might not know you, your
domain/portfolio is right there in their face. Something to think
about.</p>
<p>For added clarity, if you’re just looking to land a full-time job for
someone else, it’s not as important to sound as polished with your email
address. Whatever you choose, the most important thing is that you have
a way for people to email you. It sounds so obvious, but you’d be
surprised how often this is missed. So many portfolios have contact forms only without even providing an email address anywhere what’s so ever. The connection part of your portfolio (how someone contacts you) is so incredibly important, I even have a whole chapter dedicated to it in <a class="underline" href="https://ThatPortfolioBook.com" target="_blank">ThatPortfolioBook.com.</a></p>

<h5 id="have-clear-messaging-everywhere" v-view="(e) => viewHandler(e, 'have-clear-messaging-everywhere')" class="section"><strong>Have clear messaging
everywhere.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'7ea9e3511e72433c9a1697599c47ba31'"  :title="`Have clear messaging
everywhere`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695129852' || v.videoId === '7ea9e3511e72433c9a1697599c47ba31')" />
<p>A famous general once said that orders shouldn’t be easy to
understand. They should be impossible to misunderstand. I want you to
have the same attitude when it comes to your messaging. Get crystal
clear.</p>
<p>For starters, edit your branding materials and website for low
reading comprehension. I know. That sounds like a bad thing. But here’s
the thing: simplicity works. If you’re simple to read, you’re easy to
understand. If your messaging is simple, it creates clarity for the
greatest number of people. What you don’t want is potential clients
calling you and asking you about your confusing website, because they probably won’t call you anyway.</p>
<p>Instead, run all of your branding materials through Hemingway, a free
app you can use to check the reading level of your content. Aim for the
lowest grade possible. If it’s not second grade or lower, you can
probably simplify your messaging.</p>
<p>It sounds like a lot of work, but remember that you’re just creating
this content one time. You’ll use it again and again. Make it simple,
because a lot of people who aren’t necessarily proficient at what you do
are going to read it—and they’re going to use it to make a hiring
decision.</p>
<p>Next, get consistent. If your website says “UX designer” in your bio,
but your main page says “developer,” what is someone supposed to think?
Don’t make them do your work for you. Part of clarity is offering a
consistent message. Do the thinking for them. Consistent messaging will
help.</p>
<p>You should also avoid industry lingo and jargon. People are hiring
you because they don’t have your specialized knowledge. They don’t want
to learn this lingo and jargon. Showing it off doesn’t make you sound
like an expert—it makes you sound out of touch. Remember that you’re
writing your brand messaging for people who might hire you. And they
shouldn’t need an industry dictionary to do it.</p>
<p>Finally, focus on your unique selling proposition. What’s unique
about your story? What’s unique about the value you bring to the table?
A key point here: avoid the word “I.” They didn’t come to your portfolio
or website because they want to read David Copperfield. They want to
know what you’ve done for other companies, and what you can do for them.
Talk about the benefits of hiring you for their business and their
project.</p>

<h5 id="getting-started-myths" v-view="(e) => viewHandler(e, 'getting-started-myths')" class="section"><strong>“Getting started”
myths.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'381ed889c6204eaca835e574a1863bdd'"  :title="`Getting started”
myths`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695130003' || v.videoId === '381ed889c6204eaca835e574a1863bdd')" />
<p><strong>You have to have 3-6 months of savings before you
start.</strong> I mean, sure that would be awesome if we could all do
that and it’s great advice, but let's be realistic here, that’s just as
hard to do when you have a full-time job for someone else.</p>
<p>Definitely have some money saved up if you’re coming off a full-time
job to start freelancing. But if you can, have paying projects to work
on before you quit your job and start freelancing. Three to six months
of savings can give you a nice cushion so you don’t feel desperate to
take every low-quality gig that comes your way.</p>
<p><strong>Freelancers are cheap.</strong> Also not true. I myself
charge close to the cost, if not more, as a lot of agencies and at times
brought my pricing down when some agencies did the same. Sure, there are
some “cheap” freelancers out there, but the same goes for any kind of
service, there’s always a “cheaper” version of it.</p>
<p>Free in freelance doesn’t mean free or cheap. The word free means
free to work with anyone. That’s why you hear so many freelancers tell
you what it means to be a freelancer is “being free” or “freedom”
because even though you have clients and work for them, ultimately,
you’re on your own.</p>
<p><strong>Freelancers are just people that can’t get a job.</strong>
That’s not true at all. Sure, occasionally some people fall into
freelance out of necessity but it’s a legit full-time business, which
you should be proud of. Just because the word has “free” in it doesn’t
mean a thing. Don’t let anyone keep you from following your dream of
working for yourself. You even often see people try out different words
like contractor or something, which is fine, but own the word freelance.
Use it proudly.</p>
<p>There are actually several reasons why people freelance. Maybe you
freelance to earn extra income outside of your full-time job? Maybe
someone is freelancing for more flexible hours or a work/life
relationship that fits their lifestyle. Or it’s even possible someone is
freelancing to just get their career started in their particular field.
Though freelancing because one can’t get a job (yet) is a valid reason,
it’s not a bad thing.</p>
<p><strong>Freelancers have to be incredibly organized.</strong> Yes and
no. Think about what that word really means. Instead of “organized,” I
like to call it being efficient. Freelancers need to be efficient. You
need to be capable of delivering on timelines, communicating, and
everything else and that’s very possible to do without being incredibly
organized. You should see my desk (it’s an absolute disaster and always
is) and my calendar (it’s always empty).</p>
<p>People think organized means a perfect schedule, calendar, desk,
to-do list, and everything. That’s not the case at all. I’m living
proof-of-concept for being incredibly unorganized yet super-efficient.
Just do what works for you. Sometimes my to-do list or meeting reminder
is written on my hand or a note on my desk (somewhere). “It’s important” If it’s
important, and going to make me successful, I will remember it. But
that’s just how I operate. You operate however is best for you just
remember, every one of us operates differently and that’s an amazing
thing —being ourselves.</p>
<p><strong>Freelancing isn’t for me because I love people and being part
of teams.</strong></p>
<p>Let me tell you something, not only do you get to work with
<strong>a</strong> team, but you get to work with <strong>many</strong>
teams. You may work by yourself at home or remote office somewhere
(please don’t be that person that hoards a coffee shop all day, every
day and never spends more than $5 - it’s not your office) but you still
are very much part of a team.</p>

<h5 id="how-i-got-started" v-view="(e) => viewHandler(e, 'how-i-got-started')" class="section"><strong>How I got started.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'b6da8b6710464eab9290ea2bd858c7e4'"  :title="`How I got started`" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695130756' || v.videoId === 'b6da8b6710464eab9290ea2bd858c7e4')" />
<p>In 2004, I graduated from O’More College of Design and went to work
full-time at Fluid in San Francisco, CA making $68,000. My goal was to
become a Creative Director at a famous agency. That’s all I wanted. I
stayed at Fluid for 8 months, then got a job offer making $78,000 at a
company called BSSP across the Golden Gate Bridge in Sausalito, CA. I
stayed there another 8 months. In these 16 months, I had collected work
I created on my own and part of a team with clients like The North Face,
Mini Cooper, EA Sports, Priceline, and others which I started to share
on my portfolio. During this time, I was in fact freelancing on the site
but still for smaller mom &amp; pop local things or for friends in the
area. Nothing over $1000 really.</p>
<p>After 8 months of being an Interactive Designer at BSSP, I got
offered my dream job just 16 months out of school -- I was Creative
Director at Digitaria at the age of 26 in San Diego, CA working with
brands like NFL, ESPN, and more. Now my portfolio really started to pick
up and before I knew it, companies like Google, Virgin America, and
National Geographic were asking me to do projects for them as a
freelancer and we were no longer talking about just $1000 projects.
Within 2 months of freelancing for those companies on the side, I could
clearly see I was going to be able to make more money on my own. So I
left and went full-time freelance after just two years of employment, of
learning everything I can, and building a portfolio of work. After
realizing I had more control of my time as well? I never looked back.
Minus a couple full-time investment opportunities, I’ve been full-time
freelance ever since, working for companies like Nixon, Patagonia, Red
Bull, Billabong, Medium, Obvious Corp, Uber, Airbnb, and many others
along the way.</p>
<p>Now, I tell that story for two reasons. One, it’s ok if your “jump”
into freelance takes time and you’re doing this part-time for a while. I
was part-time for roughly two years. And secondly, I want you to see
opportunity when it comes knocking. I knew exactly what I wanted in my
career to build a dream portfolio and to work for myself so when those
opportunities came about, without question I grabbed them before they
were gone.</p>

<h5 id="reputation-is-everything" v-view="(e) => viewHandler(e, 'reputation-is-everything')" class="section"><strong>Reputation is
everything.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'20a6f2c4b297434199be54bf04759750'"  :title="`Reputation is
everything`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695130931' || v.videoId === '20a6f2c4b297434199be54bf04759750')"/>
<p>Having a good reputation goes a long way for a creative professional.
I’d even argue that your reputation is more important than your
portfolio itself since, as we’ve been discussing, people are hiring
<em>you</em>—the overall person, not just your work. I’ve been known to
hire people for my team or even just to freelance with me based solely
on the fact that I liked them as a person. Did they have the skills I
was looking for? Of course. But they weren’t necessarily the best
available. I just felt that working with them would be a more joyful
experience.</p>
<p>At least 60% of my work comes from referrals. But referrals are
earned over time. That starts with having a good reputation. This can be
as simple as displaying in your portfolio the clients that you’ve worked
for, especially if they’re well-known. But not all of us have big-name
companies in our portfolio. And that’s ok. You can base a good
reputation on simple things like showing up on time for meetings,
responding quickly to emails, and demonstrating your dedication to the
tasks at hand. Even if you don’t have enough work to show these things
(don’t worry, it’ll come), your reputation can be assessed through your
taste in design, photography, writing, leadership skills, how well you
work with others, how hard you push yourself, your passion, and, most
important, your attitude and work ethic. If you’re just starting out,
decide what you want your reputation to be. Do you want to be known as
someone who is passionate about your craft? Then do personal projects
and side projects. Maybe you’re a photographer who’s not that proficient
at editing but whose composition is like no other. That’s something to
be known for. Own it. Figure out what you want to be known for and add
it to your plan.</p>
<p>I want to be known as a positive leader in web design. Someone who’s
dedicated and passionate about his industry. That’s why I often provide
feedback and advice to other designers, and why I started an event for
creative professionals called Epicurrence. I also do many side projects,
like the @DannPetty show, Freelance.tv, among others. I also want to be
known as a designer who’s highly creative, works fast, and always
delivers the highest quality work on time. So, when I quote projects, I
always offer clients a quicker turn-around option. I began my career in
the advertising business, where I had to bust out countless incredibly
unique banners every single day for companies like Mini Cooper,
Priceline, and Columbia. I’d even have to deliver full website concepts
within a day or two, or even within hours, simply to prove my ideas.
Coming up with creative ideas and designing fast were skills that I had
actively developed and wanted to be known for.</p>
<p>I also want to be known as someone who’s friendly and easy to work
with. I prefer to cultivate a close connection with my clients (who
almost always end up as friends). Instead of emailing, we’re more likely
to text one another. I’ll text screenshots of my work and ask questions
throughout the process, sometimes daily. This shows that I’m easy to
work with and that I don’t need to exchange a bunch of emails or have
formal phone calls. I try not to demand time from my clients but make
sure they know that I’m always available. Since people tend to view
texting as something for friends and family rather than for business
associates, my efforts to move into that territory show them that I
genuinely want to be friends. I feel like these are some of the reasons
that I am referred so much. Don’t get me wrong. My designs are good. But
having a reputation as a friendly, passionate, fast, creative, and
dedicated person who’s easy to work with does wonders for my career.
It’s not always about the specific companies/clients you’ve worked for;
often it’s more about the work you do for these companies/clients. In
other words, it’s about what you do with the work you’ve got, no matter
how small the project, and how you present yourself throughout the
process.</p>
<p>Don’t forget: reputation can also be built through your social media
channels. If you’re sharing thoughtful ideas on Twitter and
participating positively in the community, that speaks volumes about
your reputation. Alternatively, if you’re on Twitter bashing and
bad-mouthing others, that does nothing to aid your reputation and
instead shows you to be someone you probably wouldn’t want on your team.
Now and then I slip up and tweet things I shouldn’t, but thankfully,
those who know me as someone who is positive and community-focused don’t
hesitate to call me out. When that happens, I go back to my plan — and
also do some honest self-reflection.</p>
<p>You might think this isn’t a big deal, but it is. You can learn a lot
about a person by how they treat others online and what they talk about.
Companies/clients hiring you WILL check out your social media. They
will. It’s 2020. There’s no longer any doubt. Your link is probably even
on your portfolio somewhere. Why wouldn’t they check it out? Adding to
your bio “Tweets are not representative of my company or my work” will
not help you at all. Be yourself but keep in mind that your social media
reputation is just as important as the reputation clients see in your
portfolio. A portfolio isn’t just a showcase of your work, it’s a story
of you.</p>
<p>Also, as we’ll get into later on, having a good reputation also means
properly crediting your team and their involvement in the work you’re
showcasing. If you were one of four designers on a team that created a
website, be sure to credit the other three. List what you did and what
the others did. You might worry that this could have the opposite effect
of the one you want to create, but in reality it speaks volumes about
your ability to work well with others. That’s one of the best
reputations you can have.</p>

<h5 id="build-trust" v-view="(e) => viewHandler(e, 'build-trust')" class="section"><strong>Build trust.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'88777b552dd64252b6306b38f9a5d99b'"  :title="`Build trust`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695129686' || v.videoId === '88777b552dd64252b6306b38f9a5d99b')"/>
<p>Be honest and upfront about your work. Don’t pretend you were a sole
creator on a project if you weren’t. People see right through this and
will move on to another candidate, especially when they see projects
that were clearly created by a larger team. Describe your true role in a
project and credit others. Trust me, it will only make others think more
highly of you.</p>
<p>In addition to creating a good reputation, a strong portfolio builds
trust. You can do this with your reputation, of course, but you can also
do it through the way you write on your portfolio, the way you present
yourself, or directly through your work. Imagine coming upon a portfolio
with an unoriginal headline, and the copy underneath it isn’t written in
complete sentences. Or maybe the work is messy and scattered, so the
overall layout is weak. Or worse, the actual work you’re showing is
itself messy and incomplete. None of these things lead to a client
trusting that you are the right person for their project. When you work
on your portfolio, the tool that you’re using to tell your story and
show your plan for your future is the only thing people can see and
judge you on. Put some real effort into creating it. In return, you’ll
be trusted to do the job. But let’s face it: if you can’t create a
clear, well-written, and organized portfolio, why should anyone trust
you with their project? We’ll talk more about portfolios later.</p>
<p>One final note about trust and reputation: don’t pretend to be a more
senior person if you’re just starting out or don’t have the skills to
back it up. These days, we can give ourselves just about any title we
want without repercussions, but that’s not something you should take
advantage of. If you’re an early product designer, own that. If you’re a
lead project designer, own that. If you’re a senior product designer,
own that. You’re not fooling anyone. It’s only going to hurt your
reputation when they figure out that you’re less experienced than you
led them to believe. Be honest. People will think more highly of you and
it will work out better in the long run.</p>

<h2 ref="chapter3" id="chapter-three-how-to-get-work-and-stand-out" v-view="(e) => viewHandler(e, 'chapter-three-how-to-get-work-and-stand-out')" class="chapter text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter
Three: How to Get Work and Stand Out</strong></h2>
<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'c5c38ae6169a4f54850940697c53b431'"  :title="'Chapter 3'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695211736' || v.videoId === 'c5c38ae6169a4f54850940697c53b431')"/>
<p class="text-black-caption font-atlas-medium text-base font-atlas">Complete Chapter Video - 37:16</p>
</div>
<p><em>Nothing happens until something moves.</em></p>
<p><strong>-Albert Einstein</strong></p>
<p>Why start this chapter out with a quote from a physicist? Maybe he
wasn’t talking about freelancing, but Einstein was one of the smartest
people who ever lived. And he knew that a fundamental truth about the
universe: nothing happens until something, or someone, starts it.</p>
<p>But it’s not just a rule in physics. It’s a rule in freelancing. If
you don’t take the steps outlined in this chapter, you may end up
branching out into freelancing and wondering, Where are all the great gigs? You have talent, you figure — so why aren’t you landing those sweet
gigs while people you know <em>are</em> landing them?</p>
<p>What’s so bad about you, and so good about them?</p>
<p>Before you make these value judgments or start doubting yourself, you
need to nip those thoughts in the bud. That comes back to the
foundational question of freelancing: <strong>nothing happens until something
moves. Until <em>you</em> get moving.</strong></p>
<p>If you work a nine-to-five, you have a desk job because someone else
had the impetus to create a company at some point. It’s easy to forget
that. We’re often so far from the original impetus of any business that
we forget the struggle of overcoming early resistance. But guess what?
I’ll bet that if you trace most businesses to the beginning, there was
someone who was a little bit afraid to put themselves out there, take
risks, and separate themselves from the herd.</p>
<p>I don’t want you to be intimidated by all this. You don’t have to
turn into a ruthless Rockefeller to make yourself succeed as a
freelancer. But you do need a jump-start — something to get you going. In
this chapter, we’ll tackle what that means.</p>

<h5 id="let-them-know-you-exist" v-view="(e) => viewHandler(e, 'let-them-know-you-exist')" class="section"><strong>Let them know you
exist.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'510bbb3ac92b4d43bd3775994a0ed48b'"  :title="'Let them know you exist'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695133743' || v.videoId === '510bbb3ac92b4d43bd3775994a0ed48b')"/>
<p>If you get nothing else from this book, remember this one lesson:
<strong><em>you need to let people know you exist</em>.</strong></p>
<p>It will feel messy sometimes, especially if you’re not used to it. It
will feel awkward. It won’t feel “natural.” But if you’re going to
succeed as a freelancer and feel resistance to this lesson, it’s
something you’ll need to work through. Overcome the instinct to hide in
the corner and “raise your hand” once someone calls on you.</p>
<p>That may be how school works. But school’s out. It’s time for you to
take the reins.</p>
<p>If there was ever a secret to becoming a successful freelancer it
would be this, and I’ll keep repeating it: <em><strong>let people know
that you exist.</strong></em> That’s the #1 thing you need to do because
if you don’t, then guess what? No one will ever know. When you stop
letting people know you exist, they’ll start to forget about you and
someone else will get noticed because they’re the ones taking the steps
you didn’t take.</p>
<p>Sure, this becomes a little less important once you are established
and have a huge network of clients you’ve worked with in the past that
can do all the promotion for you.</p>
<p>I’ll repeat it a third time. If you take nothing else from this book,
it should be this lesson: you are going to have to become comfortable
letting people know you exist. It’s the number one thing you need to do.
You might be the most talented freelancer in the world. But if your work
doesn’t come across someone else’s computer — if you fail to let other
people know you exist — it won’t matter. Don’t be the tree that fell in
the forest without making a sound. Let other people know you’re
there.</p>
<p>There’s an old quote that 90% of success is showing up. And that’s
true.</p>
<p>But you also have to know where to show up.</p>
<p>Learn to literally put yourself out there. Remember: if you don’t ask
something, it’s always a no. There’s a reason some of the top performers
in any field will harp on this subject. It works. Wayne Gretzky said you
miss 100% of the shots you don’t take. And so will you. Tweet your work.
Email someone you don’t know. Say, “hey, check this out.” Let people
know you’re a human being doing things. You exist. Your work exists.</p>
<p>If you aren’t getting any traction as a freelancer, you might assume
that no one thinks you’re any good and you’re a failure. But the real
problem is people simply haven’t heard of you.</p>
<p>Let’s take a story from a pop star’s life, Ed Sheeran.</p>
<p>Ed Sheeran wasn’t supposed
to be a pop star. He was an odd kid; he didn’t have the face of a pop
star. Looking back, telling one of the biggest pop stars on earth that
he doesn’t have the right “face” for it seems ridiculous. But you easily
could go back in time and predict Ed Sheeran never would have made
it.</p>
<p>Sheeran’s problem was a familiar one. He was just another nameless
face in a crowd of talent. Record labels turned him down. He could have
said, “Welp, that’s it—guess I won’t be a pop star now.” But he was
determined to showcase his talent.</p>
<p>He moved down to London, where the action was, and emailed every
single music promoter he could find on Musicborn.com. What did he do? He
asked, plainly and simply, if he could have a gig. According to Sheeran,
he emailed 300 people. 50 of them got replies. He booked two or three
gigs a night for himself just because he knew he wanted to get in front
of people.</p>
<p>He self-promoted. He did multiple gigs every night, often at
different venues. He knew he had the talent; he just wanted someone,
anyone, to see him.</p>
<p>Finally, he got his big break. He went on SBTV, a UK-based hip-hop
channel, and something interesting happened. His slightly goofy face,
decidedly un-hip-hop, made him stand out from the context, especially
given how well-honed his talent was by then. The video of his
performance went viral — everything changed.</p>
<p>Keep in mind that we’re talking about a driven, talented musician. Ed
Sheeran is someone we can all look at and say, “Well, of course he was
going to make it; he was always like that — a natural talent.”</p>
<p>But it was only because he put himself out there, time and again,
that people started to take notice. If he hadn’t self-promoted, no one
would have ever heard of Ed Sheeran.</p>
<p>Don’t wait for discovery to happen to you. Be the discovery.</p>

<h5 id="finding-your-first-client" v-view="(e) => viewHandler(e, 'finding-your-first-client')" class="section"><strong>Finding your first
client.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'4a0230457e5244819941a48e098bb268'"  :title="`Finding your first
client`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695133131' || v.videoId === '4a0230457e5244819941a48e098bb268')"/>
<p>If you’re going to put yourself out there, you’re going to need
somewhere to start. That means finding your first client. This can be
one of the most confusing times as a freelancer because you have no idea
where to find them—or which medium is going to work out best for you. So
take the Ed Sheeran approach. Try a little of everything. Here are a few
ideas to get you started.</p>
<ul>
<li><blockquote>
<p>Post to a platform like Upwork. On Upwork, you may have to compete
with some low-priced alternatives. And you won’t always find the
highest-quality jobs. But you will find opportunities to build your
portfolio and network.</p>
</blockquote></li>
<li><blockquote>
<p>Post to Dribbble. As a self-promotion platform for artists and
creatives, you can’t find a better place to start your newfound habit of
promoting your talents.</p>
</blockquote></li>
<li><blockquote>
<p>Post on social media that you’re ready for work. Ask your immediate
network if they know of any openings, or anyone looking for some
additional freelance help.</p>
</blockquote></li>
<li><blockquote>
<p>Reach out to every business contact you’ve made thus far. Will it
feel awkward at times? Clumsy? Yes and yes. But all it takes is one
“yes.”</p>
</blockquote></li>
<li><blockquote>
<p>Join Facebook and LinkedIn Groups. For example, a Facebook group like
Cult of Copy is great for freelance writers looking to get into sales
writing. A nice rule of thumb: if you can think of an industry, there’s
already a Facebook group for it.</p>
</blockquote></li>
</ul>
<h5 id="when-to-work-for-free" v-view="(e) => viewHandler(e, 'when-to-work-for-free')" class="section"><strong>When to work for
“free.”</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'7bbe351756224cb7b4128d7c3bf7c8e2'"  :title="`When to work for
“free.”`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695136368' || v.videoId === '7bbe351756224cb7b4128d7c3bf7c8e2')"/>
<p>This section might strike a few nerves. That’s ok. It should. I can
guarantee you that almost every creative professional has done some type
of “free” work. Whether for themselves, for a friend, family member, or
just for a company they were passionate about; they’ve done some form of
“free” work. Heck, some of us even paid thousands and thousands of
dollars to go school where we created a ton of work for our teachers or
ourselves or fake companies. We <em>paid</em> to do that. When you hear
“never work for free,” there is important context that is missing from
that advice.</p>
<p>Before we get into the good (and I say that loosely) and bad forms of
“free” work, I want to remind you that you are valuable. No matter your
skill set, you are incredibly valuable because you have a service to
offer. And you should be compensated for that service. Nothing is for
“free,” and that’s why it’s always in quotes. But you need to be smart
about when to do this and you need to understand when and if it fits
into your overall plan. Most importantly, you need to be able to see
through those who will try to take advantage of you. Take advantage of
each other — it cannot be one-sided. After all, we’re in the service
business and we have a trade. There’s nothing wrong with trading
services like the good old days.</p>
<p>Here’s my advice on when it’s good and bad to work for “free”:</p>
<p><strong>Bad “Free”:</strong></p>
<ul>
<li><blockquote>
<p>Never work for free if a company asks you to. That’s just wrong.</p>
</blockquote></li>
<li><blockquote>
<p>Never work for free if you don’t want to.</p>
</blockquote></li>
<li><blockquote>
<p>Never work for free simply for “exposure.” If you’re promised this,
it’s a true sign that you’re being taken advantage of.</p>
</blockquote></li>
<li><blockquote>
<p>Never work for free if a company has a way to compensate you.</p>
</blockquote></li>
</ul>
<p><strong>Good “Free”:</strong></p>
<ul>
<li><blockquote>
<p>Work for “free” if there is some kind of service or trade involved
that you’d enjoy.</p>
</blockquote></li>
<li><blockquote>
<p>Work for “free” if it fits into your overall plan and you know you’ll
gain some value.</p>
</blockquote></li>
<li><blockquote>
<p>Work for “free” if you want to.</p>
</blockquote></li>
<li><blockquote>
<p>Work for “free” as long as there is some clear benefit to you as
well.</p>
</blockquote></li>
</ul>
<p>Remember, nothing is truly “free.” Even when you’re not paid in
actual money for your work, you can trade favors and services, and gain
relationships. One bonus? You will also get exposure for your work. But
think of this as a bonus. It can never be your sole form of “payment.”
If it fits into your plan, take advantage of the opportunity.</p>
<h5 id="example-i-created-nixon.com-for-free" v-view="(e) => viewHandler(e, 'example-i-created-nixon.com-for-free')" class="section"><strong>Example: I
created Nixon.com for “free.”</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'b0bc1ed1034848bebbd4f69bf7f73b44'"  :title="`I
created Nixon.com for “free.”`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '701353452' || v.videoId === 'b0bc1ed1034848bebbd4f69bf7f73b44')" />
<p>Here’s one of my favorite examples to share. I created Nixon.com for “free”.
</p>
<p>That’s right. Nixon.com, owned by Billabong. They’ve got money, so
why did I work for “free”? Well, I did and I didn’t. At the time, my top
three favorite companies of all-time that I really wanted to work for
were Burton, Red Bull, and Nixon. I got word through a friend who lived
in Encinitas, CA, where Nixon is headquartered, that they were about to
launch a new website for the first time in years. I also found out that
they weren’t 100% happy with the design, even though it was about to
launch. Knowing this, I asked my friend if he’d connect me with someone
there, and he did — the main project manager. Stoked on the
possibilities, I emailed him, explaining that I was a freelance web
designer, that Nixon was my all-time favorite brand, and saying that, I
was hoping there was something I could do to help. He thanked me but
said it was too late because the site that they’d spent 6 months working
on would be launching the following week. I responded with “Give me the
weekend. I’ll show you something on Sunday.”</p>
<p>That was a Thursday. I didn’t ask for anything and I didn’t care what
his response was. I already knew that there was a 0% chance that I would
convince them of anything. They didn’t know me, and I didn’t fully know
their circumstance, other than that they were unhappy with the site they
were about to launch. So I had zero expectations other than to challenge
myself to save my favorite brand. This was an excuse to push myself
harder than I’ve ever had to, to solve a problem <em>over a weekend</em>
for my favorite brand.</p>
<p>What was the worst that could happen? I’d spend the weekend designing
some sweet comps that I could show off in my portfolio, as long as I
make it clear that it was unsolicited, conceptual work. That was the
<em>worst-case</em> scenario. Not that scary.</p>
<p>The competitor in me saw this as a challenge. At the time, I loved
design and surfing and was eagerly trying to be the best at both. If
anyone could redesign Nixon.com, it was me. In my mind, I was their
biggest fan. So, over the weekend, I did some comps and emailed them on
Sunday night, hoping it would be the first thing they saw when they got
to work on Monday morning.</p>
<p>Monday morning I received an ecstatic reply, saying that they were
showing my designs to the CEO, exclaiming that they loved my work and
could see my passion in it. They thought so highly of it that they felt
it was worth sharing with the CEO! Soon they informed me they had
stopped everything and wanted me to finish the design. They were going
to build mine! They were scratching everything that they’d created over
the previous 6 months. The design that took me a weekend (and nearly a
decade of experience) to make is the one that Nixon.com would use. Since
they were already past their deadline for the original design’s launch,
I had a 2-3 week deadline to finish the other pages.</p>
<p>So far, so good, right? But here’s the thing, since they’d already
spent their entire budget on the other website, they said they couldn’t
pay me my full rate, or even close to it. But I said I didn’t care. I
told them, “Pay me what you can,” and got to work flushing out the
design.</p>
<p>Ultimately, I ended up getting more than I had ever dreamed of. Not
only did I still make 5 figures for about 3-4 weeks of work
collectively, but they also gave me 5 figures’ worth of watches,
clothes, bags, and even new gear almost every time they launched a new
product. Since I was their biggest fan, that was awesome for me. I even
received birthday and Christmas gifts from them for nearly 2 years! It
was a dream. To top it off, the greatest gift I’ve ever received was
from Nixon and my favorite surfer of all time, Rob Machado, who’s
sponsored by Nixon. He gave me one of his very own used boards, fresh
out of the water from a day at Cardiff Reef in Cardiff-by-the-Sea, and
he had even signed it “To Dann: Nixon’s White Knight. Aloha, Rob
Machado.”</p>
<p>This was one of the greatest moments of my career…heck, my life! I
never would have even dreamed of this. To top it all off, this single
job led to so much big work that I can confidently say that it was the
moment my career took off.</p>
<p>All because I had worked for “free.”</p>
<p>If I had listened to all those who say “never work free,” regardless
of the context, this opportunity would never have happened. Never.
<em><strong>Sometimes, you need to make the opportunities for
yourself.</strong></em></p>
<p>I tell this story because when you hear “never work for free” over
and over again, you’re likely going to miss opportunities like this. But
as you have seen, I didn’t work for “free.” I did the initial work
because it was my favorite brand and I was super passionate about them
and the industry and wanted to help them. I saw it as an opportunity to
say “hi” by doing a fun project that would push myself incredibly hard
to come up with a solution. Even if they hadn’t used my design or paid
me, so what? I’d do it all over again in a heartbeat and put that
project in my portfolio, stating that it was an unsolicited design, and
move along.</p>

<h5 id="go-where-the-clients-are" v-view="(e) => viewHandler(e, 'go-where-the-clients-are')" class="section"><strong>Go where the clients
are.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'840ea8aa766640ea897468abdc2eadca'"  :title="`Go where the clients
are`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695136777' || v.videoId === '840ea8aa766640ea897468abdc2eadca')"/>
<p>This won’t surprise you, but I wasn’t born working for Red Bull,
Quicksilver, and popular surf companies. That had to start somewhere.
But I didn’t sit around waiting for these opportunities to hit me up,
either. I didn’t take on hospital design work and assume that Red Bull
was always looking out for the hottest hospital designers in the world
of freelancing, waiting to pounce on someone like me. That’s not how it
works.</p>
<p>What I did do, however, was take a conscious approach to my career.
I’d make designs for brands like SurfApp, which you can see right now at
<a href="https://dribbble.com/dannpetty" target="_blank" class="underline">dribbble.com/dannpetty</a>. Trace back from Nixon.com and you’ll see that I
worked my way up to my dream client by taking on other brands in the
same world — or just making it up. I’ve said it before, but it bears repeating: your portfolio
is just as much about where you want to go as where you’ve been.</p>
<p>Even if you have to do it on spec, create examples of what you’ve
been working towards and put them on Instagram/LinkedIn/Twitter. Start
promoting. Now.</p>

<h5 id="side-projects-show-your-passion" v-view="(e) => viewHandler(e, 'side-projects-show-your-passion')" class="section"><strong>Side projects show
your passion.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'4319b5d6be52437ebb75b14f2733162a'"  :title="`Side projects show
your passion`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695135249' || v.videoId === '4319b5d6be52437ebb75b14f2733162a')"/>
<p>Something you’re going to hear over and over again is, “We want
someone passionate about _______.” It’s usually said because the
companies that are working to solve specific problems are fairly
passionate about finding the solutions to those problems. Take Lyft, for
example. They’re helping to solve a transportation issue. Would they
hire someone who couldn’t care less about transportation? If you said in
an interview that you didn’t care about transportation, you likely
wouldn’t be hired, would you? I don’t know that for a fact, but I do
know it’s a fair assumption. The same applies to a company that says
they’re looking for someone passionate about user experiences. If you
don’t show any of that work in your portfolio and also say in an
interview that you’re not interested in user experiences, you’re likely
not going to get that job. But what if you <em>are</em> passionate about
transportation? How can you <em>show</em> that? What are some ways to
show you’re passionate about a specific topic or area before you land
the client or even get to the interview?</p>
<p>Side projects or passion projects — two terms that are easily
interchangeable — are key. When you hear the term “side projects,” you
might think it refers to a side company or something else that would
take too much valuable time to create, which makes it completely out of
reach for you. While that may be true for some side projects, that’s not
the only type of side project out there. Doing something as simple as
creating a portfolio example can be a side project. Again using Lyft as
our example, imagine you’re a user experience designer and you know that
Lyft is a company you really want to work for because you’re passionate
about transportation (a quality they are looking for). Consider creating
a side project based on transportation. Maybe you create an example user
flow for the onboarding process of an app. Maybe do a design experiment
of a navigational app by showing a map and turning directions, etc. It
doesn’t even have to be directly correlated with a particular company
and their solution to transportation. It could be as simple as creating
other user experiences for other products you might be interested in. The goal
is to show you’re passionate about user experiences, and if you’re
passionate about transportation as well, even better.</p>
<p>Another key to showing passion is choosing the right content for your
designs, illustrations, and photography, etc. Whatever your skill,
choose content that can help articulate your passions. Go back to your
plan. Remember mine? I knew I wanted to design for outdoor adventure
companies and similar companies/industries. I showed this through many
passion/side projects and the content in my portfolio. Most of my
designs had some sort of outdoor imagery and lingo within them.</p>
<ul>
<li><blockquote>
<p>I once created a surf app because I wanted to show more user
experiences in my portfolio.</p>
</blockquote></li>
<li><blockquote>
<p>I once had the opportunity to do a project for Adobe, and for the
content of the project I chose to show surfing-related content, instead
of something random, so my portfolio would not only show my skills but
reinforce my passions.</p>
</blockquote></li>
<li><blockquote>
<p>When Adobe announced XD, I even got to make their demo app, which
they shared on stage at Adobe MAX. What topic do you think I chose? Yup,
a surf app.</p>
</blockquote></li>
</ul>
<p>All of these things help show my passion for the outdoors, especially
with surfing. It’s a simple technique. Try it on your next project or go
back into some older projects that you’ve created for yourself and
adjust the content to fit into your plan.</p>
<p>So, when you think about a side project, don’t think you need to put
out a whole new company or product. It can be as simple as example
projects made just for you and your portfolio. Not only will this help
show off your skills and the type of work you’re passionate about doing,
but it will also be a way to capture potential company/clients’
attention, rather than doing nothing. So you can either spend your days
emailing or being on Twitter, or you can create something to help get
your story out there.</p>

<h5 id="build-a-stellar-portfolio" v-view="(e) => viewHandler(e, 'build-a-stellar-portfolio')" class="section"><strong>Build a stellar
portfolio.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'29e8fe9fc0b041488f1b71ec9053f7ce'" :title="`Build a stellar
portfolio`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695136308' || v.videoId === '29e8fe9fc0b041488f1b71ec9053f7ce')"/>
<p>We’ll tackle more on handling your portfolio later. We’ve already done some before, and again, I have a book <a href="https://thatportfoliobook.com" target="_blank" class="underline">thatportfoliobook.com</a> that goes into this more. on, but But  here’s the
basic intro: imagine your portfolio is not a collection of work.
Instead, think of it as a map of your freelancing destiny — made out of
images of your previous work.</p>
<p>If that doesn’t make sense, remember that portfolios need to be
forward-looking. If you want to work for Nike’s web design, are you
going to include items from your portfolio that show when you designed a
<em>book cover</em>? No, you’re going to show them web designs you did
for athletic brands. The reverse is also true. An individual client
looking for a book cover isn’t going to care that you helped with Nike.
They’re going to want to know what your book covers look like.</p>
<p>This means that you have to sit down and do some thinking. What does
a “nice” portfolio for <em>your</em> purposes really look like? My ideal
portfolio might not be the same as yours. You have to think about the
people reading your portfolio and what they might think. Your goal: to
get them to say to themselves, “Wow! that’s exactly what we’re looking
for.”</p>
<p>Remember: a portfolio is one of the first things your potential
clients will look at. They want to know that your stuff is good enough.
But even more than that, they want to see that if they’re going to ask
you to do something, that you’ve already done something like it before.
They don’t want to take on a freelancer just to coddle them and hold
their hands. They don’t want to spend all that money just to make a
square peg fit into a round hole. They want to hire someone who’s going
to come in and tell everyone, “I’ve got this.”</p>
<p>Imagine the portfolio someone like that has. Then, go about creating
one of your very own. More on that later.</p>

<h5 id="what-to-do-if-you-have-no-work" v-view="(e) => viewHandler(e, 'what-to-do-if-you-have-no-work')" class="section"><strong>What to do if you have
no work.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'8968cd3020f34d198a6ec475f00166fc'" :title="`What to do if you have
no work`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695134363' || v.videoId === '8968cd3020f34d198a6ec475f00166fc')" />
<p>It’s simple, really. If you don’t have work, go create it. Don’t sit
around and wait for work to come to you. Use creating your own work as a
marketing tool and a learning or practicing tool.</p>
<p>Maybe you’re just starting out or you’re fresh out of school and
don’t have any work to show. First of all, that’s okay. We’ve all been
there. Everyone has to start somewhere. The good news is that all you
really need to do is to create it. If you don’t have work to show, go
create it. Don’t rush this process, either.</p>
<p>I often hear designers say they found an app they really liked and
recreated it in a day. Okay, wait. <em>A day</em>? Do you think the
original designers created that same app in a <em>day</em>? Most likely
not. It probably took weeks, if not months or years of research,
education, and trial and error. So what did you really create in a
<em>day</em>?</p>
<p>To have done it in a day, visually, means you likely missed all the
learning opportunities along the way. These are opportunities to grow.
Figure out why they created the navigation the way they did. What were
the driving forces behind their choices? Why did they make the sign-in
button a button rather than text and put it at the top right?</p>
<p>Why did a photographer light an image one way instead of another?
These are things that show that the process took longer than a day. So,
if you’re taking the time to create new work, learn from it rather than
simply replicating someone else. Understand why they made the choices
they made.</p>
<p>If you know you want to be a fashion photographer, take photos of
your friends. Buy them lunch or coffee or, better yet, buy them the
clothes you’re asking them to model. Rent an Airbnb for a day, use your
own home or find some sweet urban background around town (if that’s your
vibe) and go take photos.</p>
<p>You see, it’s not always about <em>who</em> you are creating for.
It’s about <em>what</em> you are capable of creating. It’s about
<em>why</em> you’re creating it. The answer is that you’re passionate
about it and want to do more of it. The <em>who</em> can come later. But
even more important, don’t shortchange yourself. Even if you simply
create for yourself, that shows plenty more than if you were to do
nothing at all. Chances are, that’s what people hiring are looking for —
someone who is willing to learn.</p>
<p>If you’re a designer, make a fake company and design something for
it. Find a non-profit to do gratis design work for. Find a developer
friend to trade services with. Ask your local coffee shop to trade some
design for a gift card or some free coffee. The key is to start
somewhere but be careful, some companies out there might try to take
advantage of you and ask for spec work.</p>

<h5 id="watch-your-online-presence" v-view="(e) => viewHandler(e, 'watch-your-online-presence')" class="section"><strong>Watch your online
presence.</strong></h5>
<p>Here’s the thing: you have to be careful what you share online.
Whether you like to admit it or not, every tweet or Instagram post you
share will send out a signal about who you are as a person. If you think
people only hire freelancers based on their work, you’re wrong. Their
personality and attitude have a lot to do with it as well.</p>
<p>If you’re sharing thoughtful ideas on Twitter and participating
positively in the community, that speaks volumes about your reputation.
Alternatively, if you’re on Twitter bashing and bad-mouthing others,
that does nothing to aid your reputation and instead shows you to be
someone you probably wouldn’t want on your team. Now and then I slip up
and tweet things I shouldn’t, but thankfully, those who know me as
someone who is positive and community-focused don’t hesitate to call me
out. When that happens, I go back to my plan—and also do some honest
self-reflection, and then adjust accordingly.</p>
<p>You might think this isn’t a big deal, but it is. You can learn a lot
about a person by how they treat others online and what they talk about.
Companies/clients hiring you WILL check out your social media. They
will. There’s no longer any doubt. Your link is probably even on your
portfolio somewhere. Why wouldn’t they check it out? Adding to your bio
“Tweets are not representative of my company or my work” will not help
you at all. Be yourself but keep in mind that your social media
reputation is just as important as the reputation clients see in your
portfolio. A portfolio isn’t just a showcase of your work, it’s a story
of you.</p>

<h5 id="learn-the-power-of-a-question" v-view="(e) => viewHandler(e, 'learn-the-power-of-a-question')" class="section"><strong>Learn the power of a
question.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'f87cf02ec7514ab680ac77b67065bbde'" :title="`Learn the power of a
question`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695134821' || v.videoId === 'f87cf02ec7514ab680ac77b67065bbde')"/>
<p>Back in grade school, we passed notes to each other with ridiculous
questions. “Do you like me? Yes/No?”</p>
<p>Most of us look back on those days and cringe over the questions we
were asking. But here’s the thing: you never know if you don’t ask. You
can trace a lot of my success as a freelancer back to a simple rule. If
you don’t ask, the answer is always no. Asking something is the only way
to introduce the possibility of success.</p>
<p>When I started Epicurrence — the design event, I didn’t just wait in a
vacuum for everyone to discover it. I sent invites to companies like
Dropbox and Facebook. Any lead I knew, any email I had in my contacts
list, I made sure to send out an invite. The success of Epicurrence
partially stems from me asking questions.</p>
<p>I’ve also sent out Tweets asking for an NFT (non-fungible token) in
exchange for a few hours of time. I’ll say to my Twitter audience, “if
you need something, here I am.” Become someone who introduces new
possibilities into other peoples’ lives. You’ll be amazed at what
happens.</p>
<p>The reason questions are so powerful is that there’s an unlimited
upside but limited downside. The worst that can happen is that someone
says no. In many cases, that “no” doesn’t do anything to harm your
relationship with that other person. The best thing that happens? They
say yes, and you start building a powerful relationship with someone
that ends up being a long-term benefit for both of you.</p>
<p>There are few areas in life where you can get such guaranteed returns
as the power of asking questions. It’s one of the most reliable ways to
make a name for yourself. But because we’re so focused on the (limited)
downside, we don’t think about the possibilities that come with the
(unlimited) upside.</p>
<p>That’s why a lot of times when I’m really excited about something or connecting with someone over email or text or whatever, I always end the email with “Stoked, on the possibilities.” The possibility of something, of anything, is magical.</p>
<p>Psychologists are starting to take notice of the power of questions,
too. Professor Vanessa Bohns of Cornell University has even studied the
power behind asking questions. The conclusion of her research? It’s
right in the title of her book: <em><u>You Have More Influence Than You
Think</u></em>. In many cases, her studies proved that people were far
more likely to say yes than her students assumed.</p>
<p>But here is why it works: nothing happens if you don’t ask. If you
don’t invite people to your events, if you don’t promote yourself, if
you don’t ever put yourself out there — you’ll only get a whole lot of
silence in return. You don't want that. It’s much more interesting to
put yourself out there and see what comes back.</p>

<h5 id="try-five-ideas-to-get-you-noticed" v-view="(e) => viewHandler(e, 'try-five-ideas-to-get-you-noticed')" class="section"><strong>Try five ideas to
get you noticed.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'7d8dd82ce4ee478ea2ec59ef13c30b9a'" :title="`Try five ideas to
get you noticed`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695132107' || v.videoId === '7d8dd82ce4ee478ea2ec59ef13c30b9a')"/>
<p><strong>#1 – Go where the clients are and start talking.</strong></p>
<p>Imagine you lived in the 1800s and had to drum up business. Could you
do it sitting at home, writing or painting in your studio? Of course
not. You’d have to go where the clients are. Even though we live in an
interconnected, digital world now, the rules are still the same. You have
to show up where your clients are. Where are they talking? Twitter? What
hashtags are they using? What subreddits are they lurking? What message
boards do they use?</p>
<p><strong>#2: Be okay being a little bit weird.</strong></p>
<p>When I relay my bio to people, I don’t try to sugarcoat it and make
myself sound like someone who can only be spotted in suits and ties. I
let people know why I’m different. I talk about my love for surfing. I
talk about how I love relationships with people. If there’s a quirk you
have or something unique about your personality, don’t avoid it in favor
of being a corporate cut-out. Be just a little bit weird. Weird people
are memorable people. Indulge that creative side of yourself, because
when someone has to hire a freelancer, who are they going to remember?
The one person who stood out.</p>
<p><strong>#3 – Be really, really good.</strong></p>
<p>When you recognize an opportunity to build up your portfolio with
something that can change your destiny as a freelancer, take it. Put
extra <em>oomf</em> into that project. Really get creative. Do the kind
of work you always dreamed of doing. Even if your client doesn’t approve
it because they have different directions, you can always use that work
in the portfolio and simply state that it wasn’t what the client
ultimately went with. But when you get great projects and work with
great people, don’t forget: you’re here to wow them. Give it your full
and complete attention. Be as good as you can be. Make it your goal for
them to say “wow!” in their reply emails.</p>
<p><strong>#4 – Be the person who persists.</strong></p>
<p>I’m not saying that you should approach the CEO of Nike every morning
and stalk them. That’s not good persistence. But sometimes you have to
remember that sending out one tweet or turning in one good project does
not make a reputation. Be the person who persists. Keep tweeting. Keep
talking. Keep putting yourself out there. Keep posting up your work,
even if no one is giving you likes. You know why? Because eventually
someone is going to come across your online presence and see how active
you are. They’re the ones who are going to hire you. Work for them; not
for the people who are ignoring you. And keep at it.</p>
<p><strong>#5 – Go old school and send the company or client a
gift.</strong></p>
<p>Let’s be real. Who doesn’t like to receive a package in the mail? I
personally love to receive coffee mugs. Coffee mugs are my thing. But how rare is this? Incredibly
rare! Ask any company/client when they last received a portfolio
“package” in the mail. It’s been awhile. Years ago, however, this was a
proven method but now with social media and email, it’s so easy to reach
someone that this old school, more time-consuming method has fallen off.
That is, for everyone but you anyways 😉. If there’s a company out there
that you really want to work for, send them something. Have your
portfolio ready to go online but also print it out in a beautifully laid
out piece. That could be as simple as going to Kinkos, but these days
there’s a lot of printing options online that are equally “affordable”.
Package it up nice and neat, maybe include a coffee mug 😉 along with
your favorite hometown whole beans and a little note.</p>
<p><em>Bam!</em> You just got someone’s attention. Imagine them opening
the package and getting a whiff of that coffee. Now in a good mood, they
see the rest of your items included, like the note and your printed
portfolio. You likely just scored a few more moments of their time to
check out your work. Even if you don’t get the job or you’re not what
they’re looking for, this gesture could entice them to look further into
the company for a role that might fit you. Or, just as good, they might
refer you to someone else they may know. Regardless, you made an
impressive attempt at letting them know that you exist. And guess
what—now they know. Because you just did more than 99.99% of everyone
else does.</p>
<p>Be sure to do your research on who you’re sending the package to and
what they like. Don’t come across creepy. Don’t get too personal.
Consider something that they can keep by their desk or around the
office. That’s nice because when they see it, they’ll remember how they
got it. If it’s too random, it’ll likely get thrown away but still,
that’s ok. You still got their attention.</p>
<p>Remember, invest. Invest in yourself and your career. If you won’t,
why would someone else? Does that mean go and send a package that costs
$40 to 10 different companies? Absolutely not, focus. Just put a little
more effort into trying to get that job you’ve always wanted.</p>

<h2 ref="chapter4" id="chapter-four-working-with-clients" v-view="(e) => viewHandler(e, 'chapter-four-working-with-clients')" class="chapter text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter Four: Working
with Clients</strong></h2>
<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'87aa7074d3124088aaf60afe6fed5ddf'" :title="'Chapter 4'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695214976' || v.videoId === '87aa7074d3124088aaf60afe6fed5ddf')"/>
<p class="text-black-caption  text-base font-atlas-medium">Complete Chapter Video - 16:25</p>
</div>
<p>Once you’ve secured a client, you should have a very simple goal: be
the kind of freelancer they’ll recommend to someone else. That’s it.
It’s a simple concept, but making it work in reality can have a bit of a
learning curve. In this chapter, I’ll share with you everything I’ve
learned about being the kind of person businesses like to hire
again.</p>

<h5 id="communication-is-everything" v-view="(e) => viewHandler(e, 'communication-is-everything')" class="section"><strong>Communication is
everything.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'b22e5f813e2e4b00ac86c86d2d42c073'" :title="`Communication is
everything`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695137613' || v.videoId === 'b22e5f813e2e4b00ac86c86d2d42c073')"/>
<p><em>Think of this as a relationship.</em></p>
<p>Maybe you’re not the relationship type. Maybe you’re an introvert.
But here’s the thing about freelancing: when you work with a client, you
have to think of it as a relationship. And like any other good
relationship, your business relationships will hinge on your ability to
communicate.</p>
<ul>
<li><blockquote>
<p>Are you easy to talk to?</p>
</blockquote></li>
<li><blockquote>
<p>Are you fun to work with?</p>
</blockquote></li>
<li><blockquote>
<p>Are you trustworthy?</p>
</blockquote></li>
<li><blockquote>
<p>Are you open and honest?</p>
</blockquote></li>
<li><blockquote>
<p>Are you reliable?</p>
</blockquote></li>
<li><blockquote>
<p>Do you tell them the truth about whether you don’t respond on Slack,
but are available via text?</p>
</blockquote></li>
</ul>
<p><strong>Remember:</strong> the goal is to be the kind of person you’d
want to work with.</p>
<p>Communication is also a two-way street. You have to listen well. When
your client spells out your limitations, take them seriously. When they
tell you what they want to achieve, write it down.</p>
<p>I’ll share one thing I do that’s different from a lot of freelancers:
I communicate via text. Gasp! I know. A lot of freelancers are big on
setting boundaries between personal and professional communication. And
maybe that works for them. But for me, I find email so “businessy” that
it doesn’t create the kind of communication I’m after. I’d rather be
someone who’s looser and more relaxed. When they get communication from
me, I don’t want it to feel like a homework assignment. I want them to
think something more like: ”Great, it’s my buddy Dann reaching out.
Wonder what he has to say.”</p>
<p>It doesn’t work for everybody. But it works great for me. And every
time I share my personal number with people, I notice that something
interesting happens. Our relationship ends up going deeper than
business. We become solid friends. And more often than not, they
constantly refer me to other people who need a freelancer.</p>
<p><em><strong>Why it matters for freelancers:</strong></em></p>
<p>Freelancing isn’t like other jobs. For first-time freelance clients,
there might be some nervousness there. They’re not sure about
freelancers. Did they do the right thing in hiring you? Are you going to
skip town with their money? Are you going to deliver subpar work?
Remember: try to see things from the client’s side. Maybe you know
you’re hard at work and doing your best to give them some great bang for
their buck. But if you never communicate that to them, how are they
supposed to know?</p>
<p>You’re not working in an office, so it’s not like a client can come
in and check on you while they’re on their way to the water cooler. You
have to keep the lines of communication open, even if it’s just a quick
pop-in. (Remember: you don’t want to be a drag, either.)</p>
<p><em><strong>How to Have Better Communication:</strong></em></p>
<p>Here are some easy tips for getting better at communication:</p>
<ul>
<li><blockquote>
<p>Invest in contact management systems or CRM. As soon as you get a new
client, onboard all their information (name, number, physical address,
email, who to invoice, etc.) Do this ASAP. Your goal is never to have to
ask them the basic information again. If you never ask this twice,
you’ll show all the good signs of a pro who’s got freelancing down to a
science. Asking a business acquaintance for new information that you
should already know is the professional equivalent of saying “new phone,
who dis?” It doesn’t exactly bode well for how close of a relationship
you have.</p>
</blockquote></li>
<li><blockquote>
<p>Provide an occasional update they didn’t ask for. Remember: open the
lines of communication. Keep your client in the loop. Just drop them the
occasional line, such as “finished the mock-ups” or “finished the first
draft.” Your goal isn’t to create more work for them; your goal is just
to let them know you’re not off in the tropics spending their money on
Mojitos.</p>
</blockquote></li>
<li><blockquote>
<p>Ask for feedback early in the process. This is key, especially with
big projects. You don’t want to get 10,000 words into an eBook for a
client and find out you’ve been writing about flour when the assignment
was to write about flowers. In graphic design, you can always put
together a rough mockup and say, “like this?” This accomplishes two
things: first, it makes them feel involved. Second, it prevents you from
wasting a lot of your time by avoiding the wrong approach.</p>
</blockquote></li>
</ul>

<h5 id="be-easy-to-work-with" v-view="(e) => viewHandler(e, 'be-easy-to-work-with')" class="section"><strong>Be easy to work
with.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'6f96b1c66286490d9d9edf94fd8ace88'" :title="`Be easy to work
with`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695138206' || v.videoId === '6f96b1c66286490d9d9edf94fd8ace88')"/>
<p>Clients can forgive a lot if you’re easy to work with. You might not
always be able to control how a client responds to your work. You might
not always be able to control who likes you. But everyone should know
that you’re easy to work with. That means answering yes to the basic
questions. Are you polite? Are you communicative? Do you read through
client directions carefully?</p>
<p>Even if you only have 80% the talent of the next freelancer—which is
a ridiculous idea anyway—you’ll generally earn more business connections
if you’re reliable.</p>
<p>Why? For a client, hiring someone new is a pain in the butt. They
have to interview people. Create job postings. Review portfolios. Get a
new freelancer onboarded. From their perspective, they’re begging the
universe for a reliable freelancer. That’s all they want. They hire a
freelancer because they want work off their plate. Master the art of
doing that reliably and you’ll never want for paid work again.</p>
<p>This isn’t just an abstraction. As your freelance career blossoms,
you’ll notice it produces tangible results. Think about it this way:
when you’ve shown a client that you’re reliable, who do you think
they’re going to pick when they have a new project coming their way?
They’re going to say, “Hey, Dann was great to work with on that last
project. I wonder if he’s free.”</p>
<p>For me, the ultimate client compliment is simple. I love it when
clients say I’m easy to work with. Because when I’m easy to work with, I
know I’m doing the important stuff right.</p>
<p>Another key here is to <strong>learn how to communicate without
criticizing</strong>. As a freelancer working from the outside, you may
occasionally have to push a few buttons to get things moving. You may
have to egg someone on to share a Google doc with you. Learn how to be
easy to work with. There are some great tips from Harvard Business
Review you can use:</p>
<ul>
<li><blockquote>
<p>Ask questions, rather than make demands. Let’s say you need someone
to share a Google doc with you. Is it better to email, “I’m so
frustrated you won’t share this Google Doc with me after I asked you
FIVE DOZEN TIMES” or “Hey there, just popping into the Google Doc. Do
you mind giving me access?”</p>
</blockquote></li>
<li><blockquote>
<p>Don’t jump to conclusions if communication is short. If someone
doesn’t reply to an email within 24 hours, don’t ask them why they’re
ignoring you. That’s accusatory. What if this is a client who is
perfectly happy to talk to you, but they were out of the office that day
because their child had to go to the emergency room? Cut people a little
slack.</p>
</blockquote></li>
<li><blockquote>
<p>Learn how to bring up complaints without whining. Reframe a complaint
in a positive way. Don’t say, “You don’t pay me enough.” Ask someone:
“How can I be worth an extra 10%?”</p>
</blockquote></li>
</ul>
<p>One last bit on being easy to work with: hit every deadline. If 90%
of success in life is showing up, 90% of success in freelancing is
getting your work to show up. And if you’re not going to hit a deadline,
tell someone as soon as you can. People hate surprises. But they’re
often more than willing to work with you if you give them some time to
adapt. They’ll also appreciate the honesty. You’re putting them in a
position to decide, rather than making life that much harder for
them—but if you wait, you run the risk of making life harder on
them.</p>
<p>To make sure you hit deadlines, always start on a new project as soon
as possible. Put it in your calendar. Break it up into small pieces, and
take on the first piece as soon as you’re done talking to someone about
the project. Block out the task on your schedule, and you’ll be sure
never to overextend your reach.</p>

<h5 id="be-upfront-about-your-timing" v-view="(e) => viewHandler(e, 'be-upfront-about-your-timing')" class="section"><strong>Be upfront about your
timing.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'2cdeb86cf47b4cc991fe05d86dfb1751'" :title="`Be upfront about your
timing`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695138701' || v.videoId === '2cdeb86cf47b4cc991fe05d86dfb1751')"/>
<p>You’ll probably never struggle with deadlines if you’re upfront with
clients from the get-go. Here are some basic ways you can learn to be
upfront about how you work:</p>
<ul>
<li><blockquote>
<p><strong>Set clear expectations.</strong> This is especially important
when you’re starting with a new client. If you work 20-hour days to meet
a hard deadline on your first project together, the client might come to
expect that speed every time. It’s not realistic. But if you set clear
expectations for how much you can accomplish off the bat, you’ll later
have the flexibility to exceed those expectations when you can.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Tell them if you’re not going to hit a deadline.</strong>
This one is worth repeating from the previous section because it’s that
important. Don’t make clients wonder. Don’t make them wait. If there are
adjustments you need to make to the schedule, let them know right away.
This softens the blow, especially if you do it early on at a point when
your clients do have time to adjust their expectations. Wait too long
and that opportunity may run out.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Don’t take on projects that expect too much.</strong> If a
project is “out of your league” for the time being, that’s okay. It’s
better to work your way up the ladder with a string of successful
projects than to bite off more than you can chew and never work with
that client again. At the very least, make the client understand your
challenges with the timing. Tell them if they need to adjust their
schedule expectations. They won’t have any way of knowing without your
input. And if they can’t adjust, maybe you don’t want to work with that
client anyway.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Remember why you got into freelancing.</strong> The idea is
to build a career based on your timeline. It’s to have more flexibility.
Don’t trade one boss at the office for five clients breathing down your
neck. Yes, that means you’ve “diversified your bosses,” but you haven’t
really changed your life. The beauty of freelancing is that it’s a
career that you can shape according to your own designs. Never lose
sight of that.</p>
</blockquote></li>
</ul>

<h5 id="what-clients-care-about" v-view="(e) => viewHandler(e, 'what-clients-care-about')" class="section"><strong>What clients care
about.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'fe6d4273f3b947d79288aff80b1c995b'" :title="`What clients care
about`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695136858' || v.videoId === 'fe6d4273f3b947d79288aff80b1c995b')"/>
<p>At its core, freelancing is a service job. You have paying clients
who want something done, and your job is to do it. And like any service,
your role is to put yourself in their shoes and ask, “how would I want
to be treated if I were in their situation?”</p>
<p>For starters, clients just care about getting the work done. Every
client is different, true. But ultimately, they’re reaching out to a
freelancer because there’s work to be done and they don’t have the time
or resources to take it on themselves. Bottom line, you’re here to
supplement their team and provide them with something they otherwise
couldn’t get.</p>
<p>Beyond that, it might surprise you to know what clients most care
about. Most freelancers think...</p>
<ul>
<li><blockquote>
<p>...clients are going to try to do all of the work themselves. What
clients really care about is outsourcing to someone else so they don’t
have to check-in.</p>
</blockquote></li>
<li><blockquote>
<p>...clients aren’t going to pay great rates. The truth is, many of the
best clients understand that quality work requires a quality price. And
they also know that quality work can often save them costs down the
road, or even give them an ROI that’s more than worth what they put
in.</p>
</blockquote></li>
<li><blockquote>
<p>...clients aren’t going to be flexible. But have you asked the
clients for any flexibility? You might be surprised at how accommodating
clients can be if you simply pop a question their way. “Is there
flexibility in the budget?” “Can I have an extra week on this?” “Do you
have any other resources you want me to pull from?” Think about it this
way: clients want you to do the best job possible. If saying “yes” to
one of those questions is what it takes, they’re often happy to do it.
Just like you, they value a long-term relationship in which both sides
are happy.</p>
</blockquote></li>
</ul>
<p>To satisfy clients, you ultimately have to put yourself in their
shoes. What would you want out of a freelancer? I know what I’d want:
I’d want someone who I can talk to without it being too businessy. I’d
want someone who reliably did the work without me having to check in on
them. I’d want someone who comes back with great work, and if the work
isn’t great, is willing to listen to feedback and adjust as
necessary.</p>
<p>There you have it. That’s your job as a freelancer. You’re here to do
something the client either can’t do or isn’t willing to do. If you do
it on time, on budget, and bring a positive, upbeat attitude to every
project, that alone will take you far.</p>

<h5 id="common-mistakes-to-avoid" v-view="(e) => viewHandler(e, 'common-mistakes-to-avoid')" class="section"><strong>Common mistakes to
avoid.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'971edfc19fc14a2eaf19d70c9be13a21'" :title="`Common mistakes to
avoid`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695137056' || v.videoId === '971edfc19fc14a2eaf19d70c9be13a21')"/>
<p>Communication seems easy. You should just communicate a lot, be
available, and be nice, right? Well, not exactly. As you progress in
your career, you’re going to find that these ingredients aren’t enough
to make your life as a freelancer easy. You’ll also have to know some
common mistakes to avoid whenever you communicate with clients.</p>
<p><strong>Mistake #1: Overbooking.</strong> When you start using the
principles in this chapter, you might earn yourself a few referrals.
Your schedule will start to fill up. Congratulations! But now you run
into a higher-quality problem. You might overbook. Try to focus on
client quality, not quantity. I know: it’s going to seem intuitive to
book yourself to the max because you’re afraid that the income is going
to run out. But it’s better for you long-term if you give yourself the
flexibility and time to devote your full attention to each project.</p>
<p><strong>Mistake #2: Under-communicating.</strong> Don’t assume the
client doesn’t want to hear from you. Clients are more likely to be
offended when you haven’t reached out than to be annoyed that you’ve
dropped another email in their inbox. And they’re far more likely to be
peeved if you haven’t communicated a key problem.</p>
<p><strong>Mistake #3: Laziness.</strong> Did you skim their
instructions? Did you recycle old work? Did you put minimal time and
effort into the project because you assume they don’t know better? The
key to winning over clients and having them refer you to other people is
to over-deliver on expectations. This is one reason why overbooking can
be so damaging: you need time and energy to focus on client quality.</p>
<p><strong>Mistake #4: Selling yourself short.</strong> Many freelancers
aren’t used to self-promotion or getting their names in front of people
because they’ve never done anything but apply to jobs and scholarships
and submit to interviews. They’re used to filling out forms. They’re not
used to working outside the form. But freelancing is a new paradigm.
Grow comfortable with self-promotion and be willing to toot your own
horn. You’re on your own, and no one else is going to do it for you
until you’ve built a network.</p>
<p>Ultimately, what you do as a freelancer is going to depend on your
approach. It’s a business of meeting people and sustaining
relationships. And for many of these businesses, they won’t have heard
of you until you reach out to them. You might be friendly and convivial
in your private life. But unless you take the <em>proactive steps</em>
you see here to let <em>them</em> know that, they’re not going to
respond to you that way.</p>
<p>This chapter is all about taking the right approach. Some of these
points may seem common sense. Others, you might not have heard before.
But ultimately it comes down to execution. Are you <em>really</em>
putting yourself out there in the ways outlined in this chapter, or are
you simply hoping for the best?</p>
<p>Don’t be a hope-for-the-bester. Make it happen.</p>

<h2 ref="chapter5" id="chapter-five-guide-to-pricing" v-view="(e) => viewHandler(e, 'chapter-five-guide-to-pricing')" class="chapter text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter Five: Guide to
Pricing</strong></h2>
<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'6101395d6e084768aecdf1bfd7dc6f23'" :title="'Chapter 5'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696770040' || v.videoId === '6101395d6e084768aecdf1bfd7dc6f23')"/>
<p class="text-black-caption text-base font-atlas-medium">Complete Chapter Video - 1:13:13</p>
</div>
<p>Figuring out how and what you’re going to charge your clients is
probably the toughest part about becoming a freelancer for many. But it
doesn’t need to be. In this chapter, we’ll go over some ideas you can
try— but the main takeaway is that it comes down to trial and error and
my main motto: be flexible.</p>
<p>I admit it’s tough. You don’t want to undersell yourself and leave
money on the table. But at the same time, you don’t want to
<em>oversell</em> and lose good work because you were too expensive.</p>
<p>We can go back and forth on this all day. Like a lot of freelancing,
pricing can be a head game. Whatever you decide, offer it with
confidence and remain open to flexibility. One of the biggest mistakes
you see in freelancers is never budging on their pricing as well as
never increasing their pricing.</p>

<h5 id="how-i-charge" v-view="(e) => viewHandler(e, 'how-i-charge')" class="section"><strong>How I charge.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'c1c8ac5a99014fa39ff76c4c7bbed041'" :title="`How I charge`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695142614' || v.videoId === 'c1c8ac5a99014fa39ff76c4c7bbed041')"/>
<p>Before we dig into the different types of charging clients, I’d like
to go over some quick stories on how I charge. In short, when someone
reaches out to me I let them know right away that I’m $7,000 - $10,000
per week for my design work so we don’t waste any time chatting if we’re
not a good fit for each other. I explain why I choose a weekly fee
instead of anything else later in this section, but here’s an example of
my initial response to a client:</p>
<blockquote>
<p><strong>Client:</strong> Hey Dann, love your work. I’m looking for
someone to help me design a website for my new start-up and wanted to
see what your availability was like. Got time to chat sometime this week
or next?</p>
<p><strong>My response:</strong> Hey! Thanks for reaching out. This
project sounds awesome already (<em>Show some excitement and genuine
interest – remember you don’t have the project yet</em>). Yes, I am
available (<em>Always say you’re available even if you’re busy because
you never know when they actually want you to start. So many freelancers
mess this up and lose big work</em>) and currently, I’m around $7-$10K
per week. (<em>Let them know roughly how much you cost already to help
weed out clients that aren’t seriously looking or that don’t have the
budget to fully pay you. This saves a lot of unnecessary back and
forth.</em>) If this fits your budget, let’s chat Wednesday at 1 PM CT.
Here’s a Zoom link. (<em>Be a leader, set the time and date, or send
them a Calendly link to select their own time. Don’t give a bunch of
options here keep it simple</em>.)</p>
</blockquote>
<p>As you can see, that response covers a lot and it’s very purposely
written with the intent to answer the client’s biggest questions. Am I
available? Am I interested? What might I charge?</p>
<p>Before we dig a little further, $7,000 - $10,000 per week might sound
high to some of you, but don’t forget, I’ve been doing this for a very
long time and I’m very good at what I do. My designs are unique and I’m
very fast at creating them. But just because I’m $7,000 - $10,000 per
week does not mean that’s what I always charge. And it does not mean
that’s what you need to charge.</p>
<p>For one, you’ll notice that’s actually a pretty big range. I like to
use this range for when clients reach out, I can immediately respond
with some numbers to give them an idea of how much it might cost to work
with me. In my experience, it’s a great way to find out who has a
serious project and who doesn’t.</p>
<p>If a client responds back now with something like:</p>
<blockquote>
<p><strong>Client:</strong> <em>“Thanks for getting back to me.
Wednesday sounds great looking forward to chatting and potentially
working together.”</em></p>
</blockquote>
<p>If that’s the response back, I know we’re in business. If the
response back is something like:</p>
<blockquote>
<p><strong>Client:</strong> <em>“Thanks for the information but actually
sounds like you’re out of our budget, thanks.”</em></p>
</blockquote>
<p>If that’s the response, then I just filtered out a client that wasn’t
a good fit with one simple email – no wasted calls or anything. I can
tell by the client’s language they are not interested in pursuing any
further conversation with me so it likely means they have very little
money to spend so I will not respond back at this point. Sometimes they
never even respond back themselves from the initial replay back which is
great as well. The goal is to just filter out who is serious or not and
who has the budget to continue chatting with.</p>
<p>Now there’s another response back you might get from your first reply
and it could be something like this:</p>
<blockquote>
<p><strong>Client:</strong> <em>“Thanks for the information.
Unfortunately, it sounds like you might be out of our range. Is there
any flexibility here at all? If not, do you have any friends you can
recommend?”</em></p>
</blockquote>
<p>I love these responses – don’t ignore these. For one, the client
seems like they really want to work with you and aren’t just trying to
find the lowest bidder. Secondly, they trust your judgment on other
designers and you can start a relationship with this client without even
working with them directly but just referring another designer to them.
From here, it really comes down to whether or not you’re interested in
the project and working with the client. If you are, set up a
conversation with the client to feel them out. Learn more about their
project and their timeline. Try to get a sense of the potential
relationship and benefits. If you’re interested, respond back with 3
options on what you can do. Get creative with. As a rough example,
here’s what I might respond with if the client was asking for a simple
landing page for their new start-up.</p>
<blockquote>
<p><strong>My response:</strong> <em>No worries, totally understand how
it can be with start-ups – been in your shoes before a few times. After
chatting with you normally this project would take a good 2 weeks and
would be about $14,000 but I tell you what, I really do love what you’re
doing and would love to throw a few ideas your way to see if there’s
something we can do together with a tighter budget. Let me know if any
of these options sound doable:</em></p>
<p><em>Option 1: Knowing it’ll typically take 2 weeks, what if we did it
in 3-4 weeks and I’ll take my time a little bit, not rush it, for
$10,000. Just note, I won’t be able to do a lot of revisions here and
timely feedback will be very important.</em></p>
<p><em>(This is giving the client a $4,000 off deal and in return, I am
to just work on the project here and there as I can over the next 4
weeks. So in reality, I’ll still just work maybe 1.5 weeks total on it
and likely have other projects going at the same time making me even
more money ☺.)</em></p>
<p><em>Option 2: What if I do the initial design style and we then hand
it off to another designer that could likely flush it out and finish it
for a little less. This way, you may be able to squeeze in a little more
time with another designer but would still have a very strong design to
start with. We could do this for $6,000 in just a week.</em></p>
<p><em>(In this option, I get just $1,000 less for the project coming in
at $6,000 instead of the initial $7,000 estimated but I don’t even need
to finish it – I just get to do the initial concept which I absolutely
love doing the most so this works out perfectly for me.)</em></p>
<p><em>Option 3: I like to get creative sometimes with payment. I need a
new camera for work. What if you snag me “this” camera and “this” lens
which comes out to $8,381. Let’s keep it chill and I’ll get you over a
cool design. Throw on “this” other lens (+$1200) as well and we’ll
really make it sweet.</em></p>
</blockquote>
<p>Basically, I mention that I’m flexible if I’m really interested in
the project and I’ll request a chat to go over the project a little
further to see what I can do for them but only if it fits a lot of my
interests and if I can see a valuable relationship here. Otherwise, I’ll
refer them to a friend if I have one available.</p>
<p>The example above is a great look at how simply being a little
flexible can still get you paid in a healthy way and oftentimes, make
you even more money than before. I prefer projects that will let me have
more time on the project for less because it’s easier to stack the
projects which can double or triple how much I make every month.</p>
<p>Now let's quickly go back to that original reply we sent to the
client. Let’s say they responded back and we did the chat to go over the
project and they still sounded very interested. During the chat, I
likely say something like “All this sounds great, let me get back to you
with a proposal on what I can do pricing was, and let’s go from there.”
From here, I’m able to think clearly about how I might handle the
project now that I have more details. With the new information in mind,
I send the client my ideas for costs and timing and it always includes 3
options. My response back might look something like this:</p>
<blockquote>
<p><strong>My response:</strong> <em>“Hey! Great chatting today. I’m
really excited to work on this together. After some thought, here’s what
we can do pricing-wise. Let me know what works best for you.</em></p>
<p><em>Option 1: Since there’s a lot of pages that need to be designed,
I’d be more comfortable on the $8,000 per week side and I’m thinking
we’d go around 5-7 weeks depending on how quickly I can get feedback. I
will be charging $8,000 each week from start to finish. ($40,000 -
$56,000 price range for the project).</em></p>
<p><em>Option 2: If you need this done quicker than 7 weeks, I can
ensure 3 weeks if we’ve got a strong feedback loop. We can have it done
by “this” date. I’d do this for $50K. There’s a chance we may even get
it done in 3 weeks if we nail the design right away. (Set $50,000 for
the project).</em></p>
</blockquote>
<p>Here are some examples of how I’ve been flexible over my career:</p>
<p>In 2020, the creator of Recess.tv reached out with an idea to create
a Netflix-style website for fitness. I came in to do the initial design
ideas for the website. My normal rate is anywhere from $7-10,000 per
week for design. Knowing that Recess was a very early stage start-up, I
knew I wouldn’t be able to charge my $10,000 weekly fee premium straight
up.</p>
<h5 id="important-be-flexible" v-view="(e) => viewHandler(e, 'important-be-flexible')" class="section"><strong>Important: be
flexible.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'ef1bf789160a4e7693c818841bbc69a0'" :title="`Important: be
flexible`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '701368179' || v.videoId === 'ef1bf789160a4e7693c818841bbc69a0')"/>
<p>In 2019, my last freelance project of the year was a $100,000
redesign for a website design with around 12 different styled pages. In
2020, I did a project worth over $200,000. Just the other day, I did a
website for $5,000.<br />
<br />
Wait, from $100K to $5K? Yup. Every project, every client, every
timeline, every budget, every opportunity is different. Remember, I’m a
web and app designer—those come in all different shapes and sizes. I’m
sure you’ve probably read the (bad) advice saying you should never be
flexible with your pricing or never to budge on your pricing. That’s
probably the (worst) advice you’ll hear most as you start your journey
as a freelancer. In theory, I get where they are coming from. You don’t
want to undermine the industry and your worth. So, it comes from a good
place. But, without proper context on each individual opportunity, it’s
actually very hurtful advice. Here’s why:</p>
<p>Let’s say you quote a project for $10,000 (that’s my personal weekly
rate) but the client’s budget happens to only be $8,000. To add to this
story, let’s also say you have no other work at the moment. Are you
really going to turn down $8,000 because of your stubborn stance on
never being flexible? Are you going to say no to $8,000, and now only
get $0, because they wouldn’t pay you $2,000 more knowing that you have
literally zero other opportunities at the moment? I sure hope not.</p>
<p>Don’t just say “No, I’m $10,000 take it or leave it.” Not only does
that come off incredibly rude but that will leave a lasting impression
on that potential client and you never know who their friends are let
alone, you can forget them ever reaching back out again. Am I saying
work for whatever money the client has? Absolutely not. If their budget
was only, say $5,000 (half of my normal rate) depending on the
circumstances it’s closer to a NO for me than a YES but even still, it’s
not a for sure NO yet.</p>
<p>Do I know this client? Have I worked with them before? Do I already
have a relationship with them? Will this lead to more work? Will I enjoy
this work? Can I take my time with it? Do I have full creative freedom?
Is it going to look good in my portfolio and potentially lead to more
similar work? You see, there are so many questions, it’s never an easy
yes or no without proper context. So be careful when people give you any
type of absolute advice when it comes to pricing. Remember, every client
and project is different. Treat it as such and you’ll notice more
opportunities.</p>

<h5
id="follow-these-four-steps-to-discover-your-pricing" v-view="(e) => viewHandler(e, 'follow-these-four-steps-to-discover-your-pricing')" class="section"><strong>Follow
these four steps to discover your pricing.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'f85f6a79e1214c75916a3f692b4fb48b'" :title="`Follow
these four steps to discover your pricing`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695139078' || v.videoId === 'f85f6a79e1214c75916a3f692b4fb48b')"/>

<p>Ask designer Chris Do how you should price yourself and he might draw
you an example: the Nike logo. Then he might ask you what that’s worth.
The answer? It’s not $500, which some people might charge. It’s not even
$20,000 for a logo, which might sound outlandish for someone just
getting started.</p>
<p>That logo, the famous Nike “swoosh,” is worth billions of
dollars.</p>
<p>Chris Do’s message is simple: you can charge the client what you
bring that same client in value. And while everyone might be talented
enough to create a swoosh logo, it’s in the value you bring to the table
that you really determine your pricing.</p>
<p>Chris Do says that rather than charging a flat fee for a logo, his
company instead focuses on the value they bring through their logo
design process. That means an evaluation of the risk that every company
takes when they take on a new logo. Do’s firm will tackle strategizing
(to help avoid the company having to revert to an old logo). It will
handle the design of the logo. It will handle the implementation of the
logo into new applications, such as a business card. And they’ll charge
for that service as well.</p>
<p>One thing is clear: Chris Do’s model of value-based pricing is
focused on what the client gets. And once you understand the value you
can bring to the table in making life easier on your clients, you can
often charge much more than you ever thought possible.</p>
<p>To get a sense of your value in the marketplace, start asking
questions. What results have you helped previous clients attain? Did you
create a website that generated millions in sales? Did you help them
with branding in such a way that they immediately saw a 10% increase in
every product that they sold? If there’s a number out there that you’ve
helped a client realize, you need to know it. It’s going to help
determine what you charge in the future.</p>
<p>Ok, before you start to consider all the different forms of pricing,
you first need to know what you need to make to sustain your chosen
path. Here’s how to do that:</p>
<p><strong>Step 1: How much money do you need to make?</strong></p>
<p>The first step to figuring out how to price is understanding the
difference between what you need to make and what you want to make.
What, are you shocked I didn’t simply say to just go out and research
what others are marking first? That’s definitely a step but it’s not the
most important step. You must first find out what you need to make based
on the reason you’re freelancing.</p>
<p>For example, let's say you need to make $50,000 a year to pay all of
your bills — rent, food, utilities, subscriptions, plus the ability to
save a little. Ok, cool. So you just need to make $50,000. Done. Well,
if $50,000 is what you need to make to cover costs of living, then you
actually need to make more around $75,000 - $80,000 to even out because
of a little thing called taxes. Depending on where you live, if you make
$80,000 a year, you might actually only bring in $50,000 after paying
your taxes which is exactly what you need to just get by.</p>
<p>Now that we know we need to make $80,000 of revenue (total money that
came through the business) in order to actually put $50,000 of profit
(what you actually make after taxes and things) so you can pay your
bills, then we need to start our pricing at what’s going to make us
$80,000 a year.</p>
<p>But wait, we know we go on at least one vacation a year, we like to
buy new colorways of Jordans, and are obsessed with the latest tech.
Well, now we’re getting into “what we want to make” territory, which is
good for figuring out what we’ll want to charge our clients.</p>
<p>Go check out your previous bank statements for the last 2 years and
see what you’ve been spending on all of your wants. Remember, we’re not
yet thinking of ways to cut back. We’re just seeing what we’d need to
earn to continue our current way of living. Let’s say it equals an extra
$10,000 a year. That puts us at $90,000 of revenue we need to make each
year to continue the same lifestyle.</p>
<p>Oh, shoot. Just like a lot of freelancers, we may have forgotten
about healthcare, dental, vision, etc. For some, this part is skipped.
For others, this part is incredibly necessary. For some not in the US,
you may have this part for free. But not everyone does, so let’s
estimate another $2,500 a year. Ok, so now let’s say around $93,000
(always round up when budgeting) is what we need to make. Check that
out. We started at $50,000 which might have sounded extremely doable but
in reality, we’re at $93,000 to continue the same way of living.</p>
<p>Now that we know what we need to make $83,000 but want to make at
least $93,000 for the extra wants throughout the year — now we can
research what other people are charging and see where we relate to
others in case we can scale that cost up or if we need to scale it
down.</p>
<p><strong>Step 2: How much are others making?</strong></p>
<p>This part actually can be tricky, for some reason this topic is taboo
in a lot of industries. You can definitely look at all these guides
online but my pricing and what I know others charge never actually ever
line up with these things, but they are at least good to look at for
reference.</p>
<p>The best thing you can do is reach out to someone and ask what they
charge privately. Someone you admire and look to be like. They won’t
always respond, but you’d be surprised how many will if you approach
them correctly.</p>
<p>Here are a few resources that might help:</p>
<ul>
<li><blockquote>
<p><a class="underline" href="https://FreelanceRateCalculator.com" target="_blank">FreelanceRateCalculator.com</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://Truested.com/yourrate" target="_blank">Truested.com/yourrate</a></p>
</blockquote></li>
<li><blockquote>
<p><a class="underline" href="https://FreeCodeCamp.org/news/youre-probably-calculating-freelance-rates-all-wrong-so-follow-this-strategy-instead-a5c738e51f70/" target="_blank">FreeCodeCamp.org/news/youre-probably-calculating-freelance-rates-all-wrong-so-follow-this-strategy-instead-a5c738e51f70/</a></p>
</blockquote></li>
</ul>
<p>Understand this: everyone is going to price differently and please
stop comparing your pricing to others in a harmful way. It’s a great
place to start – but come up with your own pricing and understand why
others are pricing what they are – maybe it’s their level of experience
or special skill they’re offering, like speed.</p>
<p>If you want to know how much I charge, it’s hard to pinpoint exact
numbers other than a simple $7,000 - $10,000 range per week. I treat
every project differently, so this can vary drastically. But this is my
base. Keep in mind I’m very experienced and I charge as much as small
agencies do. A lot of my friends charge around $2,000 - $4,000 per week.
Some are even better designers than I am. But I bring a lot more to the
table than just a design.</p>
<p><strong>Step 3: Trial and error</strong></p>
<p>Just try it. Come in hot with a high price range. See how clients
react. Come in with a low price range and see how they react. See how
you feel when you’re paid that amount? Is it exciting? Is it just, meh?
Does it make you feel like you still need to do a lot more work? You
really won’t know what you’re capable of charging until you try it.</p>
<p><strong>Step 4: How did you feel?</strong></p>
<p>Something else you never hear others talk about when sharing advice
is the fact that you need to understand and take notice of how you feel
once paid. Did you feel that you got paid your worth on that project?
Did getting paid that certain amount make you feel happy? Did you feel a
little uneasy or cheated in a way? These are feelings you’ll want to
think about and consider when you complete a project and get paid.
Understanding the feeling can help you adjust your pricing for future
projects.</p>
<p>Before we dig into more details about the various ways you can charge
a client for the work, please understand there are multiple ways to
charge clients and it’s perfectly ok if you choose one or if you mix it
up every time. I will not go over every way but just some of the main
ones you might have heard of. Just remember, there’s no one way. I even
switch it up frequently as every client and every project is
different.</p>

<h5 id="consider-project-based-pricing" v-view="(e) => viewHandler(e, 'consider-project-based-pricing')" class="section"><strong>Consider project-based
pricing.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'be2194c1600f4bef959a0acb69bdb464'" :title="`Consider project-based
pricing`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695145894' || v.videoId === 'be2194c1600f4bef959a0acb69bdb464')" />

<p>Probably the most common way to price a project is project base or
also known as a flat fee. This just means that when a client comes to
you with a project in mind, you can give them a set number that you’ll
do the project for. You won’t get paid less and you won’t get paid more.
It’s an agreed amount of money for an agreed amount of work. I use this
form of pricing very often. If it’s not a weekly fee, it’s project-based
for me.</p>
<p>For example, let’s say Sam is going to do a photoshoot for Uber
that’s one day long and he will deliver a minimum of 20 hi-res photos.
It’s clear it's a one-day shoot and the deliverables are 20 images. With
that information, Sam can confidently charge $10,000 for the one day
plus the deliverables. (We won’t get into licensing fees with this
example.) Easy. Pretty black and white, right?</p>
<p>Well, as basic as this is, this pricing method can actually break
down pretty fast. In a perfect world, that’s how it would operate--Sam
did the shoot in one day and delivered the 20 images and the client sent
$10,000. But what if that shoot turned into two days? What if the client
ended up wanting 30 images? Ok great, I’ll just charge more money -
easy.</p>
<p>Well, yes, it can still be easy if you had clear communication with
the client from the start (which is always key), but that’s not always
the case. Any additional work outside of what was originally agreed on
that a client tries to sneak in must be charged for. This is called
scope creep and happens more than you’d think. It happens mostly because
clients don’t understand how our services work, and that’s perfectly ok.
It’s an opportunity to educate. It’s not the client’s fault. It’s often
the freelancer’s fault for not speaking up and communicating
properly.</p>
<p>If you do this method of pricing, be sure to consider client feedback
and have a strict timeline. Offer options for an increased scope. For
example: after 30 days, you go into hourly pricing or weekly pricing, or
in general, if it comes to it, just renegotiate the contract before you
continue the extra work. Most good clients will understand so do not be
afraid to bring up additional costs for additional work after there’s an
agreed scope of work.</p>
<p>For the record, again I do project base pricing all the time. It’s
my go-to when I’m doing a very large project where weekly would be too
costly and I can offer great deals to my clients for wanting to pay me
so much money.</p>
<p>As an example, if I was going to redesign Patagonia.com and knew it
would take me 4-6 weeks, at $10,000 a week that’s $40,000-$60,000 which
can be a lot of money for some clients. In cases like this, depending on
our relationship, I may offer to do this all for an even $45,000. This
“discount” offer works great when sending the client some options to
consider. They love deals. Besides, I know there are plenty of designers
out there, some even better than I am, that would do this for way less
so I’d be stoked to land that kind of deal. But again, I bring a lot
more to the table than just some design. Discover the edge you have that
other freelancers in your field don’t and find ways to upcharge for
it.</p>
<p>For further reference, most web design projects for me are always
around $20,000 - $100,000 with a solid average of around $40,000. Those
projects usually only last 1-2 months.</p>

<h5 id="charge-weekly-my-favorite" v-view="(e) => viewHandler(e, 'charge-weekly-my-favorite')" class="section"><strong>Charge weekly (my
favorite).</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'c732c906d45c430fb2b846b1e1d41d48'" :title="`Charge weekly`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695141205' || v.videoId === 'c732c906d45c430fb2b846b1e1d41d48')"/>

<p>The weekly rate makes me so happy. It’s by far my favorite option and
I typically always include it when letting the clients know what I’d
charge for specific projects.</p>
<p>Weekly basically means you’ll pick a number, let’s say $2000, and
every week that’s what you charge the client for the work. If the
project goes 3 weeks, that’s $6,000. I even set up a clause sometimes
that if it goes into that Wednesday, that counts as a week so let’s up
that to $8,000 for 3.5 weeks of work.</p>
<p>The beauty of this is that everyone knows what’s happening and
getting charged. My favorite part is that it also incentives the client
to get on with the feedback and not keep me waiting, because another
clause I include is you’re going to pay me while I wait for your
feedback. I do this because you can’t expect me to wait for 2 weeks and
still be available to jam when you’re ready. That’s not how it works.
With current relationships, I likely won’t do that but I do it a lot
with people I don’t know. Get paid to wait. It’s magic.</p>
<p>Another reason this is my favorite is that as a conceptual designer,
I could do multiple projects a week. Most of my work was quick
turn-around concepts. Usually no longer than 2-3 weeks and I was fast
enough and efficient enough at my job I could do 2-3 of those projects
at once, letting them overlap. Instead of making $2000 a week, you’re
now making $6,000 a week. The caveat to this is that you have to
actually be good and quick. You also need to communicate with the client
that you are working on multiple projects at once. Always be upfront. I
always treated my freelance business as if I was an agency that works
with multiple people and I made it clear that’s what I did. You can’t do
it a lot and I wouldn’t recommend trying to always do it...you will get
burnt out and you’re work will suffer. But if you’re on a roll, you’re
on a roll. Just be honest about that roll. Don’t hide it in your
pocket.</p>

<h5 id="value-based-pricing" v-view="(e) => viewHandler(e, 'value-based-pricing')" class="section"><strong>Value-based pricing.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'a626ce9e5dd247639fedc6a7ec44a0c9'" :title="`Value-based pricing`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695146323' || v.videoId === 'a626ce9e5dd247639fedc6a7ec44a0c9')"/>

<p>What’s the appropriate price for your services? Simple: whatever two
parties agree.</p>
<p>This can be difficult for some people — especially new freelancers —
to accept. If you’ve had a traditional career path to this point, you’re
used to researching “median” salaries. You’re used to employers who set
the budget for your salary. But as a freelancer, you’re free to charge
whatever you deem your work is worth. As long as you can find a client
who pays it enthusiastically, you call the shots.</p>
<p>Dan Mall, director of Superfriend.ly, says that when he started as a
freelancer, he was still earning money working for an agency. And he was
being paid very well. “I could be really bold in the choices I made as a
freelancer,” Mall said. “To be able to pitch outrageous rates or crazy
projects or wild ideas. And if the client said no, it wasn’t like I was
hurting for the cash. It was just a lesson to me.”</p>
<p>Mall essentially used his own personal leverage to find out the
marketplace’s value on his work. And that’s what you have to do. Notice
that Mall was willing to ask for what he wanted, even if he regarded it
as a bit outlandish. His leverage meant he could take those risks. And
it helped him create a value-based pricing system so he would only work
freelance when he wanted to.</p>
<p>You might be reading this as a new freelancer with few career options
and wondering how this could possibly apply to you. But remember: you
need to think in different terms now. If you were previously working at
an agency, then you were already working in a service-based business.
And oftentimes, those agencies take a high margin. They pay you a
reasonable salary, but then turn around and charge much higher rates to
their clients to handle overhead.</p>
<p>“The distance between what they’re billing you out at and what they
make is very far sometimes,” said Mall. If you’re working a salary of
$50/hour and the agency charges $250/hour, most of what the client is
paying for is not labor, but margin. As a freelancer, you have the right
to step in and claim that margin back for yourself.</p>
<p>The solution: experimentation. Find your value. You don’t have to
charge $250/hour off the bat, but you should be willing to see what the
market is willing to pay because it might surprise you. Again, put
yourself in the client’s shoes. If they have the option to hire an
agency for $250/hour or you for $90/hour, and they expect similar work,
who looks like a bargain?</p>
<p>It comes down to this: asking for what you want. You’ll never know
what you can charge until you do charge it. And once you find one client
who’s willing to pay you your dream rate—however you slice it—you’ll
find the confidence to start asking for it every time.</p>

<h5 id="charge-hourly-my-least-favorite" v-view="(e) => viewHandler(e, 'charge-hourly-my-least-favorite')" class="section"><strong>Charge hourly (My
least favorite).</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'79cc477d77da4657a40900e402001638'" :title="`Charge hourly`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695140758' || v.videoId === '79cc477d77da4657a40900e402001638')"/>
<p>Before I jump into this method or pricing I want to be very clear — I
hate it. I refuse to do it. But for context, I’m a web designer and my
projects usually take several weeks, so if I count my hours that could
take quite a bit of time. It also just...feels kind of messy.</p>
<p>Like I mentioned earlier, I worked at a few design agencies in my
career and all of them charged hourly, so I had to track my hours. I
hated it. I’ll go a whole day working your typical 9-5 but I’ll only put
down 6 hours at the end of that. The more I did that, the more I got in
trouble with the bosses.</p>
<p>“Dann, why just 6 hours?” Well, I went to lunch for an hour, and I
did other meetings and email and things and chatted with colleagues for
a bit. “Ok, you’ve got to work more.” Um...excuse me, what? Ahhh, so you
can charge them more and make more money, I see. <em>Ok, I’ll skip lunch
and everything else, just so I don’t get fired.</em></p>
<p>In the same scenario, at one place we didn’t have to add our
timesheets every day but every Friday, so our entire team would just
guess how much time we spent. We knew we couldn’t say 8 hours every day
and we knew if we said 6 hours, we’d get in trouble, so a week started
to look something like this: 7.5, 6.5, 5.75, 7.25, and we’d at least
have one 8 hour day in there. It never felt right.</p>
<p>The only way to become exactly hourly is to mentally know that every
30 minutes gets rounded. So, if you work 1.5 hours -- bill 2. If you
worked 45 mins, bill 1 hour. Just makes the math easier, sure. But guess
what? You’re lying.</p>
<p>Imagine this. You’ve got a 2-week project to design a homepage for a
website. You and the client agree to a $100 an hour fee. On Monday, you
work 8 hours. Tuesday, you work 6. Wednesday 10. Thursday 4. Friday 2.
That’s just 30 hours that you worked on this. So you let the client know
that. Suddenly they’re wondering why you didn’t work 40 hours because
that’s what we think of when we think of a <em>full week of work</em>.
What we see a lot in this scenario is the designer will add 10
mysterious hours to that, making it 40. If you know you’re going to just
say 40 hours, charge a weekly fee — there’s less of a chance you feel
inclined to lie about your hours</p>

<h5 id="stop-charging-hourly-whenif-you-can" v-view="(e) => viewHandler(e, 'stop-charging-hourly-whenif-you-can')" class="section"><strong>Stop charging
hourly (when/if you can).</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'481948894e85436080058db6852cb5de'" :title="`Stop charging
hourly`"  @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695146167' || v.videoId === '481948894e85436080058db6852cb5de')"/>
<p>When charging hourly, at some point you’ll be faced with a tough
decision. Should you charge for that one hour of driving? Of eating
lunch? You might have thought about work during that time or might not
have, but either way, you need to decide if you should charge for
that.</p>
<p>Let’s say during an 8-hour workday you were on Twitter for one of
those hours, are you honestly just going to charge for seven hours? Oh,
and you went to lunch, did y’all agree to charge for that hour too? If
not, you’re now at six hours that day. So you’ll either charge the six
hours or you’ll round up to the 8 because the client probably wanted you
to work eight hours each day on their project. You technically lied.
Remember, a relationship is all about trust.</p>
<p>Here’s another scenario. Let’s say you worked six hours one day but
drove to Target to get a few things and while driving and shopping you
thought about work the entire time. You thought about the designs you
were going to do, or how you were going to set up the video or photo
scene, whatever it may be – you were brainstorming in your mind while
doing something else. A lot of times, our best ideas come during times
like these. I’m sure we’ve all had brilliant ideas in the shower. Don’t
you think we should be charging for those key influential moments? I
definitely think so. But will you feel guilty for charging $100 to take
a shower or to run to Target?</p>
<p>Here’s one more scenario. I love to work and the few times I was
forced to charge hourly for a project, I always would have weird hours
like five hours on Tuesday, ten on Wednesday, zero on Thursday, ten on
Friday, two on Saturday, one on Sunday, etc. Even though I still
produced a typical 40 hours as expected, they weren’t happy when they’d
find out I didn’t work one day or worked less another day – not caring I
worked more hours on a different day. Some clients even got upset when I
needed to work “extra hours” and wouldn’t pay more than 40 hours a week
which is absurd.</p>
<p>I personally think when people ask you to charge hourly, they are
asking for the cheapest possible fee from you. I don’t necessarily think
there’s anything wrong with that – some budgets are tight, but it makes
for some really awkward decisions to be made. I’ve seen hourly fail over
and over again – it’s not healthy for many reasons. It’s all nickel and
diming on either parties’ ends.</p>

<h5 id="give-them-options" v-view="(e) => viewHandler(e, 'give-them-options')" class="section"><strong>Give them options.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'739d4bbc894342ac949555f737943c3c'" :title="`Give them options`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695142243' || v.videoId === '739d4bbc894342ac949555f737943c3c')"/>
<p>Instead of straight-up <em>No</em>s when a client “can’t afford you,”
offer ideas on how you would take the project for the $8,000 or even the
$5,000. Maybe instead of doing your usual two rounds of revisions, just
do one. Or instead of quoting three or four weeks, take your time and do
it over five or six weeks while you work on other projects.</p>
<p>Here’s an example email response template you can follow:</p>
<blockquote>
<p>“Hey! Great chatting today. I’m really excited to work on this
together. After some thought, here’s what we can do pricing-wise. Let me
know what works best for you.</p>
<p>Option 1: Since there’s a lot of pages that need to be designed, I’d
be more comfortable on the $8,000 per week side and I’m thinking we’d go
around 5-7 weeks depending on how quickly I can get feedback. I will be
charging $8,000 each week from start to finish. ($40,000 - $56,000 price
range for the project).</p>
<p>Option 2: If you need this done quicker than 7 weeks, I can ensure 3
weeks if we’ve got a strong feedback loop. We can have it done by “this”
date. I’d do this for $50K. There’s a chance we may even get it done in
3 weeks if we nail the design right away. (Set $50,000 for the
project).</p>
</blockquote>
<p>Ultimately, you never want to just turn down work without considering
other options. And even if it turns out they just can’t afford you at
all or even come close (instead of an $8K budget let’s say it was $2500,
you still don’t just say “NO” because you feel offended by a “low ball”
price. Again, that comes off as incredibly rude. It’s not that people
“don’t value your work.” They just don’t have the current budget – why
would that offend you? Don’t let it. Even the biggest of companies can
only get so much budget for certain projects throughout the year –
that’s just the way it works. It’s ok if you don’t take it, someone else
will.</p>

<h5 id="refer-a-friend-saying-no" v-view="(e) => viewHandler(e, 'refer-a-friend-saying-no')" class="section"><strong>Refer a friend &gt; saying
no.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'6b561eaeb5fa4e03babcb8e70c36af0c'" :title="`Refer a friend`"  @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695146132' || v.videoId === '6b561eaeb5fa4e03babcb8e70c36af0c')"/>
<p>Instead, offer up one or two other freelancers that might be at a
different level in their career than you so it stays in your network.
Remember, it’s all about relationships. Help others, maybe one day
they’ll help you. Also, you never know, that one “low ball” client could
come back when they raise their millions of dollar funding round or they
might have a friend with a larger budget and you want to still stay on
top of their mind.<br />
<br />
This is where so many freelancers fail. I chose the word fail because
they are usually the ones that end up back employed full-time when they
didn’t want to be. You can’t say “never be flexible in pricing” without
the proper context. Make your own decisions as to what’s best for you
and your current situation. If you never want to budge, even a penny,
don’t. Just understand the potential consequences. Having work is better
than no work. Making $8,000 this month instead of $10,000 is better than
making $0. Just understand your limits and be clear on your expectations
when negotiating prices.</p>

<h5 id="what-i-charge-for-web-design" v-view="(e) => viewHandler(e, 'what-i-charge-for-web-design')" class="section"><strong>What I charge for web
design.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'39a0b25570814ef39bbb91c463b31a66'" :title="`What I charge for web
design`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695140235' || v.videoId === '39a0b25570814ef39bbb91c463b31a66')"/>
<p>I’ll tell you what I charge for my services but it’s actually quite
difficult because every project and every client is different. When
someone reaches out to me, I tell them I’m generally $7,000 -10,000 a
week. I give a range because I want to capture a broader client base.
Sometimes I charge more, sometimes I charge less. But again, every
project is different in my eyes, and every client is different. So
having a range helps me set up the conversations upfront.</p>
<p>You need to understand that my last full-time salary was also around
$300,000/year. I’ve also been doing this for a very long time. But let’s
talk about why I charge as much as I do — which is more than most
designers that are even better than I am.</p>
<p>I sell clients on being a fast, highly creative designer that also
has a big network to help him find talent and grow. When they hear that,
they love to work together. A client's timeline is always ASAP and I
love getting work done quickly so I use this as a “feature” to sell a
client on hiring me.</p>

<h5 id="take-a-percentage-upfront-when-you-can" v-view="(e) => viewHandler(e, 'take-a-percentage-upfront-when-you-can')" class="section"><strong>Take a
percentage upfront when you can.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'6f1656b793c44bd9a085d2f916a2a31e'" :title="`Take a
percentage upfront when you can`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695145486' || v.videoId === '6f1656b793c44bd9a085d2f916a2a31e')"/>
<p>Requesting to take a percentage of the project total upfront is a
very common way to protect yourself from those clients that love to take
ideas and run or that actually don’t have the money upfront to pay you,
even though they may say they do.</p>
<p>When I do this, I typically ask for 50% upfront, especially if I
don’t know the client at all. Whenever I discuss the pricing with the
client, I always mention that I’ll ask for 25-50% upfront and see how
they react. If it’s a very large project fee, I typically will just do
the 25%. On smaller and quicker turn-around projects, I’ll do 50%
upfront.</p>
<p>Now, I don’t demand the % upfront, I ask them if they are cool with
it. “I typically take 25-50% upfront, are you cool with that?” I love
setting that up as a question because I want them to know I’m flexible
without flat-out saying it.</p>
<p>When a client refuses this, it’s a clear red flag to me and the
project won’t likely continue. But, even knowing that’s a possibility,
don’t be afraid to mention that you take a % upfront. It’s here to
protect you and any good client will understand this. Just please don’t
come across negatively saying things like “I can’t start until I get 50%
upfront.” Instead, phrase it like “I can’t wait to get started here.
I’ll jump on this right away. In the meantime, here’s an invoice for the
50% upfront payment. Let me know when you get it sent over. I should
have some ideas together by ____.” And from there, follow up in 2-3 days
asking about it if they’ve yet to send it over.</p>
<p>You’ll often hear never to start a project without taking a % up
front, which is great, but as with everything context is very important.
If you’ve worked with this client before and have a very close
relationship, then you don’t need to demand anything upfront before you
get started. When the time is right, ask for that % upfront.</p>
<p><strong>Tip:</strong> I always ask for a wire transfer over any other
types of payment because I can get that straight into my bank account on
the same day or max, in 2-3 days.</p>

<h5 id="consider-getting-creative-with-payments" v-view="(e) => viewHandler(e, 'consider-getting-creative-with-payments')" class="section"><strong>Consider
getting creative with payments.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'1fc68644a0ef4663b1107ae2d0e24a1e'" :title="`Consider
getting creative with payments`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695141757' || v.videoId === '1fc68644a0ef4663b1107ae2d0e24a1e')"/>
<p>One time a client was going to pay me $3000 for a quick and dirty
homepage design that needed to be done in five days. At the time, my
typical rate was $5000 a week. Dropping to $3000 was tough even though I
strongly believe in being flexible with pricing – at the time, I was
busy and just wasn’t willing to drop that much. With that said, I was
looking to get a new iMac for my office which was around $3500, and I
offered the client a proposal: <em>I’ll do the project if they purchase
me the iMac</em>. I mean, I was going to buy it anyway, so instead of
that coming out of my current budget (even though it could be written
off), I decided I’d rather save my current budget. I always knew that if
the client paid me $3000, I’d have to pay taxes on that. So instead of
making $2400 after taxes, I got a brand-new iMac worth $3500 for
basically 2-3 days of work and saved on taxes, rather than $3500 coming
out of my own pocket. Seemed like a major win/win.</p>
<p>That’s not the only time this has happened, either. In 2016 when I
was traveling filming freelance.tv I stopped in Austin to film some
friends there. Jonathan Coon knew I was in the area and asked me to stay
five days to help redesign Wikibuy. It was a hard decision because I was
traveling specifically to film. Staying in Austin would be another week
away from my family, so charging my typical $7-10K a week wasn’t going
to cut it, even though I loved working for Jonathan and Wikibuy.</p>
<p>I decided to get creative and offer Jonathan a proposal. I told him
that since I was filming, I noticed there were a few things I needed
that would help me make better films: a few lenses, a second camera,
etc. I gave him a list of items I’d like to have for the rest of the
film trip, which came out to around $15,000 and I said he could pick up
to $12,000. In true Jonathan form, he bought it all for me. Right at
$15,000 worth of gear for five days of work.</p>
<p><em>But Dann, you could have had $15,000 instead.</em></p>
<p>Nope. No, I couldn’t have. For one, I was going to buy this gear
anyways. But more importantly, I offered $12,000 worth of gear so I
would have potentially have gotten that, but take away taxes and it’s
more like $9,000. I needed $15,000 of gear. So with that $9,000 after
taxes, I still needed to come up with another $6,000 to finish my films.
I also knew what Wikibuy was, a product that helped you get new things
for the cheapest price, so he potentially got all this gear for even
cheaper.</p>
<p>Stuff like this doesn’t always work and I’ve noticed it works best
when you already have a working relationship with the client in some
form. But if there’s something you need or were going to buy anyway, why
not work for it instead?</p>

<h5 id="learn-how-to-invoice-accept-payment" v-view="(e) => viewHandler(e, 'learn-how-to-invoice-accept-payment')" class="section"><strong>Learn how to
invoice &amp; accept payment.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'8d8a8a35959c457783ca8f22de4abb2b'" :title="`Learn how to
invoice`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695144963' || v.videoId === '8d8a8a35959c457783ca8f22de4abb2b')"/>
<p>Almost every client you work for is going to ask for an invoice, so
you’ll need this — but don’t overthink it. Keep it simple. I’ve created
a simple invoice for you that you could try to use but if you use an
invoicing tool like Xero, Wave, Freshbooks, etc. then you likely have an
invoicing tool built in. Regardless of how you invoice, here are my key
things to think about:</p>
<p><strong>Ask for a wire.</strong></p>
<p>The cheapest and most direct way to get paid is to accept a wire
transfer. Some clients actually prefer things this way because it’s
easier on their end. They can track the wire transfers and the money
immediately comes off their books, which means they won’t be surprised
when you cash a check thirty days later.</p>
<p>From your end, a wire transfer is about as simple as it gets. The
money shows up in your bank account, ready to use. Just make sure you
draw it into a business checking account to keep it easy to track and
separate from personal checking.</p>
<p><strong>Accept credit card payments.</strong></p>
<p>Accepting credit card payments may sound like some financial
jiu-jitsu that you’d never want to learn how to do on your own.
Fortunately, you’re not the only one who’s thought of freelancing or
accepting payments before, so there are all sorts of solutions that make
it ridiculously easy.</p>
<p>The best way is to use invoicing software like the options listed
above. They’ll handle the credit card acceptance for you. They do charge
some light fees for this service (which is another point in favor of
accepting wire transfers directly from clients), but the fees generally
won’t shock you. Even so, the larger your payments get, the more
noticeable it is when a payment processor takes away those fees.</p>
<p><strong>Watch out for PayPal</strong></p>
<p>Do you need PayPal to function as a freelancer in the modern world?
You’d think the answer is yes, but I haven’t accepted PayPal for years
unless it was an absolute must for the client.</p>
<p>Why not? One quirk with PayPal is that they have security features in
place that are very consumer-friendly. That’s generally a good thing.
But from the client’s side, it also means that a sender can request
money back and have a reliable chance of receiving it without any extra
input from you. Someone took advantage of me in the past using PayPal,
so I started to grow wary of this feature. I once sold a car on eBay,
accepted $10,000 on PayPal, and thought the interaction was over. But
the buyer wrecked the car, claimed that items were missing from the car,
and got $4,000 back from the transfer on a false claim.</p>
<p>As they say in business: caveat emptor. Buyer beware. But in the case
of PayPal, I’d just issue a word of warning for the other side of the
transaction. Freelancer beware. You don’t have to avoid it completely,
but you should be aware of the advantages and disadvantages of every
payment platform.</p>
<p><strong>Consider accepting crypto.</strong></p>
<p>Cryptocurrency is growing increasingly popular, so you should expect
that there might be more future clients who ask to pay you in crypto. If
you want to be flexible as a freelancer, it doesn’t hurt to know how to
do so.</p>
<p>For starters, here’s something you need to know about crypto: it’s
taxed like any other income, and needs to be tracked like any other
income. If you accept crypto for a project, it’s a bit like accepting
stock options as part of your salary when working for a company: a
transaction you have to report. So make sure that the first time you do
accept crypto, you do so knowing your responsibilities as a business
owner.</p>
<p>Solutions like Coingate help you accept cryptocurrencies, sell them,
and then transfer that money to a business bank account. But remember
that as soon as you accept crypto, you have a taxable transaction on
your hands—it’s not simply once you sell the crypto.</p>
<p><strong>Consider not invoicing “before you start.”</strong></p>
<p>If you’re going by the book (well, not this book), then you’re
supposed to do something like charge 50% upfront and 50% when the work
is finished. And this is a smart way to approach it when you’re working
with a company for the first time.</p>
<p>Otherwise? I say don’t do it.</p>
<p>I’m a believer in just getting started. Let’s say a client reaches
out, we do the back and forth on pricing and project terms, and we agree
to get started Monday. When that happens, I’ll get started right away
trusting that the situation is going to play out well. As long as I
trust that client, then you’ll remember that my goal is simple: to be as
simple and easy to work with as possible. When I introduce an upfront
invoice, that means I’m adding an element of friction that doesn’t need
to be there.</p>
<p>I keep applying the filter of my ultimate goal: “Dann was so easy to
work with!” And because every relationship starts with trust, I like to
share with them that I trust them to pay me. In return, they can trust
me with their project and ideas. In my mind, starting with trust is the
number one way to do it.</p>
<p>That isn’t to say you should do this with everyone. If a company
comes out of nowhere, you’ve never heard of them, and you’re not sure
whether you trust them, then it may be a better idea to approach things
differently.</p>
<p>But consider the flip side. Let’s say you worked with someone at
Company A. They loved you and recommended you to Company B. Company B
approaches you with a great project, tells you that you came highly
recommended, and they’re so excited to work with you. In that situation,
are you really going to tell them, “Great! 50% down, please.” Of course
not. It shows that you’re not being flexible with the trust.</p>
<p>Your clients are busy. Don’t give them any extra homework.</p>
<p>Regardless of how you invoice, again, just keep it simple. You want
to be someone that’s known to be easy to work with and especially when
it comes to payment. I mean, don’t you want to be paid as fast as
possible? So, don’t make it difficult. Credit card and wires are
incredibly easy and usually instant.</p>

<h5 id="offer-discounts" v-view="(e) => viewHandler(e, 'offer-discounts')" class="section"><strong>Offer discounts.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'37a55f27fdd941e09fcf799d9d2b6878'" :title="`Offer discounts`" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '695141537' || v.videoId === '37a55f27fdd941e09fcf799d9d2b6878')" />
<p>Ever been part of a customer loyalty program? It feels nice to walk
into a place where they know you. I say: give that same gift to your
repeat customers. If a client works with you more than once, offer them
a discount.</p>
<p>This isn’t a hard and fast rule. There may be situations where it
isn’t appropriate to discount a large corporate conglomerate that would
much rather have a predictable budget than accept your discount. But for
those people who seem to know you on a more personal level, the
occasional, “Hey, I’m charging $18,500 instead of $20,000 for this
project” can be a nice demonstration of your appreciation.</p>
<p>Discounts also have the advantage of encouraging the behavior you
want. There’s a reason companies offer discounts—they want to steer a
customer toward something. If you offer bulk discounts because you value
the clients who offer you consistent work, for example, then maybe that
discount has a real monetary value for you, too. It comes down to your
preferences as a freelancer.</p>
<p>Just keep in mind that the reason behind it is to show appreciation
for a relationship, and not to try to manipulate a long-standing
relationship or squeeze more money out of them in the long run.</p>

<h5 id="double-your-income" v-view="(e) => viewHandler(e, 'double-your-income')" class="section"><strong>Double your income.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'d714b3d50db8461099eca3163e92d645'" :title="`Double your income`" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '700584018' || v.videoId === 'd714b3d50db8461099eca3163e92d645')"/>
<p>The best thing I ever did as a freelance web/app designer was moving
to a weekly or project base fee. This allowed me to think less in
smaller frame timing and more on a larger scale with a mindset of
getting the work done. Knowing what I’m capable of designing within then
allowed me to take on more projects at one time, in return, doubling my
income. Sometimes, tripling it.</p>
<p>I started to treat myself as an agency. Agencies work on multiple
projects at once and coming from an agency background myself, even the
designers there work on multiple projects at once – so why couldn’t I
as well? Well, nothing. I could and you can! For some reason, we’ve got
this mentality that we need to focus on one project at a time and give
it our all. That’s great. But, some of us are capable of more than that
and if that’s you then you just realized how to double, if not triple,
your income.</p>
<p>Here’s an example. A few months ago, I was designing a website for a
client where my weekly fee was $7500. This project ran for 6 weeks until
we dropped my fee down to $3000 a week once we got into more of the
production level of design and that ran for 4 more weeks. About 3 weeks
into this project, a client reached out needing an iPhone app design
with a quick turnaround (my bread and butter). Instead of saying that
client no and letting them go give that money to another designer, I
told them yes that I could fit it in because in the back of my head, I
know exactly how long it takes me to design the app they have in mind,
and I also know how long it takes me to design the other project that
I’m already on.</p>
<p>I did the iPhone app in 3 weeks for $8000 a week. During a 3-week
period, I was now making $15,500 per week, just more than double, and
each project got done perfectly and on time without me working any extra
hours or late nights. Basically, I managed my time better. Instead of
moving slower, taking my time, spending time on Twitter, playing online
games, or checking my stocks, I was honed in and focused.</p>
<p>Would I do this every month out of the year? Probably not. I’ve done
it before and it was awesome, but I forgot what the true beauty of
freelancing was about. It’s not always about the money. It’s also about
the freedom and flexibility you can get by freelancing. It’s the option
(sometimes) to be able to work less or take longer breaks. Don’t forget
that.</p>
<p>I’ve taken this to the extreme in the past to where I’ve worked on
three and even sometimes four projects at once. In 2016, I worked so
hard for the first six months in the year, just stacking project on top
of project, I made over $500,000 in six months that year (this was
before I had kids and, yes, I worked late nights). But I was exhausted.
Looking back, it wasn’t worth it, even though I did the best work of my
career in those 6 months. It was also all I did for 6 months straight. I
just wanted the money because my wife and I were trying to buy a
home.</p>
<p>Just because you can double up or triple up doesn’t mean you should.
Here’s the thing. Even by just doubling up on the projects, let’s say
the clients start to lag on feedback. Well, guess what? Now your
timeline is screwed up and suddenly deadlines start to overlap each
other. This brings on the onslaught of stress. No matter how many times
you say it upfront that the client’s feedback in a timely matter is
critical, something will come up and if you’ve got two or three projects
going at once it can get messy quickly.</p>
<p>I say that just to warn you to be careful. Take care of yourself,
your body, and your mind and remember freelancing isn’t just about the
money – it’s also about that extra freedom that sometimes comes with it.
If you ask me, that’s worth way more than any extra money.</p>
<p>Assuming I don’t need the extra money, of course.</p>
<h5 id="bonus-up-sale-the-client" v-view="(e) => viewHandler(e, 'bonus-up-sale-the-client')" class="section"><strong>Bonus: Up-sale the
client.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'f007a57390534673b27fa336995138a2'" :title="`Bonus: Up-sale the
client`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '700585300' || v.videoId === 'f007a57390534673b27fa336995138a2')"/>

<p>The concept behind the upsell is simple. When you have a client
willing to pay, you can get more money by offering them more in return.
Some statistics suggest that for companies who do B2C sales, it can
increase revenue up to 30% on average.</p>
<p>The goal, of course, is a quality relationship with your client.
Never upsell them on new services that they don’t need. They won’t keep
coming back to you if half of your communication is, “Hey, want to buy
some more work from me?” It can get exhausting saying no to someone like
that. And eventually, they’re going to turn to someone else who doesn’t
upsell them so hard.</p>
<p>But you can find low-friction ways to toss in new ideas. You might
include an upsell link as part of an invoice, for example. They’re free
to ignore it and pay you the agreed amount. They’re free to pick up that
option and pay you more. Either way, their life remains easy.</p>
<p>You might also toss out the option of an upsell during early
negotiations. As long as you take a low-pressure approach and offer it
in the context of how you can help them, you’ll find that many clients
are appreciative that you can do more. It means they don’t have to hire
two people. And they’ll reward that kind of attention by agreeing to add
to the scope of the project.</p>
<h5 id="stop-asking-whats-your-budget" v-view="(e) => viewHandler(e, 'stop-asking-whats-your-budget')" class="section"><strong>Stop asking, “What’s your
budget?”</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'5f9c47fbf3d6448bae0e8bc61d62b80e'" :title="`Stop asking, “What’s your
budget?`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '700585851' || v.videoId === '5f9c47fbf3d6448bae0e8bc61d62b80e')"/>
<p>The worst advice you’ve probably gotten is just asking the client
what their budget is. When you ask this question, you are doing two
things: 1.) seeing how much you can get out of them and 2.) sounding
like you don’t know what to charge. Most people give this advice because
you can get to the answer quickly of whether or not they can afford you
but the best approach is to always be just upfront, honest, and
confident in what you charge for your services and let them know your
cost. Then, let them determine whether or not they can afford you, if
they need to get more money, or let them try to counter. Otherwise, you
actually are wasting even more time. Most clients don’t know their
actually are wasting even more time. Most clients don’t know their
budget, they may have a range, but they likely don’t know what the going
rates are. When you have a steady stream of work. Don’t ask what the
budget is.</p>
<p>When freelancers do that, basically they are asking <em>how much I
can get out of you more than usual</em>? Instead, ask better questions
like how this will be distributed and then you can price accordingly.
Some clients do have larger budgets — research always plays into this,
so do your research and know when you can take that $5000 and request
$6500 without asking, “what’s your budget?”</p>
<p>Now, when you’re doing work for a favor, friends &amp; family, or
something you just want to do to enjoy — it’s okay to ask about the
budget and work within those constraints.</p>
<h5 id="avoid-these-common-mistakes-with-pricing" v-view="(e) => viewHandler(e, 'avoid-these-common-mistakes-with-pricing')" class="section"><strong>Avoid these
common mistakes with pricing.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'ecbea5769337472597db351bd807d233'" :title="`Avoid these
common mistakes with pricing`" @completed="completed" :played="videos && videos.length > 0 && videos.find((v) => v.videoId === '700582913' || v.videoId === 'ecbea5769337472597db351bd807d233')"/>

<p><strong>Not even trying to charge more.</strong></p>
<p>Dan Mall noted that he learned his value-based price in the
marketplace because he didn’t worry about clients who said no. This is
the power of asking questions. If you never even try to charge $10,000
for a project, how are you ever going to get there in the first place?
You might think that you scare clients off, and that may happen once or
twice. But for the most part, the worst answer you’ll hear is, “that’s
not in our budget right now.” Just as likely? The client will say,
“sounds good.”</p>
<p><strong>Turning away a client if they can’t fully afford your first
price offer.</strong></p>
<p>When you start to earn serious money as a freelancer, it can be
tempting to take on a “take it or leave it” mindset. After all, you
earned your way to the high rates you charge. But remember that this is
a business of relationships. There may be businesses out there who will
remember you for the discounts you offered them in their startup stage.
When they do have the budget to afford you, they may remember you and
your generosity. Don’t take this as carte blanche to always cut your
prices. But try to stay flexible with businesses who genuinely
appreciate you, but just don’t have the budget quite yet.</p>
<p><strong>Not offering options.</strong></p>
<p>No one likes to hear “take it or leave it.” That’s especially true if
there’s only one small obstacle in the way of you having a great
relationship with a company. If their accounting department demands that
you get set up on Bill.com or else they can’t pay you, it may be worth
looking into. Your relationship with that client can be worth far more
than the minor inconvenience of onboarding with their system. Be wary of
becoming so rigid that you start turning down great work with great
people.</p>
<p><strong>Being too greedy.</strong></p>
<p>Here’s where you’ll have to strike a balance. You should absolutely
experiment with your pricing to find out what you’re worth. But if you
start asking too much of clients, you’ll make it too easy to fire you
and simply hire someone else. While charging your full value is a great
thing, you don’t want to work so hard toward maximizing your rates that
you forget the benefits of working in reasonable arrangements with
long-term business relationships. Those businesses don’t exist to
provide you with money. No relationship is a river, with value only
flowing in one direction. To sustain long-term success as a freelancer,
you need interactions where the value flows both ways.</p>
<p><strong>Having only one price.</strong></p>
<p>Everyone should know their worth. That’s a no-brainer. But try to
evaluate every client situation for what it is. Maybe they’re an
up-and-coming brand that doesn’t quite have the budget for you yet, but
they’re willing to make a long-term commitment in exchange. That can be
worth shifting the price. Or maybe you’re working with a company that
doesn’t have the budget you want, but they can offer so much exposure
that you’re sure to get your name out there. You’ll have to make a
judgment call here, and having only one price can limit you far more
than it helps you.</p>

<h2 ref="chapter6" id="chapter-six-everything-portfolio" v-view="(e) => viewHandler(e, 'chapter-six-everything-portfolio')" class="chapter text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter Six:
Everything Portfolio</strong></h2>

<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'86cf8c222bdd4bb0aca16e4954b6a6e4'" :title="'Chapter 6'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697766583' || v.videoId === '86cf8c222bdd4bb0aca16e4954b6a6e4')"/>
<p class="text-black-caption text-base font-atlas-medium">Complete Chapter Video - 52:45</p>
</div>
<p>Your portfolio is incredibly important when it comes to your success
as a freelancer. It’s the ultimate way to house all of the work you’re
capable of doing for a future client. It’s so important, I wrote an
entire book about it called That Portfolio Book, which you can take a
look at for extra detail on portfolios.</p>
<p>But even if you don’t refer to That Portfolio Book, we’ll tackle the
highlights here for any freelancer in need of an successful
portfolio.</p>
<p>Keep in mind, if you’ve already read That Portfolio Book, this is going to be repeating some of those elements. Sometimes, word for word – I wrote it once, I’m not going to write it again, because I still agree with everything I said the first time. So, if you’ve read That Portfolio Book, feel free to skip over this, and if you haven’t read it yet, here is a great summary on what it takes to have a successful portfolio.</p>

<h5 id="what-is-a-portfolio" v-view="(e) => viewHandler(e, 'what-is-a-portfolio')" class="section"><strong>What is a portfolio?</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'480f2246dfdf493aa85c6ee86f9e1c43'" :title="'What is a portfolio?'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697765734' || v.videoId === '480f2246dfdf493aa85c6ee86f9e1c43')"/>
<p>I know what you’re thinking. <em>That’s a simple question. A
portfolio is a tool to showcase my work.</em></p>
<p>Well, you’re not wrong. It is in fact a tool to showcase your work.
However, that statement is missing a huge part of what makes a portfolio
a great one. For starters, a portfolio doesn’t just showcase any work,
it showcases your <em>best</em> work. It’s a curation. A curation that
showcases your best work—the work you want to be known for. A portfolio
is also a place to show who you are as an individual, whether a
designer, artist, photographer or whatever your creative profession is —
each of us brings our own unique perspective and individual voice to the
work we create. Our skills might be the same. Sometimes even our styles
might be the same, but nonetheless, when you peel back that top layer,
the base of work comes from our unique perspective: our story.</p>
<p>You see, a portfolio is a story about who you are as an individual.
Your interests. Your passions. Your skills. How you think, how you work
with others, what you can do, and what you want to do.</p>
<p>Just as importantly, a portfolio is a plan. Your story is a
never-ending one. You’ll always be adding to your portfolio and evolving
your skills, interests, and style. You’ll always be showing past work,
to get future work. You’ll constantly be growing, learning, adapting in
your career. So a portfolio is also showing your plan about where you
want to go next, the type of work you “plan” on creating, and what
you’re capable of doing in the future. You see, clients/companies are not
only looking at your portfolio to see what you’ve done in the past, but
they’re also coming to see what you’re capable of doing for them)in the
future — your plan.</p>
<p>Everyone’s use case for a portfolio is different. Some people are
looking for work and use it to get a new job. Some people are not
looking to get a job and just use their portfolio as a playground to
experiment and show off what they’re capable of. There’s no one right
way to have a portfolio. You might even show off your portfolio as an
actual book, it might be a PDF, but most likely these days it’s an
online portfolio, which is what we’ll mostly talk about in this book and course.
With that said, if your goal is to get a job, or your next project, there are a lot of things
you can do to increase those odds. That’s what this chapter is about —
how to show off your work to get more of it or to land that dream job, or dream client,
you’ve always been after.</p>
<p>We all know that the companies and clients hiring us are looking at
hundreds of portfolios at a time. How in the world are we going to stand
out? How can we get their attention in the flood of all the other
portfolios out there? Don’t worry, we’ll cover all of that in this
chapter. We’re going to go over how to think about your portfolio
through the lens of a <strong>tool, story, and plan</strong>. In return,
this will help your portfolio become unique, or what I like to say,
authentic.</p>
<p>So many portfolios look exactly the same. A lot of people can do the
same work you can. But what they can’t do is tell your unique, authentic
individual story. Only you can do that. We’re all different and no
matter our backgrounds, we’ll all approach our work from our own unique
perspective. Use that. Be different because we are different. Remember
this, people don’t hire work, people hire people. Use this as your
chance to stand out.</p>
<p>As you can see, a portfolio is much more than just a place to show
your work. We now understand a portfolio is generally three things: a
tool, a story, and a plan. Now, it’s time to dig in and find out how to make your portfolio a better
one.</p>

<h5 id="find-your-story" v-view="(e) => viewHandler(e, 'find-your-story')" class="section"><strong>Find your story.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'40551904ef2a458085e347ac4f98f55b'" :title="'Find your story'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697764141' || v.videoId === '40551904ef2a458085e347ac4f98f55b')"/>
<p>How do you find your story? Unless you have zero passions, it’s not
that complicated. Let’s use my story as an example and work back from
there. In short, I’m a freelance web designer and an iPhone app
designer. Even though I went to school for Graphic Design and have a
Bachelor’s of Art (which no one has ever asked about), I know that I
fell more in love with doing web design, even though I could do just
about everything related.</p>
<p>The power of designing and building something and publishing it
online for the world to see almost instantly? This is so exciting to me.
I’ve always been driven by the entrepreneurship bug that’s inside of me
and I found web design was a way to bring my business ideas to life. Now
38 years old, I started my first online business when I was 18 in
college — an online magazine for import car enthusiasts. I also noticed
quickly how much more you can charge for a great website as opposed to
what, at the time, I could charge for logos and brochures. Website
design quickly became my route to fit into my entrepreneurship plan.</p>
<p>I also know that I love outdoor adventure. Things like surfing,
snowboarding, hiking, and other things like that. I love sports, cars,
tech, and really…a lot of stuff. There’s little I don’t like or
appreciate but when it comes to my passion—it’s most definitely things
like surfing, snowboarding, and anything adventure-related. I can go on
and on about surfing which is why it makes this part of my story — what
I am passionate about — much easier to capture.</p>
<p>Let’s keep digging. What else am I passionate about that can help me
tell my story? Well, I also know that I love people. I enjoy making
connections and friends. It’s a powerful thing to me — one of the biggest
in life. I’ve been run over by people so many times in my past, taking
advantage of and hurt, so much so it helped me discover the power of a
genuine, true, vulnerable relationship. I’m an introvert, quiet, shy
human being and could be alone for days at a time in order to recharge,
but to me, life is about relationships and I believe it’s one of the
greatest gifts of all time.</p>
<p>Alright, now we’re getting too deep — that seems like a good place to stop
and summarize.</p>
<p>We know I like web design, outdoor adventure, and connecting people
through relationships. You’re likely starting to paint a picture of who
I am as an individual. That’s great! It’s in fact, exactly what I want
to show through my story on my portfolio: who am I, my passions, my
interests, and my skills? Whether I say this through how I write the
copy on my portfolio, through the content in my work, through the
pictures of myself, or just the overall design of my portfolio, my
goal is to tell that story at a glance.</p>
<p>The only question is <em>how</em>, right? We still need a little more
information as it’s hard to tell this type of story, without the plan
that goes with it.</p>

<h5 id="find-your-plan" v-view="(e) => viewHandler(e, 'find-your-plan')" class="section"><strong>Find your plan.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'350d6a24dbbd49c281db769b63567c4a'" :title="'Find your plan'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697763044' || v.videoId === '350d6a24dbbd49c281db769b63567c4a')"/>
<p>Okay, now that we know the story that I’m trying to tell in my
portfolio, I need a plan. I need to ask myself these questions: What’s
the goal of my portfolio? What am I trying to achieve with it? Who am I
creating it for? There are no wrong answers here. But they are all
important questions to ask. Hopefully, by knowing my story, you can tell
that my plan is to get more web design work for adventure-related
companies or interesting new start-ups.</p>
<p>Since that is indeed my plan, I add that to the type of work in my portfolio
and make it more of the focus. It’s as simple as that. Have a plan of
where you want to go, so you know what type of work to show. (Nice
little rhyme there for you.) If you know that you want to do web design
work for car companies or tech companies, don’t show brochure work for
pharmaceuticals or logo concepts for unrelated companies — it’s not in
your plan.</p>
<p>Using myself as an example again, we know my story; I like outdoor
adventure and relationships and want to do web design, but what do I plan
to do with this skill? What type of work do I plan to get? What are my
ideal clients and what categories do they fall into?</p>
<p>Now that we know some of my story, the plan starts to become a little
more obvious. I plan to do web design for adventure clients/companies
like Nike, Burton, Quiksilver, Oakley, Nixon, Red Bull, Patagonia, or
maybe companies like Tesla, Toyota, Jeep, or companies that connect
people like Twitter, Facebook, and Google.</p>
<p>Does that mean I can only work for those types of companies? No. Not
at all. I absolutely should be showing I’m capable of designing across
other subjects. For example, I’d show my Nixon.com redesign, or work I
did for Billabong, Red Bull, The North Face, Patagonia, Columbia, Mini
Cooper, as those are my passions but also mixed in is work I did for
Wikibuy, Google, Airbnb, etc. I’ll also show the stuff I did for Holler,
a social networking app. I’d even show the side project turned company,
Epicurrence, which is a “non” conference for creative professionals
where we go surfing, snowboarding, adventuring for a week, and talk shop
at night. I created a whole brand around that. I likely won’t show my
random start-up clients unless I want more work like that. I might show
one, maybe two, so I don’t come across as “unaffordable.” Ironically,
some of my least-paid work was for these bigger companies and some of my
highest-paid projects were for start-ups that no one has ever heard of.
That’s not a diss on larger companies. But don’t think that just because
someone worked for Google, they made bank. All projects, scopes, and
teams have different budgets. As an example, in one of my projects for
Google, I came in as a freelancer to help come up with additional
solutions for the team to share internally. It was one of those, you can
make $6000 this week, or you can make $0 this week. <em>Hmm, I’ll take
the $6,000, thank you.</em></p>
<p>Now you’re probably thinking, <em>what if I don’t have any work to
show?</em> We’ll dig more into this a little later, but for now, if you
don’t have any work to show at all, I’ve got the simplest solution for
you. Are you ready for this?</p>
<p>Here it is — just go create it. Yup, it’s that simple. You might be
thinking, “that’ll take forever.” Come on. This is something you don’t
want to make excuses for. You want to get hired, right? Well, then you
need something to show. People will often tell you never to do unpaid
work. Sadly, so many people take that to heart, and speaking of hearts,
it breaks mine when I see people follow that advice without question.
People hear that and in return, they won’t even practice their craft
unless money is involved. They’ll put up a portfolio of some simple
things that maybe took a few hours and then just wait and hope something
happens. That’s not how it works. If you want someone to invest in you,
you first need to invest in yourself. Go create. Go practice. Do things
to enhance your skills and use your practice as portfolio work.</p>
<p>To quickly get back on topic, by seeing all of what I previously
mentioned, you start to paint a picture of my story and my plan. I’m a
web designer, sometimes an app designer, that loves adventure, people,
and start-ups. I like big typography and big images in my designs.
That’s my go-to style. I want to work for companies like Burton and
Google, etc. These are the things I’m passionate about and the things
that I’m good at. And you 100% can clearly see that in my portfolio.
Having my portfolio set up this way helped me land my first job ever out
of school, which was helping redesign The North Face’s website, <a href="https://northface.com" target="_blank" class="underline">northface.com</a>. That’s
exactly what I wanted to do and at the time, was one of my favorite
brands. They had bought into my story and plan. But you still need to
decide one thing. How are you going to share your story and plan? And
what’s your key tool going to be to showcase it all?</p>

<h5 id="decide-which-tool-youll-use" v-view="(e) => viewHandler(e, 'decide-which-tool-youll-use')" class="section"><strong>Decide which tool you’ll
use.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'74752df495f8414ea999c51926421632'" :title="'Decide which tool you’ll use'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697764623' || v.videoId === '74752df495f8414ea999c51926421632')"/>
<p>How and where you share your portfolio is everything. If you’re a
photographer, using Instagram as some sort of portfolio is an excellent
choice of tool because that’s a great place to show visual work and to
get noticed. If you’re a web designer or some sort of graphic designer,
on top of Instagram, sites like Behance and Dribbble make a lot of sense
to use as tools. You’re going to want a place online to share your
story, to share your work. Having a good personal .com is also a great
choice of tool. Sometimes potential clients/companies might ask for a PDF,
in which case that makes a great tool.</p>
<p>Again, using myself as an example, we now know the story that I want
to tell and my plan for the future. Next, it’s time to figure out what
the tool is going to be to deliver this and to help me get discovered.
Of course, your first reaction might be to make a .com, in my case,
dannpetty.com, which is a great initial thought. I believe we all need a
personal .com and that it makes a great place to host your portfolio.
Having that helps to provide an environment in which we can better tell
our stories in any way we desire. However, you can’t only rely on that.
You’ve got to go where the clients are, where the views are. Again,
using myself and design as an example, we’ve got several places to
choose from, but 4 main ones stand out the most and you’ll hear them
frequently: Behance, Dribbble, Instagram, and of course, Twitter. That’s where
the clients are searching if they don’t have any leads or referrals.
Knowing that, if you solely rely on your .com, you’re forcing yourself
to be solely discovered only through something like Google search.
Unless you’re an SEO superstar, those odds aren’t so great. So it’s a
good idea to go where the clients/companies are looking as part of your
toolkit.</p>
<p>In the past, I used Behance.net to show off my work and spent a lot
of time there doing so. You can dig a lot further into the details of a
project there. Later, Dribbble.com, became my tools to show off my work. That’s where
the clients were. My personal .com just gave me more space to tell my story or
gave my referrals, an easy place to land whenever I got referred. That’s
another reason why a personal .com is so effective. Imagine if my
referrals sent people only to Behance or Dribbble, and were told to search “Dann
Petty” or they gave them my link behance.net/dannpetty – that’s awesome
until it isn’t. You see, with one click, they get a chance to search and
explore other designers. I’m not the best in the world at what I do. I’m
not the cheapest in the world at what I do. A company/client coming to
look for me can easily, with one click, go find someone else a little
better or a little cheaper. We can debate the good and bad of that, but
if my referrals could just say go check out dannpetty.com, my personal
.com, that’s going to help them focus on me. That’s what we want.
Granted they’ll still likely click/tap into my Behance or Dribbble from
there as it’s likely linked, but hopefully, my story landed with them
upfront, and they didn’t feel obligated to keep hunting.</p>
<p>It’s important to note that most of my work opportunities come from
referrals, but sites like Behance and Dribbble or Twitter do help me get discovered
by non-referrals. I use my personal .com to help sell my personal story.
For the longest time, I didn’t even have a personal .com, or if I did, it was just a landing page with a few links. All of my work
was through referrals — I wasn’t accepting any new inbound projects or
opportunities, so I didn’t think I needed a portfolio. I even slowed
down on showing some of my new work — boy, was that a mistake. One thing
I noticed, once you stop sharing, the work stops happening. You need to
stay on top of the mind of everyone out there. Let them know you’re
putting out work and didn’t decide to go into farming or something else
besides what you’ve portrayed your skills to be in that portfolio. Keep
that in mind if you’re actively looking for work, take advantage of the
different tools you can use as a portfolio and use them to help you get
noticed. Don’t worry about having thousands of followers and likes,
you’re not looking for those. You’re also not looking for thousands of
jobs and opportunities, just your next one.</p>
<p>Also, don’t let a custom .com portfolio slow you down. You do not need to build a custom portfolio. You can use all kinds of things out there, that’s why I highly recommend Dribbble, or Behance, and Instagram and Twitter – things like that. Just make something simple that works.
</p>
<p>Fair warning, if you’re a web designer and you custom design your website, it doesn’t matter what your work looks like inside of it, if you provide a not-so-great experience in your web design that you made yourself – for you, yourself, the client – you better believe that’s going to affect the image of your overall work. So it needs to be good. We don’t always need that extra pressure, just use what’s available. As long as your work and your story and your plan stands out.</p>

<h5 id="divide-your-portfolio-into-4-main-sections" v-view="(e) => viewHandler(e, 'divide-your-portfolio-into-4-main-sections')" class="section"><strong>Divide your
portfolio into 4 main sections.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'a67a931e44b94fd090fbdf5a411ef9a6'" :title="'Divide your portfolio into 4 main sections'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697746634' || v.videoId === 'a67a931e44b94fd090fbdf5a411ef9a6')"/>
<p>Much like the structure of a movie is in 3 acts: the set-up,
confrontation, and resolution; a portfolio has its own structure.
Instead of 3 acts, your portfolio needs at least 4 acts to completely
tell your story. To keep things simple, we’ll call them sections instead
of acts—so, 4 sections. Here they are:</p>
<p><strong>Section 1: The Set-up or Introduction</strong></p>
<p>The set-up, or introduction, tells us about yourself and the
skills and services you offer. It’s setting up the story you’re trying to
tell overall with your portfolio. Knowing you’ve got 5-10 seconds to
grab someone’s attention and entice them to dig further into your
portfolio, having a strong introduction to your story will lead to
further exploration of your portfolio. If your portfolio is one simple
page, you’ll want this to be everything that’s most likely in view with
0-1 scrolls. Having your introduction behind a long loader or being told
with too many scrolls is basically the same as walking up to someone
who’s in hurry and saying “Hi, my name is…wait a second let me find my
business card…Dann and I’m a…wait a second let me get out my
computer…I'm a web designer…check out some of my…wait a second let me find
it…work.” Makes no sense! That person would lose interest in chatting
with you. Your introduction is key to further engagement in your
portfolio. That’s why it’s also crucial to tease your plan (your work)
in the introduction as well. There are two parts to that: you tease it
in your tagline “A freelance web and iPhone app designer”, along with
some supporting text, and then you tease either your most recent work or
your top selected projects. Just make sure the set-up or introduction is
within, at most, one scroll.</p>
<p><strong>Section 2: The plan</strong></p>
<p>The plan is where you show your work and the type of work you plan on
getting in the future. If you show websites you’ve designed, count on
getting web design work. If you show photography, plan on getting
photography work.</p>
<p><strong>Section 3: The Summary</strong></p>
<p>The summary is often shown as the “about” page, or the “my story”
page. It’s basically a further deep dive into the setup or
introduction. This is where you talk about things like where you’re
located, your passions, your goals, why you do what you do, past
companies you’ve worked for, any accolades you may have, and so on. It’s
a summary of your story and plan.</p>
<p><strong>Section 4: The Connection</strong></p>
<p>The connection is the section where you show your links for others to
contact you; like your email and potentially your phone number and also
a section that links out places to learn more about you as a person,
like Twitter, Instagram, etc. This is where you’d also link out to your
social network portfolios like Dribbble and Behance. Whatever you do,
just make sure you have an incredibly clear way to contact you and to
learn more about you. If that means a contact form, by all means, make
one. But fair warning, those are trickier than you might think. The rule
of thumb, however, is simple. <em>Make it as easy as possible to email
you</em>. Don’t worry — getting too many emails is a quality problem,
and much easier to solve when you have a successful freelance
business.</p>
<p>Now do all four sections have to be all on one page? Not at all. The
main page of your portfolio can just be your set-up or introduction.
Your plan section could be on your “Work” page. Just remember, if you do
that, please consider teasing some of your work upfront on your set-up
or introduction first. Your summary section could be an “About” page or
a “My Story” page. And lastly, you could
have your connection section on your “Contact” page. However, with that
said, again, I strongly believe it’s crucial to at least show or tease
some of your work upfront and to always have your email address in view.
I’m a believer in making my portfolio as easy as possible to convert
interest. That’s why my set-up or introduction has the majority of my
content on it. I know everyone will see my “home page” or “landing page”
on my portfolio website, but what I can’t guarantee is that they’ll
click/tap into the other pages. Consider the things you can control and
adjust accordingly.</p>

<h5 id="only-show-the-work-you-want-to-get" v-view="(e) => viewHandler(e, 'only-show-the-work-you-want-to-get')" class="section"><strong>Only show the work
you want to get.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'0e32e1a48390409f89123cb56cca27b9'" :title="'Only show the work you want to get'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '701715974' || v.videoId === '0e32e1a48390409f89123cb56cca27b9')"/>
<p>What’s your plan? What type of work do you want to get? This is one
of the most important questions that you’ll need to be asking yourself when
thinking about your portfolio. You’re going to hear it over and over
again—only show the type of work you want to get. If you don’t want to
design logos or brochures, don’t show them. It’s a simple, yet highly
effective philosophy to follow. Seriously, if you don’t take anything
else away from this book and course in this section on portfolios, please understand this one—if all you show is
web designs but you really want to do logos and branding, you’re only
going to get web design work. The same goes across the board for all
industries, even down to your style. If you really want to only do 3d
illustration work, but you frequently show black and white sharpie
illustrations, and you hate doing them, why on Earth are you showing
those? Even if it’s mixed in with all the other styles of illustration
you can do, if you don’t want to do that type of project or that style,
don’t show it! Just…don’t show it. Why promote work that you don’t want
to get? If you don’t want to work for automotive companies, don’t show
examples of automotive companies in your portfolio. Stop and take a
birds-eye view look at all your work. Think to yourself, “Which one of
these would I love to do again”. That’s the best way to curate a
portfolio.</p>
<p>I’m going to repeat myself now, but only because it’s worth
repeating.</p>
<p><em>Only show the type of work you want to get. Only show the type of work you want to get</em>.</p>
<p>Remember my story earlier on about how I set up my portfolio early on
with projects and content based on an outdoor adventure? That’s the type
of work I wanted to get. Something related to outdoor adventure so most,
not all, but most of my content for my work was within that industry.
Also, I knew without a doubt I wanted to do web design work and iPhone
design work. So, that’s all I showed, and guess what? That’s the type of
work I got hired for. I ended up working with companies like The North
Face, Columbia, Nixon, Billabong, Red Bull, and others. They could tell
that I wanted to do that type of work. If I only showed projects and
content related to cyber security or something like that, that’s most
likely the types of companies I would attract. It’s that simple.</p>
<p>Side note: If you’re only showing absolutely everything you can do
because all you’re looking for is more range, variety, or diversity,
consider changing that. Instead, partner with a friend or two that does
the type of work that you don’t want to do, but maybe you still want to
attract. Maybe even form a collective. When you hear of someone in need
of branding and you’re not good at that or don’t want to do that type of
work — refer your friend. Chances are, next time they hear of a project
for web design, they’ll refer you as well. But back to the point: don’t
show any work you don’t want to do.</p>

<h5 id="only-show-your-best-work" v-view="(e) => viewHandler(e, 'only-show-your-best-work')" class="section"><strong>Only show your best
work.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'20c4d9f1c39c4ff6a9a568daf3f2a575'" :title="'Only show your best work'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '701728506' || v.videoId === '20c4d9f1c39c4ff6a9a568daf3f2a575')"/>
<p>You’ll be judged by your worst project.</p>
<p>Remember: a portfolio isn’t just a showcase of your work, it’s a
curation of your best work and a plan for the type of work that you want
to get in the future. If you’re a designer, try picking 8-10 of your
favorite projects, and put those into your portfolio. If you’re a
photographer, show 15-20 of your best or if you’ve broken it down into
categories, show 8-12 in each category. The more you show, the more
likely someone is going to see a not-so-good project. They’ll judge you
by by that.</p>
<p>We want to be judged by our best work. The caveat to this is the
social network portfolios — the prior statement doesn’t fully apply
here. Show as much work as you like on networks like Instagram or
Dribbble or Twitter, because you need to post often to get noticed there. I
wouldn’t say the same for Behance, but again, the more you stay top of
mind there — the more you increase your chances of getting seen. All in
all, show your best work.</p>

<h5 id="show-that-youre-focused" v-view="(e) => viewHandler(e, 'show-that-youre-focused')" class="section"><strong>Show that you’re
focused.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'03f826e4cd91476f9eccf94e2c15d58d'" :title="'Show that you’re focused'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697754536' || v.videoId === '03f826e4cd91476f9eccf94e2c15d58d')"/>
<p>It sounds obvious, but, with that said, so many portfolios get this wrong. By
showing you’re focused is indeed a good way to stand out. Take into
consideration the roles you’re applying for and what your plan is. Is
your plan to do product design? If yes, then
solely focus on product design work in your portfolio. Don’t show all
your identity work, your illustration work, your brochures, your
photography, and everything else you can do. Yes, it’s ok to have that
in places but you want to be able to paint a very clear picture of what
you’re best at — and focused on. If you want to be a photographer for a
sports magazine, consider not showing your wedding photography and the
high school senior portraits you took for your local school. Show
sports/action photography. If it’s in your plan to design websites,
don’t show all your editorial work—show more of your web design work. Is
it ok to still show some of your editorial work? Of course. But, if
you’re not clear on what your focus is and what your plan is, it could
have a negative effect on landing the projects that you're truly after.
Since this is something that so many portfolios miss, not coming across
as focused, can keep you from standing out. Hone in and stay
focused.</p>
<p>A phrase you hear often when you ask for advice on your portfolio is
that you need to “show range” or “more variety.” But what exactly does
that mean? Most of the time, people are trying to tell you that you need
to show everything you can do. They tell you the more you show, the more
chances you’ve got to simply land something. Simply put, the more the better.
Unfortunately, that’s not 100% accurate. It’s actually closer to the
opposite of that, the fewer the better. If you’re considering showing
everything you can do or maybe you’re already doing it now, let me tell
you, there’s a better way to think about showing more range/variety.
Showing everything or too much of the wrong thing can have a negative
effect on your portfolio.</p>
<p>For example, you might be able to create logos, posters, brochures,
and websites. You take photos and make videos, and you do some creative
development in which you’ll get advice to show all of that. While there
is merit in that, and certain use cases where it works, for most of us
looking for work, that would be considered too much range/variety and
not enough focus. When you overshare, it confuses the client/company
looking to hire you on what you specialize in and the type of work
you’re looking for. Even if you have the greatest work out of anyone
else, if someone comes to your portfolio to hire you for web design work but
sees that only 20% of your portfolio is in web design, they’ll likely
find someone that is closer to 100% web design. It’s not always the
case. However, if the goal of your portfolio is to better your chances
at getting hired, let’s say as a web designer, then it’s a good idea to
show more focus on web design. You can show your range/variety within
that.</p>
<p>Let’s use me as an example. We know I’m a web designer. Even though I
can do just about everything related to design — editorial layouts, brand
identity, photography, videography — and a lot of other things, I just
want to do web design. Maybe even some iPhone app design here and there.
I’m not going to show all my editorial layouts and branding projects as
well. I don’t want to do them. So I’m not going to show them. If you’re
solely looking to add more range and variety to your portfolio to get hired
for more things and increase your chances of getting work, a good
alternative to that is showing a range/variety within your focus and
what you enjoy the most. When you hear “show more range/variety”—that’s
usually what people mean. Within your focus, show variations of
solutions and ideas—that’s a better way to show range/variety. The
companies hiring you don’t want to see everything little thing you can
do. They want to see what you’re focused on.</p>
<p>Now, in your “about” area of your portfolio, or the “summary”
section, you can of course list out all those other things you can do.
Sometimes that can be a big upsell to help get yourself hired. If you’re
worried about not being able to do everything that you can do, don’t
worry—a lot of times you’ll get the opportunity to do the other things
you enjoy. A start-up or small company in fact might
be looking for a designer that can do everything—it’s true. I’ve gotten
plenty of roles because I could design websites, animate them, and do
just about anything in Photoshop that could be done. So, on top of web
design and iPhone app design for these smaller companies, I’d also end
up creating billboards and ad campaigns as well—even branding. Did I
want to do all of that? Not really. I just wanted to design websites and
iPhone apps—that’s it. I also never wanted to design Android apps, so I
never showed I could. Even though I designed a massive one for Google and
a few well-known start-ups, I never showed that work because I didn’t
want to do it again. Would showing them help me get more work? Of
course, but I didn’t want that type of work. However, when someone asked
if the timing was right, I’d of course say yes to Android work and not
turn down a good paycheck if there wasn’t anything else available at the
time. That’s not a knock-on Android devices, I simply just don’t enjoy designing
Android apps.</p>
<p>Here’s another example, if you’re a web designer but you also sometimes like to do brand work, create a web design project and do the branding for that. You’re still showing that you’re a web designer, but you can also mention, “Oh, by the way, I did that branding.” On top of that, let’s say you like to do photography. Well, in that web design that you have, take the photos and put them in there. Now, in one focused project, you’re showing that you still love to do web design and that’s the type of work you want, but you’re also showing that, “Oh, hey, I can do branding too. Oh hey, I can do photography too. Check it out!” One step further, lets say you can build that website. Now, you’re a web designer – a clearly focused web designer, because that’s the project that you’re showing –  but you also do branding, photography, and development… When people ask, and there’s a chance to upsell, you just made more money.</p>
<p>You see, some skills are so closely related it can be said you’re
capable of doing them without having to say it. Like designing iPhone
apps and Android apps. Are they both different? Very much so. It’s
challenging knowing them both well—but I can do it. Someone needing a
new movie poster might like the editorial design work you’ve done and
ask you to do a poster mimicking that style. Can you do it? Of course!
These are reasons I believe style and execution on your best work can go
further than showing you can do everything.</p>
<p>You’d be surprised that just because I’m a “designer” people think I
can do everything, so I constantly get asked to do things I don’t enjoy
doing as much as websites and apps. I still get hired to do logo design
or even big brand identity systems. I’ve only really shown one logo I’ve
designed which happened to be the <strong>Hooli logo of Silicon Valley
on HBO.</strong> Regardless, companies and clients just assume it’s
something I can do as a designer. Luckily, I can do them and it’s a
great upsell use case for me, especially with start-ups. I don’t think
every designer can do branding work. Branding is a huge undertaking—but
I can do it and will as long as I’m getting paid properly in addition to
the web design work I’m hired for as well. Often, I’ll even bring it up
myself, “Since we’re redesigning everything, have you considered
updating the brand? I can do that too or get some of my friends to
help.” You’ll find the opportunities to show range and variety without
having to always show it all on your portfolio. When you think of the
range/variety you’re going to show in your portfolio, consider your
plan--it needs to fit into that. Part of my plan is to always do
everything I can do to make the company I’m working for look amazing.
When I see opportunities to help in other areas and to bring more people
on board, especially friends, I’m going to bring it up.</p>
<p>As an important side note, don’t get taken advantage of here. As a
creative professional, sometimes you’ll get asked to create many things
but still only get paid for one. More times than I like, I get requests
to do websites for new companies. They need branding and everything else
you might think that would come with it as well — even development of a
website. Yet they can still only offer to pay for my “web design”
service and not all of it.</p>
<p>What if you don’t know what you want to focus on? First off, that’s
ok. I’m over 15 years in and still change my mind often. There are so
many options out there and a lot of us can do multiple things.
Experiment. Start with what you enjoy doing the most and make that at
least 60% of your portfolio, so it’s a little more obvious you like doing
that more than the other skills you can offer. The larger portion you
show, the better the chances you’ll look more focused in that area,
which in return can help present yourself as an expert in that area. Put
your portfolio out there and see what people connect to. If people start
to lean towards your illustration work over your product design, given
you enjoy that type of work, consider focusing your range/variety there.
More importantly, what was in your plan that you came up with like we
went over earlier? That will help you decide what to show. It will also
quickly tell you if you’re showing too much range/variety in the wrong
areas.</p>

<h5 id="what-if-you-dont-have-any-work-for-your-portfolio" v-view="(e) => viewHandler(e, 'what-if-you-dont-have-any-work-for-your-portfolio')" class="section"><strong>What
if you don’t have any work for your portfolio?</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'48a1e9bc240d4d9b95f9aaa4754b003c'" :title="'What if you don’t have any work'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697760973' || v.videoId === '48a1e9bc240d4d9b95f9aaa4754b003c')"/>
<p>If you don’t have any work to show, maybe you’re just starting out or
fresh out of school. First off, that’s okay. We’ve all been there.
Everyone here had to start from somewhere.</p>
<p>The good news is, all you really need to do is <em>create</em> it. If
you don’t have any work to show, go create it. Don’t rush this process,
either. Take the proper time to find your story, discover your plan, and
create work in that direction.</p>
<p>Often, I’ll hear designers say they found an app they really liked
and recreated it in a day. First off, do you think the original
designers created that very same app in a day? Most likely not. Most
likely, in fact, it took weeks, if not months, if not <em>years</em> of
research, education, and trial and error to create. So what did you
really just create in a day? For you to just recreate it in a day,
visually, means you likely missed a lot of the learning opportunities.
When you do things like this, it’s an opportunity to grow. Try to figure
out why they created the navigation the way they did. What were the
driving factors behind those choices? Why did they make the sign-in
button on the top right as a button and not just text? Why did the
photographer light that image this way, instead of that way? These are
all things that take longer than a day to create. So, if you’re already
spending the time to create new work, get a little more out of it by not
just replicating someone else — but understanding why they made the
choices they made.</p>
<p>If you know you want to be a fashion photographer, take photos of
your friends. Buy them lunch or coffee or better yet, buy them the
clothes you’re having them model. Rent an Airbnb for a day or use your
own home or find some sweet urban background around town (if that’s your
vibe) and go take photos. You see, it’s not always about WHO you are
creating for. It’s about WHAT you are capable of creating. It's also
about WHY you’re creating it — because you’re passionate about it and
want to do more of it. The <em>who</em> you’re doing it for can come
later. But most importantly, don’t discredit yourself. If you’re doing
it just for yourself, that shows way more than someone who did nothing
at all, for anyone. Chances are, that’s all that the people hiring are
looking for — someone willing to learn.</p>
<p>If you’re a designer, make a fake company and design something for
that. Find a non-profit to do design work for. Find a developer friend
to trade services with. Ask your local coffee shop to trade some design
for a gift card or some free coffee — start somewhere. That’s the
key.</p>
<p>Another great example of creating work to show when you don’t have
any is taking advantage of challenges. I once hosted a design challenge
called #SPACEDchallenge where I gave a fake brief and asked for entries.
Through that, I received over a thousand incredible websites,
logos, and app designs for this fake company. At the end, I chose the
winners and featured them in a video. Three of the top 10 within a short
period later got full-time jobs making over $100,000 each, after getting
noticed through the challenge. They’re a great opportunity to take
advantage of. With that said, watch out for companies doing challenges
to create actual assets they want to use for profit. For example, if I
was a large company and instead of #SPACEDchallenge, something fake, I
did a challenge for a new logo for my company and mentioned I’d only pay
the winner — that’s incredibly wrong to do. Do not do those types of
challenges.</p>
<p>I like how Jenny Arden, VP of Digital Product Design at Nike, put it:</p>
<blockquote>
<p><em>“If I were to do things differently, I would definitely just take
on passion projects left and right, even if they pay very, very little.
In fact, I mentor quite a few people and give them that advice, that
don't be afraid to do unpaid work, though I don't encourage you ever to
give away your work. But if you're actually truly excited about
something and you want to design it just for fun, never hold back. You
never know. That same company that you may be mimicking or that industry
that you're really, really excited about that you just did on the side
may even hire you for that very work.”</em></p>
</blockquote>
<p>Remember nothing is truly for “free.” You might not have gotten paid
for the work in the form of money, and no one ever said to do work for
exposure (hopefully), but trade favors, trade services, gain
relationships. If it fits into your plan, take advantage of the
opportunity.</p>

<h5 id="make-it-easy-to-contact-you" v-view="(e) => viewHandler(e, 'make-it-easy-to-contact-you')" class="section"><strong>Make it easy to contact
you.</strong></h5>

<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'1b96b49278e7480e909ef13d2d9be1cd'" :title="'Make it easy to contact you'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697749165' || v.videoId === '1b96b49278e7480e909ef13d2d9be1cd')"/>
<p>Honestly, you don’t even need a whole section just for contacting
you. If you’re making it more complicated than that, don’t.</p>
<p>Just have your email address in the top corner and/or the footer,
clear as day — that’s my preferred method. Put it where they can’t miss
it. However, if you decide to have a page dedicated to connecting with
you, great. Just be aware of the small mistakes that could lead you to
far fewer conversions. For example, you’re probably thinking that you
want to make it as easy as possible for the potential client to message
you so….you need a contact form. Well, forms are great and all but unfortunately
most people don’t fully think them through. Leaving the most crucial
part of your portfolio rendered absolutely useless, losing work and
potential clients. Honestly, the easiest way and “best” thing to do is
to just let them email you. Have your email address there as a link, you
click/tap it and it opens their email, or if they’d prefer, being listed
out it’s super easy to select it, copy and paste it.</p>
<p>Ask yourself the question, “How do YOU feel when you fill out contact
forms?” Are you like me, and likely so many others, that feel when you
hit send on a contact form that the message just goes off into some
void? Think about that.</p>
<p><strong>If you are going to use a form, have proper input fields and
structure.</strong></p>
<p>Forms are not necessarily bad when done right. But that’s just the
problem, they seldom are. It’s so easy to make small mistakes that have
a large impact leading to less contracts or opportunities. Here’s how to
think about a form so you don’t fall into that category. Keep it as
simple as possible:</p>
<blockquote>
<p>Name:</p>
<p>Email:</p>
<p>Message:</p>
<p>(Allow for Attachment) (Don’t limit character count)</p>
<p>[Send]</p>
</blockquote>
<ul>
<li><blockquote>
<p>Confirmation notification or new page.</p>
</blockquote></li>
<li><blockquote>
<p>Confirmation email.</p>
</blockquote></li>
</ul>
<p>An alternative to this is below. However, some might even consider
this too much:</p>
<blockquote>
<p>First Name:</p>
<p>Last Name:</p>
<p>Company:</p>
<p>Email:</p>
<p>Message:</p>
<p>(Allow for Attachment) (Don’t limit character count)</p>
<p>[Send]</p>
</blockquote>
<ul>
<li><blockquote>
<p>Confirmation notification or new page.</p>
</blockquote></li>
<li><blockquote>
<p>Confirmation email.</p>
</blockquote></li>
</ul>
<p><strong>Confirmation is crucial for forms.</strong></p>
<p>The most important thing that you’re going to want to do when you
create a form on your contact page or the connection section of your
portfolio website? Ensure there's a way for people to know that the form
went through. This is incredibly important and unfortunately, it’s
something too many people forget.</p>
<p>On most contact forms, after you hit send, usually all you will see
is simple text saying “thanks” or a “sent,” and that’s it. Not only does
that lose all personalization there could possibly be, but it sends me,
the sender, in this void of unknown. Did the email send? Did it work? Do
they even check this email address anymore? I’m left with zero proof
that I’ve actually messaged you, other than the words “thanks” or “sent.” I have no follow-back in my outbox that
I have sent you something. Your email isn’t in my contacts and unless
it’s displayed on your site, I’ll never have it.</p>
<p>There are a few things to consider here.</p>
<p>When I hit “send” on a form, I’d like to get confirmation the message
was sent so I feel like I’ve actually contacted you. I’m not talking
about simply saying “Thanks” or “Sent” after they’ve hit the send
button. We just went over the downsides of that. First off, I highly
recommend saying more than just that. Say something like “Thank you for
the message. I will do my best to get back to you within 48 hours. In
the meantime, have you seen this video I made or recent post, or my most recent project
or this article I’ve written?” Give them some action to do, so that can
further their knowledge about you. Already, this sounds way more
personal than just “message sent.” However, even with this, my feeling
of confirmation still isn’t complete. That comes with this second
part.</p>
<p>Always automatically send a confirmation email to their email
address. Let’s repeat that. Always automatically send a confirmation
email to their email address. This part is incredibly important. In the
email back, say thank you again and something like telling them to let
you know if their message is super important or not, and if it is, to go
ahead and respond back to this in which I’ll respond even faster. You
don’t have to say that exact thing, but say something more than just. “I received your message.” Give them something more, that makes them feel
important.</p>
<p><strong>Stop the questionnaires!</strong></p>
<p>Questionnaires can be a major roadblock for someone trying to contact
you. Sometimes people just want to reach out really quick on their walk
back from a coffee or a short break, just to ask about your pricing and
availability. But they couldn’t, thanks to this massive wall in front of
them. In order to “filter out” your inbound messages, you decided to put
up a form that had 20 questions upfront, all marked *required. Who wants
to fill that stuff out? Ironically, some of the questions on these
questionnaires are the exact questions the client has and needs your
paid help in answering. For example:</p>
<blockquote>
<p>Questionnaire question: What’s your budget?</p>
<p>Client/Company: I don’t know. What do you charge?</p>
</blockquote>
<p>Problem: If you charge $5,000 for a photo shoot or a design, why do
you care what their budget is? They can either afford you or they can’t.
In most cases, it seems sneaky, as if you might be trying to squeeze
more money from them. Not that doing so is necessarily a bad thing,
especially if you’re charging too little and the project you’re being
asked to do is incredibly valuable to a company with a lot of money
already. You just need to be confident in what you charge. It’s not
wrong to ask what someone’s budget is, there are just better ways to do
it than a questionnaire – although I rarely recommend ever asking what their budget is. If you’re looking for value-based pricing, have
conversations around that—how could you fit that into a questionnaire?
You can’t really. A lot of times clients don’t even have a budget.
They’re simply reaching out trying to understand your costs, so they can
in return, form a budget and try to get it approved.</p>
<blockquote>
<p>Questionnaire question: What type of website do you need?</p>
<p>Client/Company: No idea, was hoping you could help.</p>
</blockquote>
<p>Or</p>
<blockquote>
<p>Questionnaire question: What’s your timeline?</p>
<p>Client/Company: ASAP. The answer is always ASAP. They’re reaching out
to see how long it would take you so they can form a proper
timeline.</p>
</blockquote>
<p>You see, it’s all about the conversation. Just let them email you —
it would go a lot further.</p>
<p>I know what you’re thinking. “but it helps me filter out the good and
bad” or “it helps save me time because I’m so busy.”</p>
<p>Trust me, I get it. But unfortunately, the only filtering a
questionnaire does is filtering out the people trying to hire you. Do
not make it so hard to contact you. Keep in mind, they might be trying
to contact 10 people today. Make sure you’re one of them. Let people
email you. Zero friction. Remember, your goal is to form a relationship
and it’s hard to do that via questionnaires. Clients/companies do not
want to take a test just to contact you. Plus, 9 times out of 10, they
have a .PDF or something that they want to send to you with all the
information, and you didn’t even allow for attachments.</p>

<h5 id="never-stop-adding-work" v-view="(e) => viewHandler(e, 'never-stop-adding-work')" class="section"><strong>Never stop adding
work.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'734a50a3bc244b94babf3d88bd394784'" :title="'Never stop adding work'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '697760624' || v.videoId === '734a50a3bc244b94babf3d88bd394784')"/>
<p>Try not to think of a portfolio as your masterpiece. It’s not
something you’ll work on for a few years, step back from the canvas like
a Renaissance painter, and say — <em>voila! I’m glad that’s done.</em>
This is probably counter to everything else you’ve heard about
portfolios. People might recommend that you think of them as your finest
work. Your works of art. Your very best stuff. And there’s some truth to
that. But if you think of it as a <em>finished product</em>, then you’re
thinking about portfolios the wrong way.</p>
<p>Your portfolio should look like something you’re actively working on.
If a client glances at the most recent work in the portfolio and sees
that it’s from three years ago, what does that say about what you’ve
been doing the past three years? They have no idea. Aren’t you getting
work? Aren’t you working on any worthwhile projects? There’s no way for
them to know. It’s up to you to continuously update your portfolio with
your latest and greatest work.</p>
<p>Remember: your portfolio isn’t just about what you’ve done, either.
It’s about the kind of work you want to get in the future. If you
continuously update it with your best attempts at the kind of work you
want to get, you’ll find that clients have a way of rewarding you with
more work just like that. Better yet, you may get a client or two who
checks out your portfolio and says, “can we have something just like
that?”</p>

<h2 ref="chapter7" id="chapter-7-the-not-so-fun-side-of-freelancing" v-view="(e) => viewHandler(e, 'chapter-7-the-not-so-fun-side-of-freelancing')" class="chapter text-black text-3xl sm:text-4xl font-atlas-medium"><strong>Chapter 7:
The Not-So-Fun Side of Freelancing</strong></h2>
<div v-if="!textMode" class="space-y-2">
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'4446d7752f5f4fe79e5198898137f072'" :title="'Chapter 7'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696860353' || v.videoId === '4446d7752f5f4fe79e5198898137f072')"/>
<p class="text-black-caption text-base font-atlas-medium">Complete Chapter Video - 26:36</p>
</div>
<p>If we ended the book or course there, you’d probably have enough to get
started. But there’s still a lot to learn that can shorten your learning
curve. In this chapter, we’ll dive into some of the unfortunate
realities that come with freelancing — even when you’re successful. I’ll
also explain some of the lessons I’ve learned over the years that can
help you shorten your learning curve.</p>

<h5 id="what-do-i-do-if-i-dont-get-work" v-view="(e) => viewHandler(e, 'what-do-i-do-if-i-dont-get-work')" class="section"><strong>What do I do if I don’t
get work?</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'92ecf49a53f248b393b58e38c2a7b4b2'" :title="'What do I do if I don’t get work?'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696761657' || v.videoId === '92ecf49a53f248b393b58e38c2a7b4b2')"/>
<p>It’s every freelancer’s fear: the well drying up. When you work for
an employer, it may be at the back of your mind that the company could
one day go under, or that you could get laid off during a recession. But
with those steady paychecks coming in, that always seems like a distant
reality.</p>
<p>Freelancing is different. If you don’t work this week, you don’t get
paid this week. It can be tough to deal with that at first. But
fortunately, there are some ways you can mitigate the “what if I don’t
get work?” worries.</p>
<p>Set your own milestones for determining when you’ll go full-time.</p>
<p>When I landed my own freelancing gig with National Geographic, I knew
I had proof of concept for this freelancing thing. I could say to
myself: “Okay, there’s something to this. Now I can go off on my own and
have some reasonable expectation of success.”</p>
<p>You should do the same. If you’re riddled with student loan debt and
jump head-first into the deep end of freelancing, of course, you’re
going to worry about not getting work.
But if you don’t quit your day job at first, you’ll have some
flexibility to build a steady supply of work before that.</p>
<p>Don’t take this to mean that you should be overly cautious, either.
The danger is that you rely so much on your old gig that you never dive
into the pool at all. If that happens, you’ll never figure out how much
freedom or income you could have attained if you’d started
freelancing.</p>
<p>Don’t give up.</p>
<p>When you do encounter a lull in business, the most important thing is
not to freak out. Don’t entertain thoughts of giving up just yet. For
starters, freelancing will always be a little bit feast-or-famine. Maybe
down the line, you’ll build such a steady roster of consistently paying
clients that it won’t seem like there’s much famine anymore. But as you
build your freelance career for the first time, it’s all too easy to get
“shook” by the famine times. Don’t let it shake you.</p>
<p>To help give you some hope, try to imagine what the most successful
freelancers would have done if they’d given up during the slow periods.
Where would they be now? What gems wouldn’t they have found because they
decided to plug back in to the nine-to-five? Don’t give up until you can
truly say you’ve given this freelancing thing an honest chance.</p>
<p>Lean into the uncertainty.</p>
<p>Some freelancers use a “famine” period to lean into their work even
more. They send out more pitches. They hit up more connections, asking
for work. Others shrivel away from the experience. And this is when you
have to decide: what kind of freelancer are you?</p>
<p>Freelancing isn’t for everybody. You’ll have to become comfortable
with a degree of uncertainty, even if you get consistent work. There are
great clients out there, but there is no magic “boss” who will guarantee
your income the way an employer might.</p>
<p>For natural freelancers, the uncertainty is natural, because they
view it as the natural price of their freedom and flexibility. But if
this period freaks you out and you find yourself incapable of leaning
into the uncertainty, you may figure you’re not cut out for it, and that’s okay. Even so,
try to lean into the uncertainty. Double your efforts toward finding
that next paying gig. If you don’t, you’ll never be able to say you gave
freelancing the honest attempt it deserves.</p>
<p>And after you do, if it’s still not a good fit, it’s okay if freelancing is not for you. Freelancing is not for everyone, and that’s okay.
</p>

<h5 id="what-do-i-do-if-a-client-refuses-to-pay-me" v-view="(e) => viewHandler(e, 'what-do-i-do-if-a-client-refuses-to-pay-me')" class="section"><strong>What do I do
if a client refuses to pay me?</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'6dd4b41ca83246b485e08059f3ed33cc'" :title="'What do I do if a client refuses to pay me?'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696759719' || v.videoId === '6dd4b41ca83246b485e08059f3ed33cc')"/>
<p>It hopefully won’t happen to you often, but if you encounter enough
people, you’re eventually going to run into a few duds. Some of those
duds won’t pay you on time. But before you freak out and send over the
lawyers, try to run through this checklist first.</p>
<ul>
<li><blockquote>
<p><strong>Nudge them first.</strong> You never know what’s going on with the client’s
side until you ask. Before you freak out, make sure that you reach out
and give them a chance to apologize and say, “Hey, I didn’t even see this
in my inbox; I’m sorry and I’ll pay this as soon as I can.” Don’t be
surprised if this one little step immediately solves 70-80% of your late
payment issues. It might not be a magic wand, but remember: people out
there are basically trustworthy. They’ll respond if you ask.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Work in milestones.</strong> One way you can establish trust with a new client
is to work in milestones on a project. For example, I often like to
charge weekly. If a client wanted me to keep working into next Tuesday
without paying me for what I’ve already delivered, I know that I can
wait until they’ve paid for the work before moving on. This lets me
concentrate on other things. Doing things this way means that if a
client ghosts you, you’re still minimizing your downside. You have
options. Once you’ve established a more trusting relationship with a
client, you can have some confidence that you don’t have to charge them
so often.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Give yourself a legal foundation.</strong> I’ve said earlier that I’m not a
big fan of creating complicated contracts for many gigs; I want the
client to view me as easy to work with. I want to show that I trust
them. But there’s a rule of thumb you can always stick to:
<em>get an agreement in writing.</em> This doesn’t have
to be complicated. You can even organize your email so that you can
refer back to the initial agreement as written. If there’s anything you
worry about, get it in writing. Get a lawyer to write up a contract and
send it out. Otherwise, many gigs are so straightforward that you can
often work from a simple invoice once the project is done.</p>
</blockquote></li>
</ul>

<h5 id="learn-how-to-handle-taxes-as-a-freelancer" v-view="(e) => viewHandler(e, 'learn-how-to-handle-taxes-as-a-freelancer')" class="section"><strong>Learn how to
handle taxes as a freelancer.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'de533c35855b4122bf5b8882c40ebd63'" :title="'Learn how to handle taxes as a freelancer'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696767117' || v.videoId === 'de533c35855b4122bf5b8882c40ebd63')"/>
<p>The most important thing to learn here is that you’re not only
thinking about yearly tax returns but quarterly estimated taxes. Get
used to this. Every quarter, both your federal and state government will
require that you send them an estimation of what you’ve earned. This can
get complicated, but don’t let it intimidate you into inaction.</p>
<p>Here are some keys to making sure you handle estimated taxes the
right way:</p>
<ul>
<li><blockquote>
<p><strong>Draw up a plan for withholding.</strong> You’re going to
experience a shift; rather than the government withholding taxes from a
paycheck, you’re now writing out checks to the government out of your
own accounts. This requires a little planning. Create a separate savings
account so you can deposit your estimated taxes as often as you like:
whether you choose to do that monthly or every time you receive a check,
that’s your call. But you do need a plan in place to have the money set
aside. Personally, for me, for the past few years I’ve had a CPA that did absolutely everything for me – book keeping, taxes, a lot of my invoicing – absolutely everything. I hired them to make it all go away, because I was tired of dealing with it. Someone handling this piece for me, was so important, I even gave them 2% of everything I made, on top of their normal payment for what they would charge, because I wanted this stuff handled. Handled so much I didn’t have to think abou it. Remember, we’re still doing the job itself, plus managing everything – managing our money, thinking about taxes – there is so much involved when it comes to freelancing. You are one person doing many, many jobs. It’s not easy, but you can do it. If I can do it – the most unorganized person in the world – if I can do it, you can do it.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Learn how to estimate your figures.</strong> The IRS has a
form for calculating estimated taxes. You can also take your books to a
qualified accountant and have them go through everything and come up
with a number. In general, the best advice is to outsource any tax work
to an accountant whenever possible. They’re not only more qualified for
doing it, but they’ll take that work off your plate so you can focus on
freelancing.</p>
</blockquote></li>
</ul>
<p>And speaking of hiring help: it’s going to seem strange to hire tax
help for hundreds of dollars or more. You’re paying someone to tell you
how much you have to pay. But the truth is, it will remove headaches and
give you peace of mind. If you want to be easy to work with, that means
not being a drag with clients because you spent all day dealing with tax
issues that an accountant could tackle for you.</p>
<p>I’ll never forget the first year I started full-time freelancing – just making so much money from these clients, from National Geographic, from Oakley, from all these people – never did quarterly taxes, never thought about my taxes. Like, “Oh, I’ll figure it out when the time comes.” Time comes, I ended up spending the majority of my money, and now realizing I owe the government… A LOT of money; the biggest check I’ve ever written in my life. That was a hard learning lesson, because I didn’t even have money to cover it. I had to do more freelancing just to cover the taxes. Don’t do what I did starting out. Think about your taxes, okay. Think about it. Make sure you think about that every time you get paid – “How much do I owe?” “How much am I going to owe?” – It will save you a lot of headache. A lot.</p>

<h5 id="avoid-these-common-mistakes-and-traps" v-view="(e) => viewHandler(e, 'avoid-these-common-mistakes-and-traps')" class="section"><strong>Avoid these
common mistakes and traps.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'ff0acbf2dc3144839ae996d6dd5a2252'" :title="'Avoid these common mistakes and traps'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696758042' || v.videoId === 'ff0acbf2dc3144839ae996d6dd5a2252')"/>
<p>Here’s the thing about mistakes: you won’t know to avoid them until
you’ve made a few yourself. That’s an argument for jumping directly into
freelancing as soon as you can. But you can also be smart about the way
you avoid mistakes—especially if you take the advice of people who have
already been doing it a while. Here are some key mistakes I’d tell you
to avoid:</p>
<ul>
<li><blockquote>
<p><strong>Mistake #1: Failure to plan.</strong> Remember when I told
you not to give up too early? Here’s how you can avoid that. Create a
benchmark for when you can go full-time, such as the first month you
earn more freelancing than you do at your regular job. When you plan
ahead, you won’t be “improvising.” You won’t be wondering when the right
moment is. If you don’t have
a plan in place, you’re going to find your options to be much more
ambiguous when the time comes, and that will only make you hesitate. Again, I’m not a planner. I don’t even own a calendar, I don’t even use one. You get one for free on your Mac and on Gmail, but I ain’t got a thing on there. But, you can plan without “planning,” okay? My plan was simple; I want to forever be a full-time freelancer. Until I start a company – or an agency, or a startup idea, or whatever – I know before that, during that, and the majority of my career, I just want the freedom of being a freelancer. So that was my plan. I ain’t going no where. I’m here. I am full-time freelancing for as many clients as I possibly can… while surfing… while snowboarding… while, now, spending time with my kids and family. That was my plan.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Mistake #2: Overbooking.</strong> Imagine you follow the tips
in this book and course. Clients are loving you and referring you to people they
know. The work is coming in non-stop. Sounds great, right?
Except you’ve got TOO much work on your plate now. Your days
start getting longer. You start cutting corners to get projects done.
And guess what? You’re officially spread too thin. When you spread
yourself too thin, you’re 1) stressed, 2) not able to deliver
outstanding work for each client, and 3) possibly working harder than
you ever did at the nine-to-five... defeating the point, for some people. </p>
</blockquote></li>
</ul>

<h5 id="learn-to-deal-with-imposter-syndrome" v-view="(e) => viewHandler(e, 'learn-to-deal-with-imposter-syndrome')" class="section"><strong>Learn to deal
with imposter syndrome.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'32180a1187bc4daea85161b4e8cf2f2e'" :title="'Learn to deal with imposter syndrome'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696764082' || v.videoId === '32180a1187bc4daea85161b4e8cf2f2e')"/>
<p>Productivity expert James Clear has a nice quote on imposter
syndrome: “anything new is accomplished by unqualified people.” Since
you pick up fresh gigs all the time as a freelancer, you’re going to
have to learn how to deal with imposter syndrome. It’s part of the lay
of the land.</p>
<p>The question is, what do you do? I like to go back to the roots of
imposter syndrome and figure out why it’s there in the first place. And
the root causes aren’t always what you might think.</p>
<ul>
<li><blockquote>
<p><strong>Perfectionism</strong>. According to <a class="underline"
href="https://www.psychologytoday.com/us/basics/imposter-syndrome"><u>Psychology
Today</u></a>, perfectionism often leads to imposter syndrome. That
might seem counterintuitive. So what’s the problem with perfectionism?
If you don’t think you can do something perfectly, you’ll think you
don’t belong in the big pond. But rather than saying “I have to get this
perfect the first time,” try to bring a grittier approach. “I might not
get it perfect the first time, but I belong here because I have what it
takes to get it right eventually.”</p>
</blockquote></li>
<li><blockquote>
<p><strong>Narcissism</strong>. Another strange one. Are people with
imposter syndrome really narcissists? Psychology Today says that lots of
high achievers (the ones who shouldn’t feel like imposters) struggle
with imposter syndrome. The problem with imposter syndrome is that you
have to realize you’re not the only person who feels this way. You’re
not the only person in the world with doubts. Just as you don’t show
those doubts to other people, neither do they. Understand that imposter
syndrome is part of growth. It often comes from feeling like you must be
the only one with your feelings. You’re not. It’s natural. Accept that
and move forward.</p>
</blockquote></li>
<li><blockquote>
<p><strong>Cultural attitudes.</strong> Want to know when science first
discovered “imposter syndrome”? It was in the late 1970s, just as liberated
women were entering the workforce in greater numbers. These women were
dealing with a lingering cultural imprint that their place wasn’t in the
workforce. But when you realize that that’s all it is—an imprint and not
reality—it can help you understand that you’re only experiencing these
feelings because of cultural attitudes. And you’re under no obligation
to go along with it.</p>
</blockquote></li>
</ul>

<p>I have been doing this for a very, very long time; almost half my life. I still have imposter syndrome, all the time. When a client reaches out to me, “Wait, why does that client want to work with me? I’m just nobody. I’m a smalltown kid, I graduated from high school with like 50 other people. Why me? There are plenty other better designers out there than me. Plus, I’m a little expensive; you can find cheaper ones that, again, are better than me. Why me?”</p>
<p>Anytime I make videos or tweets – or even making this book and course like I am right now – I have imposter syndrome. Like, “Why should you listen to me? Sure, I’ve been doing this for a long time, but just been doing it my way. I don’t even know if my way is the right way, or wrong way, or whatever way, it’s just the way.”</p>
<p>So even now, in this very moment I’m in that you’re listening to me and I’m sitting here talking to you, I have imposter syndrome… But you know what? I keep going anyways. I’m about to read the next line and everything going to be good, because I know I can do the work that that client reached out with. I know that I can give you advice that will help you in your freelancing career. I can. So can you.</p>
<p>If you have imposter syndrome right now, you can get over it, you can move on from it, because you are capable of doing great things. You are.</p>

<h5 id="learn-these-10-secrets-for-freelance-success" v-view="(e) => viewHandler(e, 'learn-these-10-secrets-for-freelance-success')" class="section"><strong>Learn
these 10 secrets for freelance success.</strong></h5>
<ChapterVideo v-if="!textMode"  :show="false" :floating="false" :videoID="'0e021abd41ed41fe9265c55ee1260ae2'" :title="'Learn these 10 secrets for freelance success'" @completed="completed"
:played="videos && videos.length > 0 && videos.find((v) => v.videoId === '696753415' || v.videoId === '0e021abd41ed41fe9265c55ee1260ae2')"/>
<p>This is ultimately a book and course about not just starting freelancing. It’s
about winning at freelancing. But what are the points you should take
away from this book and course? What are some concepts you can pin up on your wall
and put to use immediately? I’ll tell you what I’ve learned from my own
experience, and in talking with others who have done the same.</p>
<ol type="1">
<li><blockquote>
<p><strong>Let them know you exist.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>Freelancing is ultimately a profession of self-promoting. Get used to
that fact. This is similar to the principle of asking for what you want:
you have to get comfortable with putting your name out there. As long as
you add value to the conversation, people are far likelier to respond
with “who is this person, and why haven’t I heard of them before?”
rather than “stop promoting yourself.”</p>
</blockquote>
<ol start="2" type="1">
<li><blockquote>
<p><strong>Always be available.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>I expect some freelancers will push back on this one, and that’s
fine. I’ll tell you what works for me. I often text with my clients
directly because it creates an immediate bond that feels more like
friends than colleagues. If you’re wondering how I established long-term
clients and got great referrals, it’s this attitude alone. I’m there for
my clients like an old friend they know from college.</p>
</blockquote>
<ol start="3" type="1">
<li><blockquote>
<p><strong>Be flexible with pricing.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>Most freelancers will tell you to know your value. And that’s good
advice. But you have to be realistic about what the marketplace looks
like at times. If you go after a specific type of work, that sometimes
means working with clients who have unique pricing needs.</p>
<p>Me? I like charging weekly. But what if a client doesn’t want to? It
depends on the client. If they offer great work and the pricing makes
sense, of course I’m willing to bend a little. If they’re asking for too
much and they don’t meet my pricing needs, it’s easy to say no.</p>
<p>If being flexible means unique invoicing terms (such as Net 30, even
though you may not be used to it), look at it as an opportunity to try
something new. Do a risk assessment: if a client could potentially be
worth tens of thousands every month or year, is it really worth saying
no because they want you to use Bill.com rather than your own
invoices?</p>
</blockquote>
<ol start="4" type="1">
<li><blockquote>
<p><strong>When you don’t have work, create it.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>There’s an old rule in business. Dress for the job you want to have.
The idea is that if you fake it until you make it, eventually, other
people will come to see you as a natural fit for the position you’re
aiming for.</p>
<p>It works the same for freelancing. Except no one can see that you’re
wearing your pajamas.</p>
<p>In this case, your “clothes” are the work that you do. Some famous
writers got their jobs because they wrote spec scripts for TV shows. You
can create the portfolio of your dreams in your free time right now. And
it will look like you’ve been working professionally for years. Simply
put, don’t wait until the work comes. Be the freelancer you want to be,
starting today.</p>
</blockquote>
<ol start="5" type="1">
<li><blockquote>
<p><strong>Hire a CPA and a bookkeeper.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>Why a bookkeeper? They’ll help you save time. They can get a handle
on your books so you’re not constantly reviewing them. Why a CPA?
They’ll ensure that you’re compliant with tax law. And they’ll minimize
your headaches when April rolls around and you’re not sure how
everything works.</p>
</blockquote>
<ol start="6" type="1">
<li><blockquote>
<p><strong>Build relationships with your clients.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>When
clients speak with friends who are looking for a freelancer, who will
they think of? The person who’s easiest to work with. When they need to
hire someone for a new project, who will they think of? The person who’s
easier to work with.</p>
<p>When push comes to shove, who do you think people are going to rely
on the most? If you’re the only freelancer they text with, it’s probably
going to be you.</p>
</blockquote>
<ol start="7" type="1">
<li><blockquote>
<p><strong>Communicate too much.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>Overcommunication is always better than under-communication. A client
can always say “hey, stop emailing me.” But if they have to ask, “Hey,
where are you?” they’re far less likely to ask you for repeat work.</p>
<p>If you worry about your boundaries, remember that an
over-communicative, friendly relationship means they’ll trust you when
you tell them that you’re away for a weekend. If you use
over-communication the right way—without being annoying—it can actually
empower your relationships.</p>
</blockquote>
<ol start="8" type="1">
<li><blockquote>
<p><strong>Don’t say no.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>This might sound like it goes against the advice to
overbooking, and probably everything that you read online. But rather than saying no to someone, keep in mind that you
can provide other options. Don’t say “no,” but you can say “No,
but…”</p>
<p>If a client asks if you’re free and you’re not, refer someone else to
them. You’d be surprised at how many clients keep coming back to you.
They still picture you as their go-to point of contact in the
industry.</p>
</blockquote>
<ol start="9" type="1">
<li><blockquote>
<p><strong>Be a dream to work with.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>It bears repeating because it’s what’s given me the career I have: be
the dream freelancer for your client.</p>
<p>True: you can’t always control a client’s response to you. But you
can 100% control your response in every situation. Are you
friendly? Are you contributing value? Are you lazy and cutting corners?
Think about what you would want in a freelancer if you were hiring. You
would want someone who went above and beyond for you, someone who made
you finally say, “Good—I don’t have to look for freelancers
anymore.”</p>
</blockquote>
<ol start="10" type="1">
<li><blockquote>
<p><strong>When in doubt, ask.</strong></p>
</blockquote></li>
</ol>
<blockquote>
<p>Here’s the big secret. If you have the guts to ask, a lot of people
will say yes.</p>
<p>You can ask for almost anything. As long as you’re open,
communicative, professional, and flexible, people will say yes far more
often than you might assume. People, on the whole, are reasonable.
They’re willing to bend a little when it helps the project.</p>
</blockquote>
<ul>
<li><blockquote>
<p>Ask if you need a deadline extension (if you’ve been communicative,
they’ll understand you’re not being lazy, but having legitimate
issues)</p>
</blockquote></li>
<li><blockquote>
<p>Ask for a testimonial</p>
</blockquote></li>
<li><blockquote>
<p>Ask to use a piece in your portfolio</p>
</blockquote></li>
<li><blockquote>
<p>Ask your friends and family if they know anyone looking to hire</p>
</blockquote></li>
<li><blockquote>
<p>Ask your Twitter followers for their insights</p>
</blockquote></li>
</ul>
<blockquote>
<p>If you need something, asking is the most direct route from A to B.
But it’s something most of us avoid. We don’t want to look dumb. We
might have imposter syndrome. Whatever the reason, ditch it. If you
build a habit of asking, you’d be surprised at how much of the world
will open up to you.</p>
</blockquote>
<p>Freelancing can be the adventure of a lifetime. It’s a bit like
walking a tightrope without a net—only not as hard as that, I
promise.</p>
<p>The good news is that freelancing can also be whatever you want to
be. If you want a stable income full of long-term clients, you can
position yourself to find them. If you want to work 8 months out of the
year and travel for the other four months, plan it. Go nuts.</p>
<p>As long as you stick to some basic principles (be the easiest person
they’ve ever worked with, be communicative, and just get started), you’d
be amazed at what you can accomplish.</p>
<p>After all, this freelance economy of ours isn’t going away anytime
soon. It’ll be up to you to decide whether it’s right for you. Just
don’t be one of those people who work in an office and wonder what could
have been. The adventure of your professional life is waiting for you on
the other side of that first gig.</p>
<p>It’s within reach, you’re almost there, you can touch it – you can see it, you might not be able to touch it yet, but you can see it – you can feel it. It’s in there.</p>
</div>
</template>

<script>

import api from "@/api";
import ChapterVideo from '../components/ChapterVideo.vue'

export default {
name: "Book",

components: {
    ChapterVideo,
},

props: ["scrollValue", "textMode"],

data() {
    return {
        videos: []
    };
},

async created() {
    const videos = await  api.users.getVideos({
        userId: api.state.firebase.auth().currentUser.uid,
    })

    this.videos = videos
},

methods: {
    viewHandler (e, id) {
        if (e.type === 'enter') {
            this.$emit('inView', {
                id: id
            })
        }

    },

    async completed(data) {

        const  videoId  = data.videoID

        await api.users.setAsPlayed({
            userId: api.state.firebase.auth().currentUser.uid,
            videoId: videoId
        })

        this.$emit('completed', {
            videoId: videoId
        })
    }
},
};
</script>

<style>
</style>
