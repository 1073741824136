<template>
  <div
    class="mx-auto px-4 sm:px-6 lg:px-8 xl:px-10"
  >
  <div class="flex justify-between items-center pb-4">
    <p class="text-gray-light">© 2022 Standout Freelancer™, an Epicurrence Inc. Company</p>

    <div class="flex flex-row align-middle items-end">
      <a href="https://twitter.com/dannpetty" target="_blank">
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M12.6823 23.8467C20.1417 23.8467 24.2217 17.6666 24.2217 12.3073C24.2217 12.1318 24.2217 11.957 24.2099 11.7831C25.0036 11.209 25.6888 10.4981 26.2333 9.68377C25.4931 10.0117 24.7079 10.2268 23.9039 10.3219C24.7505 9.81501 25.3841 9.01782 25.6869 8.07865C24.8908 8.55106 24.0198 8.88398 23.1116 9.06307C22.5001 8.41287 21.6914 7.98232 20.8106 7.83805C19.9297 7.69378 19.0259 7.84384 18.239 8.265C17.452 8.68616 16.8258 9.35495 16.4572 10.1679C16.0887 10.9808 15.9983 11.8925 16.2001 12.762C14.5877 12.6811 13.0103 12.2621 11.5703 11.5321C10.1303 10.8021 8.85992 9.77742 7.84163 8.5246C7.32301 9.41743 7.16416 10.4744 7.39744 11.4802C7.63072 12.486 8.23858 13.3651 9.09726 13.9385C8.45185 13.9194 7.82051 13.7453 7.25652 13.4309C7.25652 13.4475 7.25652 13.4649 7.25652 13.4823C7.25677 14.4186 7.58091 15.3261 8.17395 16.0507C8.76699 16.7753 9.59242 17.2725 10.5102 17.4579C9.91316 17.6207 9.2867 17.6445 8.67898 17.5275C8.93814 18.3333 9.44269 19.038 10.1221 19.543C10.8014 20.048 11.6217 20.3281 12.468 20.344C11.0319 21.4726 9.25782 22.0853 7.43126 22.0835C7.10858 22.0829 6.78621 22.0633 6.46582 22.025C8.32051 23.2152 10.4785 23.8465 12.6823 23.8436"
            fill="white"
          />
        </g>
      </svg>
      </a>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  props: ["show"],

  data() {
    return {
      expanded: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>


<style scoped>
</style>
