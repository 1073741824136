<template>
<div class="min-h-screen bg-white flex">
  <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
    <div class="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <img class="h-16 w-auto" src="../assets/images/logo.png" alt="Design Full Time">
        <h2 class="mt-6 text-3xl font-semibold text-gray-900">
          Sign in with email
        </h2>
      </div>

      <div class="mt-2">
        <div>
          <div class="mt-2 relative">
            <div class="relative flex justify-left text-sm">
              <span class="bg-white text-gray-500">
                We’ll email you a magic code for a password-free sign in.
              </span>
            </div>
          </div>
        </div>

        <div class="mt-6">
          <form class="space-y-6" @submit="login">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <input id="email" v-model="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              </div>
            </div>

            <div>
              <button @click="login" type="submit" class="w-full text-center px-8 py-3 border border-transparent rounded-full text-md font-semibold text-white bg-black hover:text-gray-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign in with email
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden lg:block relative w-0 flex-1">
    <img class="absolute inset-0 h-full w-full object-cover" src="https://dann.thatportfolio.com/wp-content/uploads/sites/57/2020/05/bg-small-1.jpg" alt="">
  </div>
</div>


</template>

<script>
import api from '@/api'
import firebaseConfigs from '@/firebase-configs'
//import store from '@/store'

export default {
	name: 'Login',
	data() {
		return {
			//previousRoute: store.state.previousRoute || 'Home',
			email: '',
			errorMessage: '',
		}
	},

	methods: {
		async login(e) {
			if (e) {
				e.preventDefault()
				e.stopPropagation()
			}

			if (!this.email) {
				/* eslint-disable-next-line */
				return alert('Please fill in your email.')
			}

			if (this.email) {
				const email = this.email.trim()

                let actionCodeSettings = {
                    // URL you want to redirect back to. The domain (www.example.com) for this
                    // URL must be in the authorized domains list in the Firebase Console.
                    //url: 'https://job-board-d133a.web.app/yewww',
                    url: `${firebaseConfigs.baseHostUrls[process.env['NODE_ENV']]}/yewww`,
                    // This must be true.
                    handleCodeInApp: true,
                };

                await api.state.firebase
                    .auth()
                    .sendSignInLinkToEmail(email, actionCodeSettings)
                    .then(() => {
                        window.localStorage.setItem('jobboard-emailForSignIn', email);
                    })
                    .catch((error) => {
                        console.log(error)
                        //let errorCode = error.code;
                        let errorMessage = error.message;
                        this.errorMessage = errorMessage
                    });
			}

			return null
		},
	},
}
</script>

<style scoped>
</style>
