export default {
    baseHostUrls: {
        development: 'http://localhost:8080/#',
        production: 'https://freelance-guide.web.app/#',
    },
    functionsUrls: {
        development:
            'http://127.0.0.1:5001/freelance-guide/us-central1/api',
        production:
            'https://us-central1-freelance-guide.cloudfunctions.net/api',
    },
    stripe: {
        development: {
            apiKey: 'pk_live_1qgqe4Nu9qsB5qddsb0rKE3j',//'pk_live_1qgqe4Nu9qsB5qddsb0rKE3j',
        },
        production: {
            apiKey: 'pk_live_515HpmgI039qAIkzNebGHNWa7HuljdAcz4YLYCir5GC0PGAIA8tGCkYp3DrSJAkLJA8L4zt7Ef6Gemp6nLsD9Fxcz00759CBHM1',//pk_live_1qgqe4Nu9qsB5qddsb0rKE3j',
        }
    }
}
