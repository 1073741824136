<template>
  <div class="w-full h-full">
    <!-- <div
      class="w-full pt-6 px-4 sm:px-20 bg-black-dark z-20"
      :class="scrollPosition ? 'sticky top-12' : ''"
    >
      <div class="flex flex-row justify-between items-center py-8">
        <h1 class="text-left text-white text-2xl pl-0">
          What you'll learn with this guide
        </h1>

        <FloatingVideo :show="scrollPosition" :url="''" />
      </div>
    </div> -->
    <div :class="preventScroll ? '' : ''" class="lg:flex bg-gray-light relative">

      <TableOfContents :preview="true" :active="'getting-started-myths'" @scrollTo="scrollTo"  @showBuy="showBuy" @onExpand="onExpand"/>

      <div class="content-wrapper bg-white min-w-0 w-full flex-auto md:static md:max-h-full md:overflow-visible z-10">
        <div class="w-full flex justify-between pr-0 md:pr-20">
          <div id="content" class="min-w-0 max-w-7xl flex-auto space-y-4 py-10 lg:pb-16 mt-0 text-gray-body px-4 sm:px-6 xl:px-8">
            <BookPreview :scrollValue="this.scrollValue"/>
            <!-- <ChapterVideo v-for="(video, index) in videos" :key="index" :videoID="video.videoId" :top="video.top" /> -->
          </div>
        </div>
      </div>

      <!-- <div @click="scrollToTop" class="fixed right-10 bottom-10 z-50 w-10 h-10 bg-gray-light rounded-full shadow-lg hover:shadow-xl flex items-center justify-center cursor-pointer ">
          <div class="w-3">
            <svg viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.35355 0.646447C4.15829 0.451184 3.84171 0.451184 3.64645 0.646447L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646447ZM4.5 12L4.5 1H3.5L3.5 12H4.5Z" fill="#141414"/>
            </svg>
          </div>
      </div> -->
    </div>
  </div>
</template>


<script>
// import FloatingVideo from "../components/FloatingVideo.vue";
// import ChapterVideo from "../components/ChapterVideo.vue"
import TableOfContents from "../components/TableOfContents.vue";
import BookPreview from "../components/BookPreview.vue";

export default {
  name: "Preview",

  components: {
    // FloatingVideo,
    // ChapterVideo,
    TableOfContents,
    BookPreview,
  },

  props: ["scrollPosition", 'scrollValue'],

  data() {
    return {
      isOpen: true,
      isMobile: false,
      scroll: '',
      preventScroll: false,
      videos: [
          {
              videoId: "692648913",
              id: 'introduction',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-one-things-you-must-know-first',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-two-setting-yourself-up-for-success',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-three-how-to-get-work-and-stand-out',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-four-working-with-clients',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-five-guide-to-pricing',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-six-everything-portfolio',
              top: 0
          },
          {
              videoId: "692648913",
              id: 'chapter-7-the-not-so-fun-side-of-freelancing',
              top: 0
          }
      ]
    };
  },

  async mounted() {

        const offset = 0;

        this.videos.forEach((video, index) => {
            const element = document.getElementById(video.id)

            if (element) {
                const top = element.getBoundingClientRect().top
                this.$set(this.videos[index],'top',top + offset)
            }

        })

        this.isMobile = this.checkMobile()
  },

  methods: {
    checkMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true
        } else {
            return false
        }
    },

    onExpand(value) {
        this.preventScroll = value.value
    },

    openSidebar() {
      this.isOpen = !this.isOpen;
    },

    showBuy() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },

    scrollTo(data) {
            var element = document.getElementById(data.href);
            var headerOffset = this.isMobile ? 10 : 40;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
    },

    scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
    }
  },
};
</script>

<style>
</style>
