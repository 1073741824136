import api from "@/api";

export default {

  async getProducts() {
    const items = [];

    const snapshot = await api.state.firestoreDB
    .collection("products")
    .where("active", "==", true)
    .get();

    snapshot.forEach(doc => {
      items.push({
        id: doc.id,
        ...doc.data()
      });
    });

    return items;
  },

  async addProduct({ product }) {
        return await api.state.firestoreDB
        .collection("products")
        .add({
            ...product,
            createdAt: new Date()
        });
  },

  async updateProduct({ product }) {
    await api.state.firestoreDB
    .collection("products")
    .doc(product.id)
    .set({
        ...product
    }, {
        merge: true
    });
},

};
