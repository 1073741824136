import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import firebaseConfigs from '@/firebase-configs'
import Home from '@/components/Home'
import Login from '@/components/Login'
import Landing from '@/components/Landing'
import Signin from '@/components/auth/Signin'
import Signup from '@/components/auth/Signup'
import ForgotPassword from '@/components/auth/ForgotPassword'
import ForgotPasswordBackend from '@/components/auth/ForgotPasswordBackend'

// import Dashboard from '@/components/auth/Dashboard'
import store from "../store";

axios.defaults.baseURL = firebaseConfigs.functionsUrls[process.env.NODE_ENV]
Vue.use(Router)

let routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            requiresAuth: true,
            nav: {
                title: 'Standout Freelancer',
            },
        },
        component: Home,
    },
    {
        path: '/buy',
        name: 'Landing',
        component: Landing,
        props: true,
        meta: {
            requiresAuth: false,
            nav: {
                title: 'Standout Freelancer',
            },
        },
    },
    {
        path: '/success',
        name: 'Home',
        meta: {
            requiresAuth: true,
            nav: {
                title: 'Standout Freelancer',
            },
        },
        component: Home,
    },
    {
        path: '/canceled',
        name: 'Landing',
        meta: { requiresAuth: false },
        component: Landing,
    },
    {
        path: '/login',
        name: 'Login',
        meta: { requiresAuth: false },
        component: Login,
    },
    {
        path: '/signin',
        name: 'Signin',
        meta: { requiresAuth: false },
        component: Signin,
    },
    {
        path: '/signup',
        name: 'Signup',
        meta: { requiresAuth: false },
        component: Signup,
        props: true
    },
    {
        path: '/forgotpassword',
        name: 'ForgotPassword',
        meta: { requiresAuth: false },
        component: ForgotPassword,
    },
    {
        path: '/forgotpasswordbackend',
        name: 'ForgotPasswordBackend',
        meta: { requiresAuth: false },
        component: ForgotPasswordBackend,
    },
    // {
    //     path: '/dashboard',
    //     name: 'Dashboard',
    //     meta: { requiresAuth: true },
    //     component: Dashboard,
    // }
]

const router = new Router({
    mode: 'history',
    routes,
})

const loadPage = ({ next, path }) => {
    try {
        if (path !== undefined) next(path)
        else next()
    } catch {
        console.log('/')
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {

        let intervalIncrement = 0
        const timing = 100

        const interval = setInterval(() => {
            intervalIncrement += timing

            if (store.state.currentUser) {
                clearInterval(interval)

                if (store.state.currentUser.userData && store.state.currentUser.userData.payment && to.meta.requiresAuth) {
                    loadPage({ next })
                } else {
                    loadPage({ next, path: '/buy' })
                }

            } else if (intervalIncrement >= 3000) {
                clearInterval(interval)
                loadPage({ next, path: '/buy' })
            }


        }, timing)
    } else {
        loadPage({ next })
    }
})

export default router
