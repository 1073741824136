<template>
  <div class="" @scroll="onScroll">
    <div
      class="sticky top-0 w-full transition-all"
      :class="scrollPosition ? 'bg-black-dark bg-opacity-100 z-50' : 'z-30'"
    >
      <Nav
        :scrollPosition="scrollPosition"
        :page="'landing'"
        @postJob="toggleMenu"
      />
    </div>

    <div class="relative -mt-20 pb-20 sm:pb-0 loop-poster">

    <video-background
        src="https://vz-c6a9f4d1-ff8.b-cdn.net/eaa5f7b1-65c5-4cf5-8a78-47c607f45cab/play_720p.mp4"
        style="max-height: 1040px; height: 100vh"
        :poster="poster"
        overlay="linear-gradient(90deg,#181A1B,transparent 40%), linear-gradient(180deg, transparent 60%, #181A1B)"
      >
      </video-background>

      <div
        class="
          px-4
          sm:px-6
          lg:px-8
          xl:px-10
          z-10
          absolute
          bottom-10
          sm:bottom-0
          left-0
          right-0
          h-full
          flex flex-col justify-between
        "
      >
        <div class="sm:grid sm:grid-cols-12 gap-y-10 sm:gap-y-0 pt-8 relative h-full">
          <div class="col-span-12 sm:col-span-6 flex flex-col space-y-6 sm:space-y-10 justify-center mt-80 sm:mt-0">

            <div>
            <h1 class="font-atlas-medium
                text-white text-3xl
                md:text-4xl
                lg:text-5xl
                2xl:text-6xl
                z-20
                sm:mt-0
                sm:space-y-2
              "
            >
              <span class="block">A powerful guide to</span>
              <span class="block">successful freelancing.</span>
            </h1>
            <p
              class="
                mt-3
                md:mt-4
                lg:mt-5
                text-lg
                font-normal
                leading-6
                text-white
                z-20
              "
            >

                <span class="block xl:hidden">This is literally the best advice that I tell every freelancer to help lead a more successful career. I’ve stood out for 20+ years as a successful web designer, now it’s your turn.</span>

                <span class="hidden xl:block">This is literally the best advice that I tell every freelancer to help lead a more</span>
                <span class="hidden xl:block">successful career. I’ve stood out for 20+ years as a successful freelance</span>
                <span class="hidden xl:block">web designer, now it’s your turn.</span>
            </p>


            </div>

              <div class="flex flex-row items-center space-x-4">
        <svg class="w-5 h-5 flex flex-col justify-end mt-1 sm:mt-0 self-start sm:self-center" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
        </svg>
        <span class="text-white hidden sm:flex text-lg font-normal">
            Includes 5+ hours of video and online book.
        </span>
        <p>
         <span class="text-white block sm:hidden text-lg font-normal">
            Includes 5+ hours of
        </span>
        <span class="text-white block sm:hidden text-lg font-normal">
           video and online book.
        </span>
        </p>
    </div>

            <div class="space-y-2 sm:space-y-10">
            <p class="font-semibold mt-3">
              <span class="block text-white text-lg"
                >{{ isOpen ? '$5' : '$249' }}
                <span v-if="isOpen" class="line-through text-white text-opacity-60 text-lg ml-1"
                  >$249</span
                >
              </span>
              <span v-if="isOpen" class="block text-green"
                >Sale special ends in {{ countdownEnd }}</span
              >
            </p>

            <div class="flex flex-row align-middle items-center justify-start">
              <button
                @click="buyCourse"
                type="button"
                class="
                  bg-green
                  text-center
                  px-12
                  py-4
                  w-auto
                  rounded-full
                  shadow-sm
                  text-lg
                  font-atlas-medium
                  text-black
                  focus:outline-none
                  buyButton
                "
              >
                {{ isOpen ? 'Buy Course' : 'Course Closed' }}
              </button>
              <button
                @click="seeDetails"
                type="button"
                class="
                  bg-black
                  bg-opacity-80
                  border
                  border-green
                  hover:border-black
                  rounded-full
                  text-center
                  px-4
                  sm:px-8
                  w-auto
                  py-4
                  ml-3
                  shadow-sm
                  text-lg
                  font-atlas-medium
                  text-white
                  flex flex-row
                  justify-space-evenly
                  items-center
                  focus:outline-none
                  seeDetails
                "
              >
                See Details
              </button>
            </div>
            </div>
          </div>

          <div class="hidden col-span-12 sm:col-span-6 flex-row justify-start sm:justify-end mt-8 sm:mt-0">
            <div class="flex flex-col justify-center">
              <p class="text-white text-lg font-semibold">Includes:</p>
              <div class="flex flex-row items-center space-x-2">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z"
                    fill="#45DB78"
                  />
                </svg>
                <p class="text-white text-left text-lg">5 hours of video</p>
              </div>
              <div class="flex flex-row items-center space-x-2">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z"
                    fill="#45DB78"
                  />
                </svg>
                <p class="text-white text-left text-lg">Video version</p>
              </div>
              <div class="flex flex-row items-center space-x-2">
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z"
                    fill="#45DB78"
                  />
                </svg>
                <p class="text-white text-left text-lg">Online book version</p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="
            hidden
            sm:grid sm:grid-cols-12 sm:gap-x-6
          "
        >
          <div
            class="hidden sm:flex col-span-12 justify-end space-y-0 items-center flex-col"
          >
            <p class="mt-3 font-normal text-gray-light text-sm z-20 w-full">
              <span class="block"
                >Dann has worked for and helped students get jobs at companies
                like:</span
              >
            </p>
            <div class="w-full pb-10">
              <div class="flex flex-row w-auto">
                <img class="" src="../assets/images/sponsors-1.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Preview :scrollPosition="scrollPosition" :scrollValue="scrollValue" />

    <!-- <PaymentSuccess
      v-if="showSuccessModal"
      :description="'Thanks for the payment! Your Job Post will appear shortly. You will also receive an email with all the details in your inbox very soon.'"
      :closeText="'Close'"
      @closePaymentModal="closePaymentModal"
    /> -->

    <div class="mt-2 mx-auto">
      <main class="pt-32 sm:pt-0">
        <Footer />
      </main>
    </div>
  </div>
</template>

<script>
import Nav from "./Nav";
import Footer from "./Footer";
import Preview from "./Preview";
import VideoBackground from "vue-responsive-video-background-player";
import store from "../store";
import api from "../api";
import firebaseConfigs from "@/firebase-configs";
import poster from "../assets/images/poster.jpg";

export default {
  name: "Landing",

  components: {
    Nav,
    Preview,
    Footer,
    VideoBackground,
  },

  data() {
    return {
        sharedState: store.state,
        title: "Standout Freelancer",
        showSuccessModal: false,
        scrollPosition: false,
        scrollValue: 0,
        isOpen: false,
        user: null,
        course: null,
        code: null,
        countdownEnd: "",
        interval: null,
        priceId: "price_1KrRnuI039qAIkzNqIjy5sBv",
        isPlaying: false,
        poster: poster,
    };
  },

  watch: {
    "sharedState.currentUser"(newVal) {
      if (newVal) {
        this.user = newVal;
      }
    },
    "this.$route"(newVal) {
      if (this.$route.query.code) {
        this.code = newVal.query.code;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("scroll", this.onScroll);
  },

  async created() {
    window.addEventListener("scroll", this.onScroll);

    //  this.setCountdown();

    if (store.state.currentUser) {
      this.user = api.state.firebase.auth().currentUser;
    }
  },

  async mounted() {
    const route = this.$route;

    if (route.path.includes("/success") || route.path.includes("/canceled")) {
      this.sessionId = this.$route.query.session_id;

      if (route.path.includes("/success")) {
        this.showSuccessModal = true;
      }
    }
  },

  methods: {
    onReady() {},
    onEnded() {
      this.isPlaying = false;
    },
    onPlaying() {
      this.isPlaying = true;
    },
    playVideo() {
      this.isPlaying = true;
      this.$refs.player.play();
    },
    seeDetails() {
        var element = document.getElementById('introduction');

        if (element) {
            let headerOffset = 80;
            let elementPosition = element.getBoundingClientRect().top;
            let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }

    },
    toggleMenu() {},
    toggleTrailer() {},

    setCountdown() {
      const self = this;

      this.interval = setInterval(function () {
        let now = new Date().getTime() / 1000;
        let endDate = 1673812800;

        let distanceEnd = endDate - now;

        var dEnd = Math.floor(distanceEnd / (3600 * 24));
        var hEnd = Math.floor((distanceEnd % (3600 * 24)) / 3600);
        var mEnd = Math.floor((distanceEnd % 3600) / 60);
        var sEnd = Math.floor(distanceEnd % 60);

        var dDisplayEnd = dEnd + (dEnd == 1 ? "d :" : "d :");
        var hDisplayEnd = hEnd + (hEnd == 1 ? "h :" : "h :");
        var mDisplayEnd = mEnd + (mEnd == 1 ? "m :" : "m :");
        var sDisplayEnd = sEnd + (sEnd == 1 ? "s" : "s");

        // if (dDisplayEnd + hDisplayEnd + mDisplayEnd + sDisplayEnd !== "") {
        //   self.countdownEnd = `${dDisplayEnd}${hDisplayEnd}${mDisplayEnd}${sDisplayEnd}`;
        // }

        if (dDisplayEnd + hDisplayEnd + mDisplayEnd + sDisplayEnd !== "") {
            if (dDisplayEnd.includes('-') || hDisplayEnd.includes('-') || mDisplayEnd.includes('-') || sDisplayEnd.includes('-')) {
                self.isOpen = false;
            } else {
                self.countdownEnd = `${dDisplayEnd}${hDisplayEnd}${mDisplayEnd}${sDisplayEnd}`;
            }
        }
      }, 1000);
    },

    onScroll() {
        this.scrollValue = window.scrollY
        this.scrollPosition = this.scrollValue > 200;
    },

    async closePaymentModal() {
      this.showSuccessModal = false;

      if (!this.course) {
        this.course = await api.courses.getByTitle({ title: this.title });
      }

      this.$router
        .push({
          name: "VideoPlayer",
          params: { id: this.course.id },
          query: {
            returnUrl: this.course.slug,
          },
        })
        .catch(() => {});
    },

    async buyCourse() {
      if (store.state.currentUser && this.isOpen) {
        // await this.createSession();
      } else {
        if (this.$route.path != "Signup") {
          if (this.code) {
            this.$router
              .push({ name: "Signup", query: { code: this.code } })
              .catch(() => {});
          } else {
            this.$router.push("Signup").catch(() => {});
          }
        }
      }
    },

    async createSession() {
      const key = firebaseConfigs.stripe[process.env.NODE_ENV].apiKey;

      const stripe = window.Stripe(key);
      const lineItems = [];

      lineItems.push({
        price: this.priceId,
        adjustable_quantity: {
          enabled: true,
          minimum: 1,
          maximum: 99,
        },
        quantity: 1,
      });

      const result = await api.payments.getCheckoutCourse({
        lineItems,
        userId: this.user.uid,
        code: this.code,
      });

      const sessionId =
        result && result.data && result.data.sessionId
          ? result.data.sessionId
          : "invalid";

      if (sessionId !== "invalid") {
        stripe
          .redirectToCheckout({
            sessionId: result.data.sessionId,
          })
          .then((data) => {
            console.log(data);
          });
      } else {

        const mergedUser = {
          ...this.user,
        };

        store.setAction({
          which: "setCurrentUser",
          data: {
            user: mergedUser,
          },
        });

        this.showSuccessModal = true;
      }
    },
  },
};
</script>

<style scoped>
/* .bg-image {
  background-image: url("../assets/images/bg_standout.png");
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1024px) {
  .bg-image {
    background-image: url("../assets/images/bg_standout_mobile.png");
    background-repeat: no-repeat;
    background-position-x: right;
  }
}

@media only screen and (max-width: 640px) {
  .bg-image {
    background-image: url("../assets/images/bg_standout_mobile.png");
    background-repeat: no-repeat;
    background-position-x: center;
  }
}

@media (min-width: 1440px) {
  .bg-image {
    background-image: url("../assets/images/bg_standout_large.png");
    background-repeat: no-repeat;
    background-position-x: right;
  }
}

@media (min-width: 1750px) {
  .bg-image {
    background-image: url("../assets/images/bg_standout_xlarge.png");
    background-repeat: no-repeat;
    background-position-x: 70%;
  }
} */

.vimeo {
  overflow: hidden;
  z-index: 1;
}

.vimeo iframe {
  border-radius: 0.375rem !important;
}
</style>
