import axios from "axios";

export default {

  async Subscribe({ email }) {
      console.log(email)
      const results = await axios.post('/subscribe', {
        email
      })

      return results
  },
}
