import api from "@/api";

export default {
    async get({ courseId }) {
        const items = [];

        const snapshot = await api.state.firestoreDB.collection("courses").doc(courseId).get();

        items.push({
            id: snapshot.id,
            ...snapshot.data()
        });

        return items[0];
    },

    async getVideos({ courseId }) {
        const items = [];

        const snapshot = await api.state.firestoreDB.collection("courses").doc(courseId).collection('videos').get();

        snapshot.forEach(doc => {
            items.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return items;
    },

    async getByTitle({ title }) {
        const items = [];

        const snapshot = await api.state.firestoreDB
        .collection("courses")
        .where("title", '==', title)
        .get();

        snapshot.forEach(doc => {
            items.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return items[0];
    },

    async getCourses() {
        const items = [];

        const snapshot = await api.state.firestoreDB.collection("courses").get();

        snapshot.forEach(doc => {
            items.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return items;
    },
};
