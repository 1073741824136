<template>
  <div id="app" class=" bg-transparent">
      <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>

</style>
