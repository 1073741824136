<template>
    <div :class="floating ?  'absolute right-20 bottom-20' : 'relative'">
        <div  style="position: relative; padding-bottom: 52.734375%; height: 0;">
            <iframe v-if="isPlaying" :src="`https://www.loom.com/embed/${videoID}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true`" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
            </iframe>
        </div>
        <img
            :src="getThumb(videoID)"
            :class="isPlaying ? 'hidden' : 'absolute inset-0 bg-cover w-full h-full'"
        />
        <div :class="isPlaying ? 'hidden' : 'absolute bottom-2 sm:bottom-10 left-2 sm:left-12'">
            <button
            @click="playVideo"
            type="button"
            class="
            bg-opacity-70 bg-black-button
            border border-green
            rounded-full
            text-center
            w-auto
            py-4
            sm:py-5
            px-5
            sm:px-10
            sm:mt-0
            shadow-2xl
            text-sm
            sm:text-base
            font-normal
            text-white
            flex flex-row
            justify-evenly
            sm:justify-center
            space-x-1
            sm:space-x-4
            items-center
            focus:outline-none
            hover:bg-opacity-100
            transition ease-in-out delay-150
            seeDetails
            "
            >
                <svg class="fill-current" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 8.5L9.05408e-07 16.7272L1.83541e-06 0.272758L15 8.5Z" />
                </svg>
                <span class="font-atlas-medium">{{ title }}</span>
            </button>
        </div>
         <div :class="!played ? 'hidden' : 'absolute bottom-2 sm:bottom-10 right-2 sm:right-12'">
            <svg class="w-5 h-5 flex flex-col justify-end" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.96117 6.14551C2.55404 6.44815 2.98153 6.74941 3.20864 7.28055C3.468 7.91118 3.79324 8.57544 4.0526 9.20607C4.15072 9.3392 4.38197 9.37421 4.5151 9.27608C4.54872 9.21021 4.6146 9.24384 4.64822 9.17796C4.81498 9.01397 5.04761 8.8836 5.14849 8.68597C5.91914 7.50161 6.91967 6.51764 7.82208 5.40053C7.88933 5.26878 7.92296 5.20291 8.05608 5.10479C9.48824 3.92594 10.6905 2.54672 12.2531 1.60051C12.6188 1.37202 12.9187 1.1099 13.3167 0.980908C14.2472 0.45943 15.2717 0.567206 16.0594 1.30148C16.4211 1.56911 16.4814 2.26424 16.1479 2.59223C15.9475 2.8221 15.7472 3.05197 15.4473 3.31409C14.748 3.87058 14.0487 4.42706 13.4153 5.01717C12.4821 5.8694 11.581 6.82113 10.844 7.93961C10.1406 8.99222 9.27322 9.87808 8.53757 10.8312C8.1368 11.2909 7.80191 11.7843 7.46703 12.2777C7.19939 12.6393 6.96401 13.1004 6.6305 13.4284C5.19558 14.938 5.42821 14.8076 3.61322 14.2963C3.31609 14.2277 3.08622 14.0273 2.82273 13.8928C1.96774 13.2903 1.37901 12.4915 1.15603 11.4643C0.868563 10.238 0.515218 8.97811 0.161874 7.71822C0.131005 7.45335 0.166012 7.22209 0.233265 7.09035C0.502279 6.56335 0.901662 6.26899 1.39917 6.10775C1.59817 6.04325 1.86167 6.17776 1.96117 6.14551Z" fill="#45DB78"/>
            </svg>
        </div>
    </div>
</template>

<script>

export default {
  name: "ChapterVideo",

  props: ["show", "floating", "videoID", "title", "played"],

  data() {
    return {
      isPlaying: false,
    };
  },

  async created() {},

  methods: {
    getThumb(videoID) {
      return require(`../assets/images/${videoID}.jpg`);
    },
    close() {
      this.$emit("close");
    },
    onReady() {},
    onEnded() {
      this.$emit("completed", {
          videoID: this.videoID
      });
    },
    onPlaying() {
      this.isPlaying = true;
    },
    playVideo() {
      this.isPlaying = true;
      this.$refs.player.play();
    },
    checkProgress() {
        // const loomIframe = document.getElementById("loom-video");
        // const checkInterval = 1000; // Check every 1000 ms (1 second)
        // let videoDuration = 0;

        // // Function to run when the video has completed
        // function onVideoCompleted() {
        //     console.log("Video completed");
        //     // Add your custom callback logic here
        // }

        // // Check the video progress periodically
        // function checkVideoProgress() {
        //     loomIframe.contentWindow.postMessage(
        //         {
        //         type: "get",
        //         property: "currentTime"
        //         },
        //         "*"
        //     );

        //     loomIframe.contentWindow.postMessage(
        //         {
        //         type: "get",
        //         property: "duration"
        //         },
        //         "*"
        //     );
        // }

        // // Listen for messages from the iframe
        // window.addEventListener("message", (event) => {
        //     if (event.data.property === "currentTime") {
        //         const currentTime = event.data.value;

        //         if (currentTime >= videoDuration) {
        //         onVideoCompleted();
        //         }
        //     }

        //     if (event.data.property === "duration") {
        //         videoDuration = event.data.value;
        //     }
        // });

        // // Start checking video progress
        // setInterval(checkVideoProgress, checkInterval);

    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
