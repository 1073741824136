import init from "@/api/init"
import state from "@/api/state"
import courses from "@/api/courses"
import users from "@/api/users"
import payments from "@/api/payments"
import products from "@/api/products"
import subscribe from "@/api/subscribe"

export default {
    init,
    state,
    courses,
    users,
    payments,
    products,
    subscribe
};
