<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block
        sm:p-0
      "
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          px-4
          pt-5
          pb-4
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8
          sm:align-middle
          sm:max-w-sm
          sm:w-full
          sm:p-6
        "
      >
        <div>
          <div
            class="
              mx-auto
              flex
              items-center
              justify-center
              h-12
              w-12
              rounded-full
              bg-green-100
            "
          >
            <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.96117 10.1455C5.55404 10.4482 5.98153 10.7494 6.20864 11.2805C6.468 11.9112 6.79324 12.5754 7.0526 13.2061C7.15072 13.3392 7.38197 13.3742 7.5151 13.2761C7.54872 13.2102 7.6146 13.2438 7.64822 13.178C7.81498 13.014 8.04761 12.8836 8.14849 12.686C8.91914 11.5016 9.91967 10.5176 10.8221 9.40053C10.8893 9.26878 10.923 9.20291 11.0561 9.10479C12.4882 7.92594 13.6905 6.54672 15.2531 5.60051C15.6188 5.37202 15.9187 5.1099 16.3167 4.98091C17.2472 4.45943 18.2717 4.56721 19.0594 5.30148C19.4211 5.56911 19.4814 6.26424 19.1479 6.59223C18.9475 6.8221 18.7472 7.05197 18.4473 7.31409C17.748 7.87058 17.0487 8.42706 16.4153 9.01717C15.4821 9.8694 14.581 10.8211 13.844 11.9396C13.1406 12.9922 12.2732 13.8781 11.5376 14.8312C11.1368 15.2909 10.8019 15.7843 10.467 16.2777C10.1994 16.6393 9.96401 17.1004 9.6305 17.4284C8.19558 18.938 8.42821 18.8076 6.61322 18.2963C6.31609 18.2277 6.08622 18.0273 5.82273 17.8928C4.96774 17.2903 4.37901 16.4915 4.15603 15.4643C3.86856 14.238 3.51522 12.9781 3.16187 11.7182C3.13101 11.4533 3.16601 11.2221 3.23327 11.0903C3.50228 10.5634 3.90166 10.269 4.39917 10.1077C4.59817 10.0433 4.86167 10.1778 4.96117 10.1455Z"
                      fill="#45DBC1"
                    />
                  </svg>
          </div>
          <div class="text-center">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              Payment successful
            </h3>
            <div class="mt-2">
              <p class="text-sm text-gray-500">
                {{ description }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button
            @click="closeModal"
            type="button"
            class="
          sea-gradient
                text-center
                btn-details
                w-full
                rounded-full
                shadow-sm
                text-base
                font-semibold
                text-white
                focus:outline-none
            "
          >
            {{ closeText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "PaymentSuccess",

  props: ["title", "description", "closeText"],

  data() {
    return {
      showPostModal: false,
    };
  },

  async created() {
    //window.addEventListener("scroll", this.onScroll);
  },

  methods: {
    closeModal() {
      this.$emit("closePaymentModal");
    },
  },
};
</script>

<style>
</style>
