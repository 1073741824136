import api from "@/api";

export default ({ firebase, firestoreDB }) => {
  api.state.firebase = firebase;
  api.state.firestoreDB = firestoreDB;

  api.state.firestoreDB.settings({
    //timestampsInSnapshots: true,
  });
};
