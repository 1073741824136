import getters from '@/store/getters'

export default {
    debug: true,
    state: {
        previousRoute: null,
        query: '',
        user: {},
        currentUser: null,
    },

    getters,

    setAction({ which, data }) {
        switch (which) {
            case 'setPreviousRoute':
                this.state.previousRoute = data.route
                break

            case 'setUser':
                this.state.user = data.user
                break

            case 'setCurrentUser':
                this.state.currentUser = data.user
                break

            case 'signout':
                this.state.user = {}
                this.state.currentUser = null

                break

            default:
                // eslint-disable-next-line
                console.log(`Store action "${which}" not recognized.`)
        }

        return this.state
    },
}
